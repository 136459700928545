<template>
    <v-layout class="pt-1" row wrap>
        <v-flex md4 class="hidden-sm-and-down">
          <SaveSearchBox v-if="isLoggedIn"></SaveSearchBox>
          <CompareList></CompareList>
            <SearchFilter>
                <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
                <b>Produktkategorie</b>
                    </span>
            </SearchFilter>
        </v-flex>
        <v-flex md8 sm12 xs12>
            <SortOrder></SortOrder>
            <ResultsList class=""></ResultsList>
        </v-flex>
    </v-layout>
</template>


<script>
import SearchFilter from "@/components/SearchFilter.vue";
import ResultsList from "@/components/ResultsList";
import SortOrder from "@/components/SortOrder.vue";
import CompareList from "@/components/CompareList";
import SaveSearchBox from "@/components/SaveSearchBox.vue";
import {mapGetters} from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    SaveSearchBox,
    CompareList,
    SearchFilter,
    ResultsList,
    SortOrder
  },
  computed: {
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  },
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
