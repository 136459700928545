<template>
  <div class="equipment-reserved-form">
    <v-card tile>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="linde-font px-3"
        >Equipment reservieren
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form
            id="equipment-reserved-form"
            v-model="valid"
            ref="form"
            lazy-validation
        >
          <v-layout>
            <v-flex class="mr-3" md6 sm6 xs12>

              <v-text-field
                  v-model="name"
                  label="Name*"
                  :rules="rules.requiredRules"
                  required
              ></v-text-field>
            </v-flex>
            <v-flex class="mr-3" md6 sm6 xs12>
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="computedDateFormatted"
                      label="Reserviert bis"
                      hint="DD.MM.YYYY"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="reservedUntil" no-title @input="menu = false"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="normalize-font ml-2 mb-2" flat @click.native="cancel">
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            class="mr-2 mb-2 normalize-font"
            @click.native="patchEquipment"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {EventBus} from "../event-bus";
import EquipmentServices from "../services/equipment.services";
import CONSTSANTS from "../utils/constants";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "EquipmentForm",
  data() {
    return {
      valid: false,
      // reservedUntil: HelperFunctions.formatDate(new Date(Date.now())),
      reservedUntil: new Date().toISOString().substring(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substring(0, 10)),
      name: "",
      isAdmin: false,
      infoColor: "primary",
      menu: false,
      rules: CONSTSANTS.formRules,
      equipmentId: null
    };
  },
  props: ['equipment'],
  mounted() {
    this.isAdmin = this.user.isAdmin;
    EventBus.$on('openReservedDialog', (data) => {
      this.equipmentId = data.id;
      if (this.equipmentId && data.name && this.isReservedActive(data.reservedUntil)) {
        this.name = data.name;
        this.reservedUntil = data.reservedUntil;
      } else {
        this.name = "";
        this.reservedUntil = new Date().toISOString().substring(0, 10)
      }
    });
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    }
  },
  methods: {
    ...HelperFunctions,
    patchEquipment: function (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line
        this.loading(true);
        EquipmentServices.updateReservedStatus(
            this.accessToken,
            this.equipmentId,
            {reservedFor: this.name, reservedUntil: this.reservedUntil}
        )
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                color: "success",
                message: "Erfolgreich reserviert"
              });
              this.setDialogStatus(true);
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                color: "error",
                message: "Bitte kontrollieren!"
              });
            });
      } else {
        this.$store.dispatch("showSnackbar", {
          color: "error",
          message: "Bitte kontrollieren!"
        });
      }
    },
    setDialogStatus(changed) {
      EventBus.$emit('onCloseReservedDialog', changed);
    },
    cancel() {
      this.setDialogStatus(false);
    },
    getReservedFor(equipmentId) {
      EquipmentServices.getReservedInformation(this.accessToken, equipmentId)
          .then((response) => {
            const rp = response.data;
            this.name = rp.reservedFor;
            if (rp.reservedUntil) {
              this.reservedUntil = new Date(rp.reservedUntil).toISOString().substring(0, 10);
            }
          })
    },
  },
  computed: {
    ...mapState([
      "accessToken",
      "user"
    ]),
    ...mapActions(["getEquipmentData"]),
    computedDateFormatted() {
      return this.formatDate(this.reservedUntil)
    }
  },
  components: {}
};
</script>

<style>
#equipment-form .v-chip,
#equipment-form .v-chip__content {
  color: white;
  background: #b80c1e;
}

#equipment-form .v-chip {
  background: #b80c1e;
}
</style>
