<template>
    <v-layout align-center justify-center row>
        <v-flex md4>
            <v-card>
                <v-card-title class="title lighten-2 text-xs-center" primary-title>
                    <p class="width-100">Vielen Dank!</p>
                    <p class="width-100">Ihre E-Mail Adresse wurde erfolgreich verifiziert.</p>
                </v-card-title>
                <v-divider class="mt-1"></v-divider>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: "verified"
    }
</script>

<style scoped>
    .width-100 {
        width: 100%
    }
</style>