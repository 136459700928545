<template>
  <v-dialog :value="dialog" max-width="600" persistent transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title class="title">
        Inzahlungnahme von {{ selectedTradeIn.companyOrganization }} - {{ selectedTradeIn.name }}
      </v-card-title>
      <v-card-text>

        <v-flex class="" md5>
          <v-select
              :items="tradeInStatusItems"
              v-model="tradeInStatus"
              label="Status"
              @input="onChangeStatus"
          ></v-select>
        </v-flex>

        <div class="subheading mt-2">
          Kontakt
        </div>
        <v-divider></v-divider>
        <div class="mt-2">
          <v-icon small>person</v-icon>
          {{ selectedTradeIn.name }}
        </div>
        <div class="">
          <v-icon small>business</v-icon>
          {{ ' ' + selectedTradeIn.companyOrganization }}
        </div>
        <div class="">
          <v-icon small>map</v-icon>
          {{
            selectedTradeIn.address ? selectedTradeIn.address + ', ' + selectedTradeIn.zipCode + ' ' + selectedTradeIn.place : "-"
          }}
        </div>
        <div class="mt-2">
          <v-icon small>phone</v-icon>
          {{ selectedTradeIn.phone ? ' ' + selectedTradeIn.phone : '-' }}
        </div>
        <div class="">
          <v-icon small>mail</v-icon>
          {{ ' ' + selectedTradeIn.email }}
        </div>
        <v-divider v-if="selectedTradeIn.sellerName && selectedTradeIn.sellerName !== ''"></v-divider>
        <div class="subheading" v-if="selectedTradeIn.sellerName && selectedTradeIn.sellerName !== ''">
          Angaben zum Verkäufer
        </div>
        <v-divider v-if="selectedTradeIn.sellerName && selectedTradeIn.sellerName !== ''"></v-divider>
        <div class="mt-2" v-if="selectedTradeIn.sellerName && selectedTradeIn.sellerName !== ''">
          <v-icon small>person</v-icon>
          {{ selectedTradeIn.sellerName }}
        </div>
        <div class="" v-if="selectedTradeIn.sellerName && selectedTradeIn.sellerName !== ''">
          <v-icon small>mail</v-icon>
          {{ ' ' + selectedTradeIn.sellerEmail }}
        </div>
        <v-divider></v-divider>
        <div class="subheading">
          Angaben zum Gerät
        </div>
        <v-divider></v-divider>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.manufacturer"
                                 label="Hersteller"
                                 :value="selectedTradeIn.manufacturer"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.type"
                                 label="Typ"
                                 :value="selectedTradeIn.type"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.serialNumber"
                                 label="Seriennummer"
                                 :value="selectedTradeIn.serialNumber"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.constructionYear"
                                 label="Baujahr"
                                 :value="selectedTradeIn.constructionYear"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.operatingHours"
                                 label="Betriebsstunden (h)"
                                 :value="selectedTradeIn.operatingHours"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.loadCapacities)"
                                 label="Tragfähigkeit"
                                 :value="selectedTradeIn.loadCapacities"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.propulsion"
                                 label="Antrieb"
                                 :value="selectedTradeIn.propulsion"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.mastType"
                                 label="Hubmast"
                                 :value="selectedTradeIn.mastType"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.liftingHeight"
                                 label="Hubhöhe (mm)"
                                 :value="selectedTradeIn.liftingHeight"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.height"
                                 label="Bauhöhe (mm)"
                                 :value="selectedTradeIn.height"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.additionalHydraulic"
                                 label="Hydraulik"
                                 :value="selectedTradeIn.additionalHydraulic"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.attachmentUnit)"
                                 label="Anbaugerät"
                                 :value="selectedTradeIn.attachmentUnit"
                                 font-size="body-1"></DescriptionValueDisplay>

        <v-divider></v-divider>
        <div class="subheading">
          Angaben zur Batterie
        </div>
        <v-divider></v-divider>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.batteryVoltage"
                                 label="Volt"
                                 :value="selectedTradeIn.batteryVoltage"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.ampereHour"
                                 label="AH"
                                 :value="selectedTradeIn.ampereHour"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.batterySerialNumber"
                                 label="Seriennummer"
                                 :value="selectedTradeIn.batterySerialNumber"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.batteryConstructionYear)"
                                 label="Baujahr"
                                 :value="selectedTradeIn.batteryConstructionYear"
                                 font-size="body-1"></DescriptionValueDisplay>
        <v-divider></v-divider>
        <div class="subheading">
          Angaben zum Ladegerät
        </div>
        <v-divider></v-divider>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.chargerVoltage"
                                 label="Volt"
                                 :value="selectedTradeIn.chargerVoltage"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.chargerAmpereHour"
                                 label="AH"
                                 :value="selectedTradeIn.chargerAmpereHour"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="selectedTradeIn.chargerSerialNumber"
                                 label="Seriennummer"
                                 :value="selectedTradeIn.chargerSerialNumber"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.chargerConstructionYear)"
                                 label="Baujahr"
                                 :value="selectedTradeIn.chargerConstructionYear"
                                 font-size="body-1"></DescriptionValueDisplay>
        <v-divider></v-divider>
        <div class="subheading">
          Zustand Gerät
        </div>
        <v-divider></v-divider>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.opticalCondition)"
                                 label="Optisch"
                                 :value="selectedTradeIn.opticalCondition"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.technicalCondition)"
                                 label="Technisch"
                                 :value="selectedTradeIn.technicalCondition"
                                 font-size="body-1"></DescriptionValueDisplay>
        <v-divider></v-divider>
        <div class="subheading">
          Wichtig für den Transport
        </div>
        <v-divider></v-divider>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.isAbleToDrive)"
                                 label="Gerät ist fahrbereit?"
                                 :value="selectedTradeIn.isAbleToDrive"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.loadingOnSite)"
                                 label="Verladung vor Ort"
                                 :value="selectedTradeIn.loadingOnSite"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.weightOfEquipment)"
                                 label="Gesamtgewicht Gerät (kg)"
                                 :value="selectedTradeIn.weightOfEquipment"
                                 font-size="body-1"></DescriptionValueDisplay>
        <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedTradeIn.pickupAddress)"
                                 label="Abholadresse"
                                 :value="selectedTradeIn.pickupAddress"
                                 font-size="body-1"></DescriptionValueDisplay>

        <v-divider v-if="selectedTradeIn.other && selectedTradeIn.other.length > 0"></v-divider>
        <div v-if="selectedTradeIn.other && selectedTradeIn.other.length > 0" class="subheading mt-2">
          Sonstiges
        </div>
        <v-divider v-if="selectedTradeIn.other && selectedTradeIn.other.length > 0"></v-divider>
        <div v-if="selectedTradeIn.other && selectedTradeIn.other.length > 0" class="mt-2">
          <v-icon small>message</v-icon>
          {{ ' ' + selectedTradeIn.other }}
        </div>
        <v-divider></v-divider>
        <div class="subheading">
          Bilder
        </div>
        <v-divider></v-divider>
        <ImageShow :images="images" :imageP="images[0]" :path="'client/uploads/tradeIns/w800_'"></ImageShow>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mb-2 mr-2" color="primary" @click="setTradeInDialogStatus">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "../event-bus";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import TradeInService from "@/services/trade-in.service";
import HelperFunctions from "@/utils/helper-functions";
import Constants from "@/utils/constants";
import ImageShow from "@/components/ImageShow";

export default {
  name: "TradeInInformation",
  data() {
    return {
      dialog: false,
      selectedTradeIn: "",
      tradeInType: "",
      tradeInStatus: "",
      imagePath: "uploads/tradeIns/w800/",
      tradeInStatusItems: Constants.statusItems,
    };
  },
  props: [],
  mounted() {
    EventBus.$on("openTradeInDialog", this.setTradeInDialogStatus);
    EventBus.$on("onSelectTradeIn", tradeIn => {
      this.setSelectedTradeIn(tradeIn);
      this.tradeInStatus = this.selectedTradeIn.status;
    });
  },
  components: {
    ImageShow,
    DescriptionValueDisplay
  },
  methods: {
    ...HelperFunctions,
    setSelectedTradeIn(tradeIn) {
      this.selectedTradeIn = tradeIn;
      // this.fillImagesArray(tradeIn);
    },
    setTradeInDialogStatus() {
      this.dialog = !this.dialog;
      EventBus.$emit("onCloseTradeInDialog", true);
    },
    fillImagesArray(tradeIn) {
      this.images = [
        tradeIn.frontLeftImage,
        tradeIn.frontRightImage,
        tradeIn.rearRightImage,
        tradeIn.rearLeftImage,
        tradeIn.operatingHourTerminalImage,
        tradeIn.typeDefinitionImage
      ];
    },
    onChangeStatus() {
      this.loading(true);
      TradeInService.updateTradeInStatus(
          this.$store.state.accessToken
              ? this.$store.state.accessToken.id
              : null, this.selectedTradeIn.id,
          {status: this.tradeInStatus}
      )
          .then(response => {
            this.initiateSuccessInfo("Status der Inzahlungnahme erfolgreich geändert!")
            this.tradeIns = response.data;
          })
          .catch(error => {
            this.initiateErrorInfo("Bitte versuchen Sie es erneut!")
            throw error;
          });
    }
  },
  computed: {
    images: {
      get: function() {
        return [
          this.selectedTradeIn.frontLeftImage,
          this.selectedTradeIn.frontRightImage,
          this.selectedTradeIn.rearRightImage,
          this.selectedTradeIn.rearLeftImage,
          this.selectedTradeIn.operatingHourTerminalImage,
          this.selectedTradeIn.typeDefinitionImage
        ];
      }
    }
  }
};
</script>
