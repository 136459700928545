<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-toolbar dark color="primary px-3">
        <v-toolbar-title>Equipment aus Datei importieren</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="subheading" v-if="!fileName">
          Wählen Sie bitte eine Datei aus, aus der Sie Daten importieren möchten.
        </p>
        <p class="subheading" v-else>
          Möchten Sie aus dieser Datei:<br> <b>{{fileName}}</b><br> Daten importieren?
        </p>
        <v-radio-group class="linde" v-model="fileType" inline>
          <template v-slot:label>
            <div>Dateityp wählen:</div>
          </template>
          <v-radio label="csv" color="primary" value="csv"></v-radio>
          <v-radio label="xml" color="primary" value="xml"></v-radio>
        </v-radio-group>
        <v-btn
            color="primary"
            class="normalize-font"
            flat
            @click="uploadEquipment"
        >
          <span style="display: flow">
              <v-icon small >attach_file</v-icon> Datei wählen
          </span>
        </v-btn>
        <input
            v-if="fileType === 'csv'"
            type="file"
            name="CSV"
            style="display: none"
            ref="csv"
            accept="text/csv"
            @change="onFilePicked"
        />
        <input
            v-else
            type="file"
            name="XML"
            style="display: none"
            ref="xml"
            accept="text/xml"
            @change="onFilePickedXML"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="normalize-font mb-2"
            flat
            @click="onCancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
            class="normalize-font mb-2 mr-2"
            color="primary"
            @click="onImportData()"
            :disabled="!isFileSelected"
        >
          Importieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EquipmentServices from "@/services/equipment.services";
import {EventBus} from "@/event-bus";
import {mapActions, mapState} from "vuex";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "EquipmentImportDialog",
  data: function () {
    return {
      dialog: false,
      fd: new FormData(),
      fileName: "",
      isFileSelected: false,
      fileType: "csv"
    }
  },
  props: {},
  mounted() {
    EventBus.$on("openEqImportDialog", () => {
      this.dialog = true
    })
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["getEquipmentData"]),
    uploadEquipment() {
      if (this.fileType === 'csv') {
        this.$refs.csv.click();
      } else {
        this.$refs.xml.click()
      }
    },
    onFilePicked(e) {
      this.fileName = e.target.files[0].name;
      this.isFileSelected = e.target.files.length > 0
      this.fd.append("CSV", this.$refs.csv.files[0]);
    },
    onFilePickedXML(e) {
      this.fileName = e.target.files[0].name;
      this.isFileSelected = e.target.files.length > 0
      this.fd.append("XML", this.$refs.xml.files[0]);
    },
    onImportData() {
      if (this.fileType === 'csv') {
        EquipmentServices.importFromCsv(this.accessToken, this.fd)
            .then(response => {
              this.initiateSuccessInfo("Daten wurden erfolgreich importiert.")
              this.getEquipmentData();
              this.onCancel()
              if (response.status == 200) {
                setTimeout(this.onCancel, 200);
              }
            })
            .catch(() => {
              this.initiateErrorInfo("Ein Fehler ist aufgetreten!")
            });
      } else {
        EquipmentServices.importFromXML(this.accessToken, this.fd)
            .then(response => {
              this.initiateSuccessInfo("Daten wurden erfolgreich importiert.")
              this.getEquipmentData();
              this.onCancel()
              if (response.status == 200) {
                setTimeout(this.onCancel, 200);
              }
            })
            .catch(() => {
              this.initiateErrorInfo("Ein Fehler ist aufgetreten!")
            });
      }
    },
    onCancel() {
      this.dialog = false
      this.fileName = ""
      this.fd = new FormData()
      this.isFileSelected = false
      this.$refs.csv.value = "";
    },
  }
};
</script>
