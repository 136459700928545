import Api from "./api";

const RESOURCE_NAME = "Searches";

const getSearches = auth => {
    return Api().get(RESOURCE_NAME, {
        headers: {
            Authorization: returnAccessToken(auth)
        }
    });
};

const postSearch = (auth, payload) => {
    return Api().post(
        `${RESOURCE_NAME}?access_token=${returnAccessToken(auth)}`,
        payload,
        {
            headers: {
                Authorization: auth ? auth.id : null
            }
        }
    );
};

const deleteSearch = (auth, id) => {
    return Api().delete(
        `${RESOURCE_NAME}/${id}?access_token=${returnAccessToken(auth)}`,
        {
            headers: {
                Authorization: auth ? auth.id : null
            }
        }
    );
};

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getSearches,
    postSearch,
    deleteSearch
};
