<template>
  <v-dialog :value="dialog" max-width="600" persistent transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title class="title">
        Suchanfrage von {{ selectedSearchInquiry.companyOrganization }} - {{ selectedSearchInquiry.name }}
      </v-card-title>
      <v-card-text>

        <v-flex class="" md5>
          <v-select
              :items="searchStatusItems"
              v-model="searchStatus"
              label="Status"
              @input="onChangeStatus"
          ></v-select>
        </v-flex>
        <div class="subheading">
          Angaben der Suchanfrage
        </div>
        <v-divider></v-divider>
        <div class="mt-2 mb-2">
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.propulsion"
                                   label="Antrieb"
                                   :value="selectedSearchInquiry.propulsion"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0"
                                   v-if="proofExistenceOfValue(selectedSearchInquiry.type)"
                                   label="Typ"
                                   :value="selectedSearchInquiry.type"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.loadCapacities"
                                   label="Tragfähigkeit (kg)"
                                   :value="selectedSearchInquiry.loadCapacities"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.constructionYear"
                                   label="Baujahr bis"
                                   :value="selectedSearchInquiry.constructionYear"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.operatingHours"
                                   label="Betriebsstunden (h) bis"
                                   :value="selectedSearchInquiry.operatingHours"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.fullCabin"
                                   label="Vollkabine"
                                   :value="selectedSearchInquiry.fullCabin"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.mastType"
                                   label="Hubmast"
                                   :value="selectedSearchInquiry.mastType"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.liftingHeight"
                                   label="Hubhöhe (mm)"
                                   :value="selectedSearchInquiry.liftingHeight"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.height"
                                   label="Bauhöhe (mm)"
                                   :value="selectedSearchInquiry.height"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedSearchInquiry.additionalHydraulic"
                                   label="Hydraulik"
                                   :value="selectedSearchInquiry.additionalHydraulic"
                                   font-size="body-1"></DescriptionValueDisplay>
          <DescriptionValueDisplay class="my-0 py-0" v-if="proofExistenceOfValue(selectedSearchInquiry.attachmentUnit)"
                                   label="Anbaugerät"
                                   :value="selectedSearchInquiry.attachmentUnit"
                                   font-size="body-1"></DescriptionValueDisplay>

        </div>


        <div class="subheading">
          Budget
        </div>
        <v-divider></v-divider>
        <div class="mt-2">
           <span
               class="subheading text-md-right text-sm-right text-xs-right linde-font linde-color linde-bold"
           >€ {{ formatNumbers(selectedSearchInquiry.budget) }}
                  </span>
        </div>
        <div class="subheading mt-2">
          Kontakt
        </div>
        <v-divider></v-divider>
        <div class="mt-2">
          <v-icon small>person</v-icon>
          {{ selectedSearchInquiry.name }}
        </div>
        <div class="">
          <v-icon small>business</v-icon>
          {{ ' ' + selectedSearchInquiry.companyOrganization }}
        </div>
        <div class="">
          <v-icon small>map</v-icon>
          {{ selectedSearchInquiry.address ? selectedSearchInquiry.address+ ', ' + selectedSearchInquiry.zipCode + ' ' + selectedSearchInquiry.place : "-"  }}
        </div>
        <div class="mt-2">
          <v-icon small>phone</v-icon>
          {{ selectedSearchInquiry.phone ? ' ' + selectedSearchInquiry.phone : '-' }}
        </div>
        <div class="">
          <v-icon small>mail</v-icon>
          {{ ' ' + selectedSearchInquiry.email }}
        </div>

        <div v-if="selectedSearchInquiry.other && selectedSearchInquiry.other.length > 0" class="subheading mt-2">
          Sonstiges
        </div>
        <v-divider v-if="selectedSearchInquiry.other && selectedSearchInquiry.other.length > 0"></v-divider>
        <div v-if="selectedSearchInquiry.other && selectedSearchInquiry.other.length > 0" class="mt-2">
          <v-icon small>message</v-icon>
          {{ ' ' + selectedSearchInquiry.other }}
        </div>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mb-2 mr-2" color="primary" @click="setSearchInquiryDialogStatus">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "../event-bus";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import SearchInquiriesServices from "@/services/search-inquiry.service";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SearchInquiryInformation",
  data() {
    return {
      dialog: false,
      selectedSearchInquiry: "",
      statusType: "",
      searchStatus: "",
      searchStatusItems: [
        {text: "Offen", value: "open"},
        {text: "In Bearbeitung", value: "inProgress"},
        {text: "Abgeschlossen", value: "completed"},
      ]
    };
  },
  props: [],
  mounted() {
    EventBus.$on("openSearchInquiryDialog", this.setSearchInquiryDialogStatus);
    EventBus.$on("onSelectSearchInquiry", searchInquiry => {
      this.setSelectedSearchInquiry(searchInquiry);
      this.searchStatus = this.selectedSearchInquiry.status;
    });
  },
  components: {
    DescriptionValueDisplay
  },
  methods: {
    ...HelperFunctions,
    setSelectedSearchInquiry(searchInquiry) {
      this.selectedSearchInquiry = searchInquiry;
      switch (this.selectedSearchInquiry.status) {
        case "open":
          this.statusType = "circle";
          break;
        case "inProgress":
          this.statusType = "change_circle";
          break;
        case "completed":
          this.statusType = "done";
          break;
        default:
          break;
      }
    },
    setSearchInquiryDialogStatus() {
      this.dialog = !this.dialog;
      EventBus.$emit("onCloseSearchInformationDialog", true);
    },
    onChangeStatus() {
      this.loading(true);
      SearchInquiriesServices.updateSearchInquiryStatus(
          this.$store.state.accessToken
              ? this.$store.state.accessToken.id
              : null, this.selectedSearchInquiry.id,
          {status: this.searchStatus}
      )
          .then(response => {
            this.initiateSuccessInfo("Status der Suchanfrage erfolgreich geändert!")
            this.searchInquiries = response.data;
          })
          .catch(error => {
            this.initiateErrorInfo("Bitte versuchen Sie es erneut!")
            throw error;
          });
    }
  }
};
</script>
