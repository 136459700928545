<template>
  <div class="search-inquiry-form linde-font">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Ansprechpartner {{ operation }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-layout row wrap>
              <v-flex md12>
                <v-layout v-if="pickedImage" row wrap>
                  <v-flex md12>
                    <v-layout class="mb-2" row wrap align-center justify-center>
                      <v-flex class="text-md-center pl-2" md12 v-if="pickedImage.image">
                        <v-img
                            v-if="pickedImage.url"
                            class="mb-2"
                            :src="pickedImage.url"
                            width="150"
                            height="200"
                            aspect-ratio="0.75"
                        >
                        </v-img>
                        <v-img
                            v-else
                            class="mb-2"
                            :src="appendBaseUrl(pickedImage.image)"
                            width="150"
                            height="200"
                        ></v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md12>
                <v-layout row wrap align-center justify-center>
                  <v-flex md12>
                    <v-img
                        v-if="!pickedImage.image"
                        class="mb-2 ml-3"
                        :src="require('@/assets/contact-person-images/placeholder-person.png')"
                        width="150"
                        height="200"
                    ></v-img>
                  </v-flex>
                  <v-flex md12>
                    <v-btn
                        color="primary"
                        class="normalize-font"
                        flat
                        @click="pickFile()"
                    >
                      *<v-icon small>attach_file</v-icon>
                      Profilbild auswählen
                    </v-btn>
                    <input
                        type="file"
                        style="display: none"
                        ref="images"
                        accept="image/*"
                        @change="onFilePicked"
                        multiple
                    />
                  </v-flex>
                  <v-flex v-if="showMissingImageMessage" md12>
                    <span class="error--text">Bitte wählen Sie ein Profilbild aus.</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                    label="*Vorname"
                    required
                    :rules="rules.nameRules"
                    v-model="contactPerson.firstName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*Nachname"
                    required
                    :rules="rules.nameRules"
                    v-model="contactPerson.lastName"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*Role"
                    required
                    v-model="contactPerson.careerLevel"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*Email"
                    persistent-hint
                    :rules="rules.emailRules"
                    v-model.trim="contactPerson.email"
                    hint=""
                    required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*Telefon"
                    persistent-hint
                    :rules="rules.phoneRules"
                    v-model.trim="contactPerson.phone"
                    hint="Diese wird für den Ausdruck der PDF verwendet."
                    required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*Mobil"
                    persistent-hint
                    :rules="rules.phoneRules"
                    v-model.trim="contactPerson.mobilePhone"
                    hint="Diese wird für den Ausdruck der PDF verwendet."
                    required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    label="*PLZ Bereich"
                    persistent-hint
                    v-model.trim="contactPerson.zip"
                    hint="Bsp.: 45345, 34543, ..."
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-spacer></v-spacer>
              <small>*indiziert Pflichtfelder</small>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              flat
              @click.native="onClose()"
          >Abbrechen
          </v-btn
          >
          <v-btn v-if="operation === 'anlegen'"
                 class="normalize-font mb-2 mr-2"
                 color="primary"
                 @click.native="addContactPerson"
          >Anlegen
          </v-btn>
          <v-btn v-else
                 class="normalize-font mb-2 mr-2"
                 color="primary"
                 @click.native="updateContactPerson"
          >Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CONSTANTS from "../utils/constants";
import HELPER_FUNCTIONS from "../utils/helper-functions";
import {EventBus} from "@/event-bus";
import ContactPersonService from "@/services/contact-person.service";

let fds = new FormData(), fDs = []
export default {
  name: "ContactPersonForm",
  data() {
    return {
      rules: CONSTANTS.formRules,
      constants: CONSTANTS,
      valid: false,
      pickedImage: "",
      contactPerson: this.getEmptyContactPerson(),
      dialog: false,
      copyOfEnteredData: {},
      operation: "anlegen",
      showMissingImageMessage: false
    };
  },
  components: {},
  mounted() {
    EventBus.$on("openCPDialog", this.setDialogStatus)
    EventBus.$on("onSelectContactPerson", data => {
      if (data.operation === "edit") {
        this.operation = "bearbeiten"
        this.contactPerson = data.cp
        if (this.contactPerson.image) {
          this.pickedImage = this.contactPerson.image;
        }
      }
      if (data.operation === "add") {
        this.operation = "anlegen"
        this.pickedImage = ""
      }
    })
  },
  methods: {
    ...HELPER_FUNCTIONS,
    setDialogStatus() {
      this.dialog = !this.dialog;
    },
    onClose() {
      this.pickedImage = ""
      this.showMissingImageMessage = false
      this.resetValidation()
      this.resetForm()
      this.setDialogStatus()
      EventBus.$emit("closeCPDialog");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addContactPerson(e) {
      this.valid = this.$refs.form.validate();
      e.preventDefault();
      if (this.valid && this.pickedImage) {
       this.loading(true)
        this.copyOfEnteredData = {
          firstName: this.contactPerson.firstName,
          lastName: this.contactPerson.lastName,
          careerLevel: this.contactPerson.careerLevel,
          email: this.contactPerson.email,
          mobilePhone: this.contactPerson.mobilePhone,
          zip: this.contactPerson.zip,
          image: this.pickedImage ? 'cp_' + this.pickedImage.image : "",
          phone: this.contactPerson.phone,
        };
        ContactPersonService.postContactPeople(this.accessToken, this.copyOfEnteredData)
            .then((response) => {
              if (this.pickedImage) {
                fds.append("image", this.pickedImage.file.file, response.data.id + '_' + this.pickedImage.image);
                try {
                  this.resizeImageAndUpload(
                      {},
                      response.data.id,
                      this.pickedImage.file.file,
                      response.data.id + '_' + this.pickedImage.image,
                      "contact",
                      {
                        quality: 0.8,
                        maxWidth: 300,
                        maxHeight: 400,
                        debug: true
                      });
                } catch (err) {
                  // console.debug(err)
                }
              }
            })
            .then(() => {
              //Scrolls to top when view is displayed
              window.scrollTo(0, 0);
              this.initiateSuccessInfo("Ansprechpartner wurde erfolgreich erstellt!")
              this.resetForm()
              this.onClose()
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben prüfen!")
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben prüfen!")
        this.showMissingImageMessage = true
      }
    },
    updateContactPerson(e) {
      this.valid = this.$refs.form.validate();
      e.preventDefault();
      if (this.valid) {
        this.loading(true)
        this.copyOfEnteredData = {
          id: this.contactPerson.id,
          firstName: this.contactPerson.firstName,
          lastName: this.contactPerson.lastName,
          careerLevel: this.contactPerson.careerLevel,
          email: this.contactPerson.email,
          mobilePhone: this.contactPerson.mobilePhone,
          zip: this.contactPerson.zip,
          image: this.pickedImage ? 'cp_' + this.pickedImage.image : "",
          phone: this.contactPerson.phone,
        };
        ContactPersonService.updateContactPerson(this.accessToken, this.copyOfEnteredData.id, this.copyOfEnteredData)
            .then((response) => {
              if (this.pickedImage.url) {
                fds.append("images", this.pickedImage.file.file, response.data.id + '_' + this.pickedImage.image);
                try {
                  this.resizeImageAndUpload(
                      {},
                      response.data.id,
                      this.pickedImage.file.file,
                      response.data.id + '_' + this.pickedImage.image,
                      "contact",
                      {
                        quality: 0.8,
                        maxWidth: 300,
                        maxHeight: 400,
                        debug: true
                      });
                } catch (err) {
                  // console.debug(err)
                }
              }
            })
            .then(() => {
              //Scrolls to top when view is displayed
              window.scrollTo(0, 0);
              this.initiateSuccessInfo("Ansprechpartner wurde erfolgreich aktualisiert!")
              this.resetForm()
              this.onClose()
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben prüfen!")
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben überprüfen!")
      }
    },
    pickFile() {
      this.$refs.images.click();
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      fDs.push({file: file, name: file["name"]});
      if (file !== undefined) {
        this.pickedImage = {
          file: {file: file, name: file["name"]},
          name: this.name,
          image: file.name,
          url: URL.createObjectURL(file)
        };
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
      } else {
        this.pickedImage = {};
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.images.value = "";
    },
    onClickTestButton() {
      this.contactPerson.firstName = "Max"
      this.contactPerson.lastName = "Muster"
      this.contactPerson.careerLevel = "Beratung & Verkauf"
      this.contactPerson.email = "muster@mail.com"
      this.contactPerson.phone = "02345 55555"
      this.contactPerson.mobilePhone = "(+49)123 55555"
      this.contactPerson.zip = "45000, 456000"
    },
  },
  computed: {
    ...mapState(["user", "accessToken"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  }
};
</script>

<style>
label.v-label {
  font-size: 14px;
}
</style>
