<template>
  <v-layout class="favorites-list" row wrap>
    <v-flex md12>
      <v-card class="mb-2">
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
           <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
             <b>Favoriten</b></span>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-flex md12  v-if="favorites.length > 0">
        <div v-for="equipment in favorites" :key="equipment.equipmentId">
          <v-card
              class="mb-3 cursor"
              v-if="equipment.active === 0 || equipment.active === null"
              :disabled="!equipments.find(e => e.equipmentId === equipment.equipmentId)"
          >
            <v-card-title @click="onSelectEquipment(equipment)" class="pa-0">
              <v-layout wrap row>
                <v-flex md3 xs12 sm4 class="">
                  <v-img
                      v-if="equipment.images && equipment.images.length > 0 && equipment.images[0].image"
                      class="white--text"
                      :src="appendBaseUrl(equipment.images[0].image)"
                      :aspect-ratio="1.333"

                  >
                    <template v-slot:placeholder>
                      <v-layout fill-height align-center justify-center ma-0>
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                      </v-layout>
                    </template>
                  </v-img>
                  <v-img
                      v-else
                      class="white--text"
                      :src="require('@/assets/placeholder-image.jpg')"
                      :aspect-ratio="1.333"
                  >
                    <template v-slot:placeholder>
                      <v-layout fill-height align-center justify-center ma-0>
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                      </v-layout>
                    </template>
                  </v-img>
                </v-flex>
                <v-flex md9 xs12 sm8 class="mt-2">
                  <v-layout class="px-3 pb-0" wrap row justify-space-between>
                    <v-flex class="subheading mb-0 pb-0" md9 xs9 sm8>
                  <span class="linde-font linde-color linde-bold">{{
                      equipment.type
                    }}</span>
                    </v-flex>
                    <v-flex d-flex class="mb-0 pb-0 text-lg-left">
                  <span
                      class="subheading text-md-right text-sm-right text-xs-right linde-font linde-color linde-bold"
                  >€ {{ formatNumbers(equipment.price) }}
                          <span
                              class="body-1" style="color: #636363; font-family: linde;">*</span>
                  </span>
                    </v-flex>
                  </v-layout>
                  <v-layout class="px-3 pb-2" wrap row justify-space-between>
                    <v-flex md12 xs12 sm12>
                  <span class="subheading linde-font mb-1 "
                  >{{ equipment.constructionType }}
                    {{
                      equipment.additionalConstructionType
                          ? equipment.additionalConstructionType
                          : ""
                    }}</span
                  >
                    </v-flex>
                    <v-flex class="my-0 py-0" md5 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.constructionYear"
                          label="Baujahr:"
                          :value="equipment.constructionYear"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.constructionYear ||
                        equipment.constructionYear === 0
                    "
                          label="Baujahr:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.liftingHeight"
                          label="Hubhöhe:"
                          :value="equipment.liftingHeight"
                          unit="mm"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.liftingHeight || equipment.liftingHeight === 0
                    "
                          label="Hubhöhe:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md5 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.loadCapacity"
                          label="Traglast:"
                          :value="equipment.loadCapacity"
                          unit="kg"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.loadCapacity || equipment.loadCapacity === 0
                    "
                          label="Traglast:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.operatingHours"
                          label="Betriebsstunden:"
                          :value="formatNumbers(equipment.operatingHours)"
                          unit="h"
                      />
                      <DescriptionValueDisplay
                          v-if="!equipment.operatingHours"
                          label="Betriebsstunden:"
                          value="Auf Anfrage"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.operatingHours ||
                        equipment.operatingHours === 0
                    "
                          label="Betriebsstunden:"
                          value="Auf Anfrage"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md5 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.height"
                          label="Bauhöhe:"
                          :value="equipment.height"
                          unit="h"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.height ||
                        equipment.height === 0
                    "
                          label="Bauhöhe:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.additionalHydraulic"
                          label="Hydraulik:"
                          :value="equipment.additionalHydraulic"
                          unit="h"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.additionalHydraulic ||
                        equipment.additionalHydraulic === 0
                    "
                          label="Hydraulik:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md5 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.mastType"
                          label="Mast:"
                          :value="equipment.mastType"
                          unit="h"
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.mastType ||
                        equipment.mastType === 0
                    "
                          label="Mast:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.attachmentUnit"
                          label="Anbaugerät:"
                          :value="equipment.attachmentUnit"
                          unit=""
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.attachmentUnit ||
                        equipment.attachmentUnit === 0
                    "
                          label="Anbaugerät:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md5 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.serialNumber"
                          label="Seriennr.:"
                          :value="equipment.serialNumber"
                          unit=""
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.serialNumber ||
                        equipment.serialNumber === 0 && isLoggedIn
                    "
                          label="Seriennr:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                      <DescriptionValueDisplay
                          v-if="equipment.AProdID"
                          label="Anb. Prod.-Nr.:"
                          :value="equipment.AProdID"
                          unit=""
                      />
                      <DescriptionValueDisplay
                          v-if="
                      !equipment.AProdID ||
                        equipment.AProdID === 0
                    "
                          label="Anb. Prod.-Nr.:"
                          value="-"
                      />
                    </v-flex>
                    <v-flex v-if="equipment.comment" class="mt-4">
                      <b>Kommentar: {{equipment.comment}}</b>
                    </v-flex>
                    <v-flex class="my-0 py-0" md7 sm12 xs12>
                  <span
                      class=""
                      style="font-size: 10px;color: #a2a2a2; font-family: linde;position: absolute;right: 15px;bottom: 0;">
                    * Preis exkl. MwSt.
                  </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-divider class=""></v-divider>
            <v-card-actions>
              <v-btn flat color="primary" @click="setFavorite(equipment)">Entfernen</v-btn>
            </v-card-actions>
          </v-card>
        </div>
    </v-flex>
    <v-flex md12 class="text--center" v-else>
      <v-card class="pb-4 min-height-400">
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
           <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
             <b>Noch keine Fahrzeuge in der Merkliste.</b></span>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "FavoriteList",
  data() {
    return {
      images: [],
      favoriteEquipments: [],
    };
  },
  components: {
    DescriptionValueDisplay
  },
  mounted() {
    this.clearFavoritesAndComparesFromDeletedItems({ favorites: this.favorites, compares: [] })
  },
  computed: {
    ...mapState(["favorites", "equipments", "constructionYearSpan", "accessToken"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  },
  methods: {
    ...HelperFunctions,
    onSelectEquipment(equipment) {
      equipment.image = "";
      equipment.imagesReal = [];
      this.$store.commit("setSelectedEquipment", equipment);
      this.$router.push("detail/" + equipment.equipmentId);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
