<template>
  <div class="equipment-details">
    <div class="equipment-details" v-if="equipment">
      <InquiryForm :equipment="equipment"
                   :assigned-customer-intend="customerIntend"
                   :assigned-run-time="runTime"
                   :assigned-residual="residual"
                   v-if="callForEquipment">
        <template v-slot:back>
          <v-btn
              small
              flat
              class="normalize-font"
              color="primary"
              @click="goBack"
          >
            <v-icon small>arrow_back</v-icon>
            zurück zur Detailansicht
          </v-btn>
        </template>

        <template v-slot:title>
          <span class="title linde-color"> Angebot: {{ equipment ? equipment.type : "" }} </span>
        </template>

        <template v-slot:action>
          <v-btn
              small
              flat
              class="normalize-font"
              color="primary"
              @click="goBack"
          >
            <v-icon small>arrow_back</v-icon>
            zurück zur Detailansicht
          </v-btn>
        </template>
      </InquiryForm>

      <v-flex
          class="text-md-left text-sm-left text-xs-left mt-0 py-0 mb-1"
          md12
          sm12
          xs12
          v-if="!callForEquipment"
      >
        <v-btn
            class="ma-0 pa-0 normalize-font"
            color="primary"
            to="/result"
            flat
            small
        >
          <v-icon small>arrow_back</v-icon>
          zurück zu den Ergebnissen
        </v-btn>
      </v-flex>

      <v-card class="mt-1 mb-3 pt-0" v-if="!callForEquipment">
        <v-layout row wrap>
          <v-flex md4 sm12 xs12 class="mt-0 pt-0">
            <EquipmentImages :equipment="equipment"></EquipmentImages>
          </v-flex>
          <v-divider class="hidden-md-and-up"></v-divider>
          <v-flex class="px-3" md8 sm12 xs12>
            <v-layout>
              <v-flex md8 sm10 xs10>
                <p class="headline linde-font linde-color linde-bold mb-0 pb-0">
                  {{ equipment ? equipment.type : "" }}
                </p>
                <p>
                  {{ equipment.constructionType }}
                  {{
                    equipment.additionalConstructionType
                        ? "/ " + equipment.additionalConstructionType.replace("/", "")
                        : ""
                  }}
                </p>
              </v-flex>

              <!-- Email -->

              <v-flex class="text-md-right text-sm-right text-xs-right" d-flex>
                <v-btn
                    class="ma-0 py-0 pl-0 pr-2 hidden-xs-only normalize-font"
                    small
                    flat
                    color="primary"
                    @click="showInquiryForm"
                >
                  <v-icon class="mr-1 ma-0 pa-0" small>email</v-icon>
                  Fahrzeug anfragen
                </v-btn>
                <v-btn
                    class="my-0 ml-0 mr-1 hidden-sm-and-up"
                    color="primary"
                    icon
                    @click="showInquiryForm"
                    flat
                >
                  <v-icon class="ma-0 pa-0" small>email</v-icon>
                </v-btn>
              </v-flex>

              <!-- Print PDF -->

              <v-flex
                  class=" text-md-right text-sm-right text-xs-right"
                  md2
                  sm2
                  xs1
                  d-flex
              >
                <v-btn
                    class="ma-0 py-0 pl-0 pr-2 hidden-xs-only normalize-font"
                    color="primary"
                    @click="showZipCodeDialog('print')"
                    flat
                    small
                >
                  <v-icon class="mr-1 ma-0 pa-0" small>print</v-icon>
                  Als PDF laden
                </v-btn>
                <v-btn
                    class="my-0 ml-0 mr-1 hidden-sm-and-up"
                    color="primary"
                    icon
                    @click="getPDF"
                    flat
                >
                  <v-icon class="ma-0 pa-0" small>print</v-icon>
                </v-btn>
              </v-flex>

              <!-- Download word document -->

              <v-flex
                  class=" text-md-right text-sm-right text-xs-right"
                  md2
                  sm2
                  xs1
                  d-flex
                  v-if="isLoggedIn"
              >
                <v-btn
                    class="ma-0 py-0 pl-0 pr-2 hidden-xs-only normalize-font"
                    color="primary"
                    @click="getWordDocument()"
                    flat
                    small
                >
                  <v-icon name="fa-thumbs-up" class="mr-1 ma-0 pa-0" small
                  >description<span class="material-icons">
                      description
                    </span></v-icon
                  >
                  Als Dokument laden
                </v-btn>
                <v-btn
                    class="my-0 ml-0 mr-1 hidden-sm-and-up"
                    color="primary"
                    icon
                    @click="getWordDocument()"
                    flat
                >
                  <v-icon class="ma-0 pa-0" small>description</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md9 sm9 xs12>
                <DescriptionValueDisplay
                    class="my-0 py-0"
                    v-if="equipment.serialNumber && isLoggedIn"
                    label="Seriennummer"
                    :value="equipment.serialNumber"
                    font-size="body-1"
                ></DescriptionValueDisplay>
                <DescriptionValueDisplay
                    class="body-1 my-0 py-0"
                    v-if="equipment.providerProductNumber"
                    label="Anbieter Produkt-Nr."
                    :value="equipment.providerProductNumber"
                    font-size="body-1"
                ></DescriptionValueDisplay>
              </v-flex>
              <v-flex
                  class="text-md-right text-xs-left text-sm-right pt-3 pr-3"
                  md3
                  sm3
                  xs12
                  dflex
              >
                <div
                    class="headline text-md-right text-xs-left text-sm-right linde-font linde-color linde-bold ma-0 pa-0"
                >
                  {{ equipment ? formatNumbers(equipment.price) : " " }} €
                </div>
              </v-flex>
              <v-flex class="hidden-xs-only">
                <p style="font-size: 10px; color: rgb(162, 162, 162);text-align: end;margin-bottom: 0;margin-top: 0;padding: 0; padding-right: 10px;">
                  Preis exkl. MwSt.</p>
              </v-flex>
              <v-flex class="hidden-sm-and-up">
                <p style="font-size: 10px; color: rgb(162, 162, 162);text-align: start;margin-bottom: 0;margin-top: 0;padding: 0;">
                  Preis exkl. MwSt.</p>
              </v-flex>
            </v-layout>
            <v-divider class="my-2 mr-2" style=""></v-divider>
            <EquipmentTechnicalDetails
                :equipment="equipment"
            ></EquipmentTechnicalDetails>
            <v-divider v-if="equipment.isFundable && isLoggedIn" class="my-2 mr-2" style=""></v-divider>
            <v-layout v-if="equipment.isFundable && isLoggedIn">
              <v-flex>
                <v-btn
                    v-if="!provider"
                    class="mt-2 ma-0 py-0 pl-2 pr-2 hidden-xs-only normalize-font"
                    color="primary"
                    @click="showFundableDialog"
                    flat
                    small
                >
                  Mietkauf-Beispiel berechen
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="" small v-on="on">info_outline</v-icon>
                  </template>
                  <span>
                    <p>Soll der Stapler letztlich Ihnen gehören, seine Nutzung also mit einem <br>
                      Erwerb verbunden sein, bietet sich der Mietkauf an. Mit individueller<br>
                      Ratengestaltung und konstanter Mietkaufrate funktioniert dieses<br>
                      Modell wie der Erwerb durch Kredit, bei flexibler Gestaltung der Bilanz.<br>
                      </p>
                      <b>Vorteile</b><br>
                        - kein Eigenkapitaleinsatz für die Anschaffung durch Kauf auf Kredit <br>
                        - Bilanzierung beim Mietkäufer eröffnet Option auf Fördermittel<br>
                        - Freie Vereinbarung der Laufzeit bietet wirtschaftliche Optimierung der Raten<br>
                  </span>
                </v-tooltip>
              </v-flex>
            </v-layout>

            <div v-if="provider">
              <img
                  class="mt-3 mb-2"
                  src="../assets/logo-lmh-rr.jpg"
                  height="50"
              />
              <ProviderDetails
                  class="hyphens"
                  :company="provider"
                  :provider="provider"
              ></ProviderDetails>
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex></v-flex>
          <v-flex
              class="px-3 text-md-right text-sm-right text-xs-right"
              md3
              sm3
              xs4
          >
            <v-btn
                v-if="!provider"
                class="mt-3 ma-0 py-0 pl-2 pr-2 hidden-xs-only normalize-font"
                color="primary"
                @click="showZipCodeDialog('contact')"
                flat
                small
            >
              Anbieter kontaktieren
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>

      <v-dialog v-model="zipCodeDialog" max-width="290">
        <v-card>
          <v-toolbar dark color="primary px-3">
            <v-toolbar-title v-if="callToAction === 'print'"
            >Angebot als PDF
            </v-toolbar-title
            >
            <v-toolbar-title v-if="callToAction === 'contact'"
            >Anbieter Kontaktdaten
            </v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            Bitte geben Sie Ihre Postleitzahl ein.
            <v-form
                v-if="callToAction === 'print'"
                v-model="valid"
                ref="form"
                @submit="getPDF"
            >
              <v-text-field
                  v-model="zipCode"
                  :rules="zipCodeRules"
              ></v-text-field>
            </v-form>
            <v-form
                v-if="callToAction === 'contact'"
                v-model="valid"
                ref="form"
                @submit="getContact"
            >
              <v-text-field
                  v-model="zipCode"
                  :rules="zipCodeRules"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="normalize-font mb-2"
                color="primary"
                flat
                @click="zipCodeDialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                v-if="callToAction === 'print'"
                class="normalize-font mb-2 mr-2"
                color="primary"
                @click="getPDF"
            >
              Drucken
            </v-btn>
            <v-btn
                v-if="callToAction === 'contact'"
                class="normalize-font mb-2 mr-2"
                color="primary"
                @click="getContact"
            >
              Anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="financeDialog" max-width="500">
        <v-card>
          <v-toolbar dark color="primary px-3">
            <v-toolbar-title>Mietkauf-Beispiel berechnen</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>Bitte wählen Sie die Laufzeit und den Restwert für das Mietkauf-Beispiel aus.</p>
            <v-layout>
              <v-flex>
                <v-select
                    class="pr-3"
                    v-model="runTime"
                    :items="fundableRuntimes"
                    label="Laufzeit (Monate)"
                    placeholder="Bitte wählen"
                    @change="calculateRates"
                    required
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                    class="pr-3"
                    v-model="residual"
                    :items="fundableResiduals"
                    label="Restwert (Prozent)"
                    placeholder="Bitte wählen"
                    @change="calculateRates"
                    required
                ></v-select>
              </v-flex>
            </v-layout>
            <v-divider class="mb-2"></v-divider>
            <v-layout>
              <v-flex v-if="mRate !== '0,00'">
                <DescriptionValueDisplay label="Monatliche Rate:"
                                         :value="formatNumbers(mRate)"
                                         unit="€"></DescriptionValueDisplay>
                <DescriptionValueDisplay label="Schlussrate:"
                                         :value="formatNumbers(eRate)"
                                         unit="€"></DescriptionValueDisplay>
              </v-flex>
              <v-flex class="" v-if="mRate === '0,00'">
                <DescriptionValueDisplay label="Monatliche Rate:"
                                         :value="'auf Anfrage'"
                                         unit=""></DescriptionValueDisplay>
                <DescriptionValueDisplay label="Schlussrate:"
                                         :value="'auf Anfrage'"
                                         unit=""></DescriptionValueDisplay>
              </v-flex>
            </v-layout>
            <v-divider class="mt-2"></v-divider>
            <v-layout>
              <v-flex class="mt-2">
                Angebot freibleibend.
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="normalize-font mb-2"
                color="primary"
                flat
                @click="financeDialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                class="normalize-font mb-2 mr-2"
                color="primary"
                @click="transferDataToInquiryForm"
            >
              Zur Anfrage
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="equipment-details-no-result" v-if="!equipment">
      <v-card
          style="max-width: 200px; margin-left: auto; margin-right: auto; text-align: center"
      >
        <v-card-text>
          <span class="mt-3">Stapler nicht gefunden!</span>
          <v-btn class="mt-3" color="primary" to="/">Zur Suche</v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import InquiryForm from "@/components/InquiryForm.vue";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay.vue";
import DividerWithLabel from "@/components/DividerWithLabel.vue";
import ProviderDetails from "@/components/ProviderDetails.vue";
import EquipmentImages from "@/components/EquipmentImages.vue";
import EquipmentTechnicalDetails from "@/components/EquipmentTechnicalDetails";
// import ImageServices from "../services/image.service";
import {saveAs} from "file-saver";
import {mapGetters, mapState} from "vuex";
import axios from "axios";
import HelperFunctions from "@/utils/helper-functions";
import constants from "@/utils/constants";
import {runTimes} from "@/assets/fundable-rates/fundable.json";
import {EventBus} from "@/event-bus";
import EquipmentServices from "@/services/equipment.services";
import ContactPersonService from "@/services/contact-person.service";
import ImageService from "@/services/image.service";

function base64toBlob(base64str) {
  base64str = base64str.split(",")[1];
  // decode base64 string, remove space for IE compatibility
  var binary = atob(base64str.replace(/\s/g, ""));
  // get binary length
  var len = binary.length;

  // create ArrayBuffer with binary length
  var buffer = new ArrayBuffer(len);

  // create 8-bit Array
  var view = new Uint8Array(buffer);

  // save unicode of binary data into 8-bit Array
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  return new Blob([view], {type: "application/pdf"});
}

export default {
  name: "EquipmentDetails",
  props: ["equipment"],
  data() {
    return {
      valid: false,
      callForEquipment: false,
      zipCodeDialog: false,
      financeDialog: false,
      fundableRuntimes: constants.runTimes,
      fundableResiduals: constants.residuals,
      runTime: 24,
      residual: 10,
      callToAction: "",
      zipCode: "",
      provider: "",
      zipCodeRules: [
        v => !!v || "Bitte geben Sie eine Postleitzahl an",
        v =>
            /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) ||
            "Ungültige Postleitzahl"
      ],
      endRate: "",
      monthlyRate: 0,
      sum: 0,
      interestAmount: 0,
      customerIntend: "buy"
    };
  },
  components: {
    InquiryForm,
    DescriptionValueDisplay,
    DividerWithLabel,
    ProviderDetails,
    EquipmentImages,
    EquipmentTechnicalDetails
  },
  created() {
    this.provider = null;
  },
  mounted() {
    EventBus.$on('ReloadEquipment', () => {
      this.$router.go()
    })
    this.calculateRates();
  },
  methods: {
    ...HelperFunctions,
    getPDF(e) {
      e.preventDefault();
      this.loading(true)
      this.zipCodeDialog = false
      EquipmentServices.getPdfByZipCode(this.accessToken, this.equipment.equipmentId, this.zipCode)
          .then(response => {
            const options = {
              data: base64toBlob(response.data),
              name: `${this.equipment.type}.pdf`
            };
            try {
              saveAs(options.data, options.name);
            } catch (e) {
              window.open(
                  window.URL.createObjectURL(options.data),
                  "Download",
                  "width=10,height=10,resizable=yes"
              );
            }
            this.loading( false);
          })
          .catch(error => {
            throw error;
          });
    },
    getWordDocument() {
      this.loading( true);
      axios
          .get(
              `${this.$apiURL}Equipments/${
                  this.equipment.equipmentId
              }/docx`
          )
          .then(response => {
            const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
              const byteCharacters = atob(b64Data);
              const byteArrays = [];

              for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              const blob = new Blob(byteArrays, {type: contentType});
              return blob;
            }
            const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            const blob = b64toBlob(response.data, contentType);
            const options = {
              data: blob,
              name: `${this.equipment.type}.docx`
            };
            try {
              saveAs(options.data, options.name);
            } catch (e) {
              window.open(
                  window.URL.createObjectURL(options.data),
                  "Download",
                  "width=10,height=10,resizable=yes"
              );
            }
            this.loading( false);
          })
          .catch(error => {
            throw error;
          });
    },
    getContact(e) {
      e.preventDefault();
      this.$refs.form.validate();
      if (this.valid) {
        ContactPersonService.getContactPersonByZip(this.zipCode)
            .then((res) => {
              this.provider = res.data
              ImageService.getImagesByContactPersonId("", res.data.id)
                  .then(imgRes => {
                    this.provider.image = imgRes.data[0].image
                  })
            })
            .catch(error => {
              throw error;
            })
        this.zipCodeDialog = false;
      }
    },
    goBack() {
      this.callForEquipment = !this.callForEquipment;
      //Scrolls to top when view is displayed
      window.scrollTo(0, 0);
    },
    showInquiryForm() {
      this.callForEquipment = !this.callForEquipment;
      //Scrolls to top when view is displayed
      window.scrollTo(0, 0);
    },
    showZipCodeDialog(type) {
      this.callToAction = type;
      this.zipCodeDialog = true;
    },
    showFundableDialog() {
      this.financeDialog = true;
      this.calculateRates();
    },
    transferDataToInquiryForm() {
      this.customerIntend = "finance";
      this.showInquiryForm();
      this.financeDialog = false;
    },
    calculateRates() {
      const localePriceSetting = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
      const price = this.selectedEquipment ? this.selectedEquipment.price : 0;
      if (this.runTime) {
        const runTimeIndex = runTimes.findIndex(r => r.runTime === this.runTime);
        if (this.fundableResiduals.includes(this.residual)) {
          const residualIndex = runTimes[runTimeIndex].residuals.findIndex(r => r.rate === this.residual);
          const factor = runTimes[runTimeIndex].residuals[residualIndex].factor;

          const monthlyRateAsNumber = price * (factor / 100);
          const endRate = price * this.residual / 100;
          this.monthlyRate = monthlyRateAsNumber.toLocaleString('de-DE', localePriceSetting);
          this.endRate = endRate.toLocaleString('de-DE', localePriceSetting);
        }
      }
    },
  },
  computed: {
    ...mapState(["selectedEquipment", "user", "accessToken"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
    mRate() {
      return this.monthlyRate;
    },
    eRate() {
      return this.endRate;
    },
    currentUser() {
      return this.user;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

/* hyphens */
.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
</style>
