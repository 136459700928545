<template>
  <div class="company-logo">
    <div class="hidden-xs-only">
      <img :src="logoLmhRR" height="50" />
    </div>
    <div class="hidden-sm-and-up">
      <v-layout>
        <v-flex>
          <img :src="logoLmhRR" height="50" />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import logoLmhRR from "@/assets/logo-lmh-rr.jpg";

export default {
  name: "CompanyLogo",
  data() {
    return {
      logoLmhRR: logoLmhRR
    };
  }
};
</script>
