<template>
  <v-layout align-start justify-start row>
    <v-flex md12>
      <v-card>
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
          <slot></slot>
        </v-card-title>
        <v-layout
          class="mt-2 text-md-center text-sm-center text-xs-center"
          row
          wrap
        >
          <v-flex
            class="ma-0 pa-0"
            xs4
            sm4
            md4
            v-for="typeItem in typeItems"
            :key="typeItem.value"
          >
            <v-avatar
              :class="[
                'cursor',
                'ma-0',
                'pa-0',
                types.includes(typeItem.type) ? 'isActive' : 'isInactive'
              ]"
              tile="tile"
              @click="onSelectType(typeItem.type)"
              size="65"
            >
              <v-img :src="require('@/assets/' + typeItem.path)" alt="avatar" cover/>
            </v-avatar>
            <p :style="{ fontSize: iconDescriptionSize + 'px' }">
              {{ typeItem.type }}
            </p>
          </v-flex>
        </v-layout>
        <v-layout
          class="hidden-md-and-up mt-2 text-md-center text-sm-center text-xs-center"
          wrap
          justify-space-between
        >
          <v-flex
            class="mx-0 px-0"
            xs2
            sm2
            md2
            v-for="typeItem in typeItems"
            :key="typeItem.value"
          >
            <img
              :class="[
                'cursor',
                'ma-0',
                'pa-0',
                types.includes(typeItem.type) ? 'isActive' : 'isInactive'
              ]"
              @click="onSelectType(typeItem.type)"
              :src="require('@/assets/' + typeItem.path)"
              height="10"
              alt="avatar"
            />
          </v-flex>
        </v-layout>
        <v-card-title
          class="subheading slighten-2 ma-0 px-3 pt-2 pb-0"
          primary-title
        >
          <span
            class="font-weight-medium"
            style="color:#AA0020; font-family: linde;"
            ><b>Filter</b></span
          >
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container grid-list-md class="px-3" fluid>
            <v-layout wrap justify-space-between>
              <v-flex md12 xs12 sm12>
                <SearchSelectField
                  label="Tragfähigkeit"
                  :options="loadCapacities"
                  propertyName="loadCapacity"
                />
              </v-flex>
              <v-flex class="mt-2" md12 xs12 sm12>
                <v-layout v-resize="resizeDescription" wrap row>
                  <v-flex md6 sm6 xs6>
                    <label
                      class="font-weight-bold"
                      :style="{ fontSize: descriptionFontSize + 'px' }"
                    >
                      Masttyp
                    </label>
                  </v-flex>
                  <v-flex md5 sm6 xs6>
                    <label
                      class="font-weight-bold"
                      :style="{ fontSize: descriptionFontSize + 'px' }"
                    >
                      Komponenten/Typ
                    </label>
                  </v-flex>
                  <v-flex md5 sm6 xs6>
                    <v-select
                      class="my-0 pa-0"
                      dense
                      :items="mastTypes"
                      hide-details
                      v-model="mastType"
                      @input="onSelectMastType"
                    ></v-select>
                  </v-flex>
                  <v-flex offset-md1 md5 xs6 sm6>
                    <v-select
                      class="my-0 pa-0"
                      dense
                      :items="componentTypes"
                      hide-details
                      :disabled="!search.types.includes('Komponente') ||
                          search.types.length === 0"
                      v-model="componentType"
                      @input="onSelectComponentType"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-space-between>
              <v-flex class="mt-2" md12 xs12 sm12>
                <SearchSelectField
                  label="Hubhöhe"
                  :options="liftingHeights"
                  propertyName="liftingHeight"
                />
              </v-flex>
              <v-flex class="mt-2" md12 xs12 sm12>
                <SearchSelectField
                  label="Bauhöhe"
                  :options="mastHeights"
                  propertyName="height"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap justify-space-between>
              <v-flex class="mt-2" md12 xs12 sm12>
                <v-layout v-resize="resizeDescription" wrap row>
                  <v-flex class="mt-2" md5 sm6 xs6>
                    <label
                      class="font-weight-bold"
                      :style="{ fontSize: descriptionFontSize + 'px' }"
                    >
                      Antrieb
                    </label>
                    <v-select
                      v-if="
                        search.types.includes('Gegengewichtstapler') ||
                          search.types.length === 0
                      "
                      class="my-0 pa-0"
                      dense
                      :items="propulsion"
                      hide-details
                      v-model="propulsionType"
                      @input="onSelectPropulsion"
                    ></v-select>
                    <v-select
                      v-else
                      class="my-0 pa-0"
                      dense
                      :items="['Elektro']"
                      hide-details
                      v-model="propulsionType"
                      @input="onSelectPropulsion"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md6 xs6 sm6> </v-flex>
            </v-layout>

            <v-layout wrap justify-space-between>
              <!--  <v-flex class="mt-2" md12 xs12 sm12>
                                <SearchSelectField label="Baujahr"
                                                   :options="constructionYearSpan"
                                                   propertyName="constructionYear"/>
                            </v-flex> -->
              <v-flex class="mt-2" md12 xs12 sm12>
                <SearchSelectField
                  label="Betriebsstunden"
                  :options="operatingHourSpan"
                  propertyName="operatingHours"
                />
              </v-flex>
              <v-flex class="mt-2" md12 xs12 sm12>
                <SearchSelectField
                  label="Preis"
                  :options="prices"
                  propertyName="price"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="hidden-sm-and-down"
            small
            color="primary"
            @click="resetFilter"
          >
            Filter zurücksetzen
          </v-btn>
          <slot name="closeBottomSheetButton"></slot>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SearchSelectField from "@/components/SearchSelectField";
import CONTANTS from "../utils/constants";

export default {
  name: "SearchFilter",
  data: () => ({
    typeItems: CONTANTS.typeItems,
    liftingHeights: CONTANTS.liftingHeights,
    loadCapacities: CONTANTS.loadCapacities,
    operatingHourSpan: CONTANTS.operatingHour,
    prices: CONTANTS.prices,
    iconDescriptionSize: 11,
    propulsion: CONTANTS.propulsion,
    componentTypes: CONTANTS.componentTypes,
    propulsionAvailable: false,
    propulsionType: "Beliebig",
    componentType: "Beliebig",
    mastType: "Beliebig",
    mastTypes: CONTANTS.mastTypes,
    mastHeights: CONTANTS.mastHeights,
    descriptionFontSize: 14
  }),
  mounted() {
    this.propulsionType =
      this.search.propulsionType.length > 0
        ? this.search.propulsionType[0]
        : "Beliebig";
    this.componentType =
      this.search.componentType.length > 0 !== "" ? this.search.componentType[0] : "Beliebig";
    this.mastType =
      this.search.mastType.length > 0 !== "" ? this.search.mastType[0] : "Beliebig";
    this.getConstructionYearsSpan();
  },
  components: {
    SearchSelectField
  },
  computed: {
    ...mapState({
      constructionYearSpan: state => state.constructionYearSpan,
      types: state => state.search.types,
      search: state => state.search
    })
  },
  watch: {},
  methods: {
    ...mapMutations(["resetQuery"]),
    ...mapActions([
      "getEquipmentData",
      "getConstructionYearsSpan",
      "unsetSearchParameters",
      "setTypes",
      "setPropulsionType",
      "setComponentType",
      "setMastType"
    ]),
    showResult() {
      this.$router.push("result");
    },
    resetFilter() {
      this.unsetSearchParameters();
      this.resetQuery();
      this.propulsionType = "Beliebig";
      this.mastType = "Beliebig";
      this.getEquipmentData();
    },
    onSelectComponentType() {
      this.setComponentType(this.componentType);
      this.getEquipmentData();
    },
    onSelectType(type) {
      let assignedType = type;
      if (!assignedType) assignedType = this.type;
      this.setTypes(type);
      this.getEquipmentData();
      this.propulsionAvailable = this.search.types.includes(
        "Gegengewichtstapler"
      );
      if (this.propulsionAvailable) {
        this.propulsionType = "Beliebig";
        this.onSelectPropulsion();
      }
      if (!this.propulsionAvailable && this.search.types.length === 0) {
        this.propulsionType = "Beliebig";
        this.onSelectPropulsion();
      } else if (!this.propulsionAvailable && this.types.length === 1) {
        this.propulsionType = "Elektro";
        this.onSelectPropulsion();
      }
    },
    onSelectMastType() {
      this.setMastType(this.mastType);
      this.getEquipmentData();
    },
    onSelectPropulsion() {
      this.setPropulsionType(this.propulsionType);
      this.getEquipmentData();
    },
    resizeDescription() {
      if (window.innerWidth < 600) this.descriptionFontSize = 12;
      else this.descriptionFontSize = 14;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.isActive {
  opacity: 1;
}

.isInactive {
  opacity: 0.4;
}

#styled-input {
  height: 40px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 40px;
  font-size: 20pt;
}
</style>
