import "es6-promise/auto";
import "@babel/polyfill";
import Vue from "vue";
import "./plugins/axios";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import constants from "@/utils/constants";


Vue.filter("formatDate", function (value) {
  if (!value) return '';
  value = new Date(value);
  const d = value.getDate() > 9 ? value.getDate() : '0' + value.getDate();
  const m = (value.getMonth() + 1) > 9 ? (value.getMonth() + 1) : '0' + (value.getMonth() + 1);
  const y = value.getFullYear();
  return d + '.' + m + '.' + y;
});

Vue.filter("meter", function (value) {
  if (!value) return '';
  value = value.toString();
  return value + ' mm';
});

Vue.filter("weight", function (value) {
  if (!value) return '';
  value = value.toString();
  return value + ' kg';
});

// set environment in utils/constants file
const apiUrl = constants.baseURL[constants.environment];
const baseUrl = apiUrl.replace("api/", "");

Vue.config.productionTip = false;
Vue.prototype.$apiURL = apiUrl;
Vue.prototype.$baseUrl = baseUrl;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
