<template>
  <div class="no-match-note">
    <div style="" class="note">
      <h4 style="text-transform: uppercase;" class="">Leider entspricht kein Angebot deinen Suchkriterien.</h4>
      <p>Leg hier ein kostenloses Gabelstapler Gesuch an. </p>
    </div>
    <v-btn style="text-transform:capitalize;"
           class="" @click="onOpenSearchMissionForm" color="primary" >
      Gesuch anlegen
    </v-btn>
    <slot name="resetButton"></slot>
    <!--<v-btn style="text-transform:capitalize;" color="primary"
           class="" @click="onResetFilter" ripple small flat>
      Filter zurücksetzen
    </v-btn>-->
  </div>
</template>


<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "NoMatchNote",
  props: {
    equipment: null,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["resetQuery"]),
    ...mapActions([
      "getEquipmentData",
      "unsetSearchParameters",
    ]),
    onOpenSearchMissionForm() {
    },
    onResetFilter() {
        this.unsetSearchParameters();
        this.resetQuery();
        this.getEquipmentData();
    },
  }
};
</script>


<style>
.no-match-note {
  margin-left: 8px;
  margin-bottom: 8px
}
.note {
  margin-bottom: 5px;
}
</style>
