<template>
  <v-card class="mb-3">
    <v-container grid-list-md class="py-1 px-0">
      <v-layout wrap row>
        <v-flex class="" md12 sm12 xs12>
          <v-layout class="px-2 py-1 hidden-sm-and-down">
            <v-flex md4 class="pt-2">
              <span
                class="subheading mt-0"
                style="color:#AA0020; font-family: linde;"
              >
                <b>
                  {{ numberOfActiveEquipments }}
                  Fahrzeug<span v-if="numberOfActiveEquipments !== 1">e </span></b
                ></span
              >
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex md4>
              <v-select
                dense
                :items="sortOrderPossibilities"
                v-model="sortOrder"
                v-on:change="onChangeSortOrder"
                class="my-0 py-0"
                hide-details
              ></v-select>
            </v-flex>
          </v-layout>

          <!-- small layout -->
          <v-layout class="px-2 py-1 hidden-md-and-up">
            <v-flex md3>
              <v-chip color="primary" text-color="white">
                {{ numberOfActiveEquipments }}
                <div class="hidden-xs-only pl-1">
                  Fahrzeug<span v-if="numberOfActiveEquipments !== 1">e </span>
                </div>
              </v-chip>
            </v-flex>
            <v-flex offset-md1 md4>
              <v-select
                dense
                :items="sortOrderPossibilities"
                v-model="sortOrder"
                v-on:change="onChangeSortOrder"
                class="my-0 py-0"
                hide-details
              ></v-select>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex>
              <div class="text-xs-right ml-1 my-0 mr-0 pa-0">
                <v-bottom-sheet v-model="bottomSheetFilter">
                  <v-btn
                    class="mx-0 pa-0"
                    slot="activator"
                    color="primary"
                    small
                    icon
                  >
                    <v-icon>filter_list</v-icon>
                  </v-btn>

                  <SearchForm>
                    <span
                      class="font-weight-medium"
                      style="color:#AA0020; font-family: linde;"
                    >
                      <b>
                        Produktkategorie
                      </b>
                    </span>
                    <template slot="closeBottomSheetButton">
                      <v-btn
                        small
                        block
                        color="primary"
                        @click="bottomSheetFilter = false"
                        v-if="this.$route.path != '/'"
                      >
                        anwenden
                      </v-btn>
                    </template>
                  </SearchForm>
                </v-bottom-sheet>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SearchForm from "@/components/SearchForm";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SortOrder",
  data: () => ({
    sortOrderPossibilities: [
      {
        text: "Preis aufsteigend",
        value: "price_up"
      },
      {
        text: "Preis absteigend",
        value: "price_down"
      },
      {
        text: "Baujahr aufsteigend",
        value: "construction_up"
      },
      {
        text: "Baujahr absteigend",
        value: "construction_down"
      }
    ],
    sortOrder: "price_up",
    bottomSheetFilter: false,
  }),
  props: {
    md: false,
    sm: false
  },
  components: {
    SearchForm
  },
  mounted() {
    const settings = this.$store.state.settings;
    this.sortOrder = settings && settings.itemsSortOder ? settings.itemsSortOder : "price_up";
    this.onChangeSortOrder();
  },
  computed: {
    ...mapState(["equipments"]),
    numberOfActiveEquipments() {
      return this.getActiveElements(this.equipments).length;
    }
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["getEquipmentData"]),
    onChangeSortOrder() {
      this.$store.commit("deleteQueryElement", {
        name: "sortOrder"
      });
      this.$store.commit("updateSortOrder", this.sortOrder);
      if (this.sortOrder === "price_up") {
        this.constructQuery("sortOrder", "price", "ASC");
      } else if (this.sortOrder === "price_down") {
        this.constructQuery("sortOrder", "price", "DESC");
      } else if (this.sortOrder === "construction_up") {
        this.constructQuery("sortOrder", "constructionYear", "ASC");
      } else if (this.sortOrder === "construction_down") {
        this.constructQuery("sortOrder", "constructionYear", "DESC");
      }
    },
    constructQuery(type, sortType, sortOrder) {
      this.$store.commit("updateQuery", {
        name: type,
        query: `filter[order]=${sortType}%20${sortOrder}`
      });
      this.getEquipmentData();
    }
  },
};
</script>

<style></style>
