<template>
  <div class="equipment-images">
    <v-layout row wrap>
      <v-flex md12 sm6 xs12>
        <v-layout>
          <v-flex md12 sm12 xs12>
            <v-img
              position="center center"
              contain
              v-if="image || imageP"
              :src="image ? appendBaseUrl(path + image) :appendBaseUrl(path + imageP)"
              aspect-ratio="1.333"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md12 sm6 xs12>
        <v-layout row wrap v-if="images.length > 0">
          <v-flex v-for="(image, index) in images" :key="index" md4 sm3 xs2>
            <v-img
              v-if="image && images"
              contain
              position="center center"
              :src="appendBaseUrl(path + image)"
              @click="setImage(index)"
              style="cursor: pointer"
              aspect-ratio="1.333"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "ImageShow",
  data() {
    return {
      image: "",
    };
  },
  props: {
    images: "",
    imageP: "",
    path: "",
  },
  mounted() {
    this.image = this.imageP;
  },
  components: {},
  computed: {
    assignedImage: {
      get: function() {
        return this.image;
      },
      set: function(value) {
        this.image = this.images[value];
      }
    },
  },
  methods: {
    ...HelperFunctions,
    setImage(index) {
      this.image = this.images[index];
    },
  }
};
</script>
