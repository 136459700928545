const content = {
  impressum: {
    info:
      "<b>Linde Material Handling Rhein-Ruhr GmbH & Co. KG</b><br />" +
      "Hauptverwaltung<br />" +
      "Rotthauser Straße 48<br />" +
      "45309 Essen<br />" +
      "Telefon +49 201 8917 0<br />" +
      "Telefax +49 201 8917 129<br /><br />" +
      "E-Mail: <a href='mailto:info@lmh-rr.de' title='mailto:info@lmh-rr.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0 0;'>info@lmh-rr.de</span></a><br />" +
      "<a href='www.lmh-rr.de' title='www.lmh-rr.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0 0;'>www.lmh-rr.de</span></a><br /><br/>" +
      "Registergericht: Essen<br />" +
      "Registernummer: HRA 6508<br />" +
      "Persönlich haftende Gesellschafterin: Linde Material Handling Rhein-Ruhr Verwaltungs-GmbH<br />" +
      "Registergericht: Essen<br />" +
      "Registernummer: HRB 31801<br />" +
      "Vertretungsberechtigter Geschäftsführer: Matthias Vorbeck<br />" +
      "Inhaltlich Verantwortlicher gemäß § 55 Absatz 2 RStV: Matthias Vorbeck<br />" +
      "Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG: DE 178632506<br /> " +
        "Steuernummer: 111/5770/0813<br /><br />" +
      "<h4>Haftungsausschluss</h4><br/>" +
      "<p>Wir bemühen uns, Ihnen genaue und aktuelle Informationen zur Verfügung zu stellen. Trotz größter Sorgfalt können wir keine Gewähr für die Richtigkeit und Vollständigkeit der Inhalte dieser Webseiten übernehmen. Die Haftung für jegliche Art von Schäden, einschließlich Folgeschäden, die sich aus dem Gebrauch der auf diesen Webseiten veröffentlichten Informationen ergeben, ist ausgeschlossen.</p>" +
      "<p>Wir übernehmen keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>" +
      "<h4>Copyright</h4><br/>" +
      "<p>Die Abbildungen, Marken und Texte auf diesen Webseiten sind urheberrechtlich sowie durch andere gewerbliche Schutzrechte geschützt. Die Vervielfältigung zum Verkauf oder für eine andere kommerzielle Nutzung ist nicht gestattet. Für den privaten, persönlichen Gebrauch sowie für andere, nicht kommerzielle Zwecke ist die vollständige oder auszugsweise Vervielfältigung dieser Webseiten erlaubt, sofern keine inhaltlichen Änderungen vorgenommen werden.</p>",
    title: "Impressum"
  },
  agb: {
    info: "loaded from AGB.HTML file",
    title: "AGB"
  },
  datenschutz: {
    info:
      "<h1>Datenschutzerklärung</h1>          \n" +
      "<p>Die Linde Material Handling Rhein-Ruhr GmbH & Co. KG freut sich über Ihren Besuch auf dieser Webseite. Im Folgenden informieren wir Sie über Art, Umfang und Zwecke der Erhebung sowie Verarbeitung und Nutzung Ihrer personenbezogenen Daten. Ihre personenbezogenen Daten werden von uns ausschließlich im Rahmen der gesetzlichen Bestimmungen verarbeitet.</p>" +
      "<h4>Name und Kontaktdaten des Verantwortlichen</h4><br/>" +
      "<p>Linde Material Handling Rhein-Ruhr GmbH & Co. KG <br/> Rotthauser Straße 48<br/> 45309 Essen <br/> Vertreten durch den Geschäftsführer: Matthias Vorbeck<br/> Telefon: +49 201 8917 0<br/> Telefax: +49 201 8917 129<br/> E-Mail: <a href='mailto:info@lmh-rr.de' title='mailto:info@lmh-rr.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0% 0%;'>info@lmh-rr.de</span></a> <br/>  <a href='www.lmh-rr.de' title='www.lmh-rr.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0% 0%;'>www.lmh-rr.de</span></a> </p>" +
      "<h4>Name und Kontaktdaten des Datenschutzbeauftragten</h4><br/>" +
      "<p>KION GROUP AG <br/> Norbert Moeren<br/> Thea-Rasche-Straße 8 <br/> 60549 Frankfurt am Main<br/> E-Mail: <a href='mailto:dataprotection@kiongroup.com' title='mailto:dataprotection@kiongroup.com'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0% 0%;'>dataprotection@kiongroup.com</span></a></p>" +
      "<h4>Zwecke und Rechtsgrundlagen der Verarbeitung personenbezogener Daten</h4><br/>" +
      "<p>Jeder Zugriff auf unsere Webseite und jeder Abruf einer auf der Webseite hinterlegten Datei werden protokolliert. Die Speicherung dient internen systembezogenen und statistischen Zwecken. Protokolliert werden: der Name der abgerufenen Datei, Datum und Uhrzeit des Abrufs, die Dauer Ihres Besuchs, die übertragene Datenmenge sowie die Meldung über erfolgreichen Abruf, Webbrowser und anfragende Domain. Zusätzlich werden die IP-Adressen der anfragenden Rechner protokolliert und nach 30 Tagen gelöscht.</p>" +
      "<p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Diese Daten werden ausschließlich für die Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, sofern Sie dies wünschen und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>" +
      "<p>Weitergehende personenbezogene Daten werden nur dann erfasst, wenn Sie diese Angaben freiwillig machen, etwa im Rahmen einer Anfrage oder der Registrierung. Folgende personenbezogene Daten werden in diesem Fall von uns verarbeitet:</p>" +
      "<ul><li>Anrede</li> <li>Vorname und Nachname des Ansprechpartners</li><li>Firmendaten (falls es sich um einen B2B-Kunden handelt)</li><li>Adresse (Straße, Hausnummer, PLZ, Ort, Land)</li> <li>Kontaktinformationen (Telefonnummer, E-Mail-Adresse)</li></ul><br/>" +
      "<p>Diese weitergehenden personenbezogenen Daten werden von uns gemäß den geltenden datenschutzrechtlichen Bestimmungen streng vertraulich behandelt. Wir verwenden diese Daten zur Beantwortung Ihrer Anfragen, zur Abwicklung mit Ihnen geschlossener Verträge, für die technische Administration sowie für die Zusendung von Informationen zu den Produkten per E-Mail oder Post.</p>" +
      "<p>Die zur Abwicklung des Verkaufsauftrages benötigten Daten werden zusätzlich in den ERP-Systemen der Linde Material Handling Rhein-Ruhr GmbH & Co. KG gespeichert. </p>" +
      "<p>Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten hängt davon ab, für welchen Zweck wir Ihre Daten erhalten bzw. verarbeiten: </p>" +
      "<p><ol class='no-list-dot' type='a'>" +
      "<li>Aufgrund einer gegebenen Einwilligungserklärung nach Art. 6 Abs. 1 lit. a DSGVO<br/><br/>" +
      "Sofern Sie uns gegenüber freiwillig in die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten eingewilligt haben, stellt dies die Rechtsgrundlage für die Verarbeitung der Daten dar.<br/><br/>" +
      "Sie haben das Recht, diese Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.<br/><br/></li>" +
      "<li>Zur Erfüllung eines Vertrages nach Art. 6 Abs. 1 lit. b DSGVO<br><br>" +
      "Ihre personenbezogenen Daten werden von uns für den Zweck der Anbahnung und Durchführung von Vertragsverhältnissen sowie deren Abwicklung verarbeitet. Dies umfasst z.B. die Angebots-, Lieferschein- und Rechnungserstellung, die Kontaktaufnahme mit Ihnen, das Vertragsmanagement, den Versand oder die Kundenbetreuung.<br/><br/></li>" +
      "<li>Zur Erfüllung einer rechtlichen Verpflichtung nach Art. 6 Abs. 1 lit. c DSGVO<br/><br/>" +
      "Wir unterliegen rechtlichen Verpflichtungen. Um diesen nachzukommen, kann die Verarbeitung Ihrer personenbezogenen Daten hierfür erforderlich sein. Dazu zählen bspw. gesetzliche Aufbewahrungspflichten oder die Erfüllung der Steuerpflicht.<br/><br/></li>" +
      "<li>Für die Wahrung berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGV<br/><br/>" +
      "Berechtigte Interessen, die z.B. von uns verfolgt werden, sind:<br/><br/>" +
      "<ul class='original-list-dot'>" +
      "<li>Zusendung von Informationen zu Produkten per E-Mail oder Post, bei denen ein Interesse des Empfängers angenommen wird</li>" +
      "<li>Ermittlung und Verbesserung der Kundenzufriedenheit</li>" +
      "<li>Überwachung der Qualität der Produkte und Ermittlung von Verbesserungspotential</li>" +
      "<li>Durchführung und Pflege der Geschäftsbeziehung</li>" +
      "<li>Bereitstellung dieser Webseite zum Abruf sowie Auswertung zur Optimierung der Webseite</li></ul></li>" +
      "</ol> </p><br/><br/>" +
      "<h4>Empfänger oder Kategorien von Empfänger der personenbezogenen Daten</h4><br/>" +
      "<p>Innerhalb der verantwortlichen Stelle erhalten nur diejenigen Personen Zugriff auf Ihre personenbezogenen Daten, die diese zur Erfüllung der oben genannten Zwecke benötigen.</p>" +
      "<p>Die Übermittlung Ihrer personenbezogenen Daten erfolgt auch an von uns eingesetzte Dienstleister, sofern dies für die vorgegebenen Verwendungszwecke – insbesondere die Weitergabe von Bestelldaten an Lieferanten – erforderlich ist. Von diesen fordern wir zwingend rechtliche, technische sowie organisatorische Maßnahmen zur Einhaltung datenschutzrechtlicher Vorschriften ein. Rechtsgrundlage ist in diesem Fall Art. 28 DSGVO in Verbindung mit Auftragsverarbeitungsverträgen.</p>" +
      "<p>Eine darüberhinausgehende Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur mit Ihrer Zustimmung, es sei denn, dass eine gesetzliche Verpflichtung hierzu besteht oder zum Schutz der Rechte, des Eigentums und der Sicherheit der Linde Material Handling Rhein-Ruhr GmbH & Co. KG.\n</p>" +
      "<h4>Empfänger in Drittstaaten</h4><br/>" +
      "<p>Es kann eine Übermittlung Ihrer personenbezogenen Daten in Drittstaaten erfolgen, sofern die oben genannten Empfänger dort ihren Sitz haben. Die Absicherung der Datenübermittlung erfolgt auf Basis der EU-Standardverträge.</p>" +
      "<h4>Speicherdauer personenbezogener Daten oder Kriterien für die Festlegung der Dauer</h4><br/>" +
      "<p>Ihre personenbezogenen Daten werden von uns nur solange gespeichert, wie dies für die vorgesehenen Verwendungszwecke erforderlich ist und/oder Sie dem nicht widersprochen haben. Im Anschluss daran werden diese Daten gelöscht, sofern es keine anderen gesetzlichen Pflichten zur weiteren Aufbewahrung der Daten gibt.</p><br>" +
      "<h4>Hinweise auf die Rechte der Betroffenen</h4><br/>" +
      "<p>Nach den gesetzlichen Bestimmungen der DSGVO stehen Ihnen aufgrund der Verarbeitung Ihrer personenbezogenen Daten die nachfolgenden Rechte zu:<br/>" +
      "<ol class='no-list-dot' type='a'>" +
      "<li>Recht auf Auskunft nach Art. 15 DSGVO <br><br/> Sie können von uns eine Bestätigung darüber verlangen, ob wir betreffende personenbezogene Daten über Sie verarbeiten. Ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.</li><br/>" +
      "<li>Recht auf Berichtigung nach Art. 16 DSGVO <br/><br/> Sofern die sich auf Sie beziehenden personenbezogenen Daten unrichtig und/oder unvollständig sind, haben Sie das Recht, von uns unverzüglich die Berichtigung dieser betreffenden Daten zu verlangen.</li><br/>" +
      "<li> Recht auf Löschung nach Art. 17 DSGVO <br/><br/> Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft und wir als Unternehmen auch keine gesetzlichen Aufbewahrungspflichten einhalten müssen. </li><br/>" +
      "<li>Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO <br/><br/> Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist.</li><br/>" +
      "<li> Recht auf Datenübertragbarkeit nach Art. 20 DSGVO <br/><br/> Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und/oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.</li><br/>" +
      "<li>Recht auf Widerspruch gegen die Verarbeitung nach Art. 21 DSGVO <br/><br/> Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die weitere Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten Ihre Person betreffend überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. </li><br/>" +
      "<li>Recht auf Beschwerde bei einer Aufsichtsbehörde nach Art. 77 DSGVO <br/><br/> Sie haben das Recht, sich bei einer Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO verstößt. Sie können dieses Recht bei einer Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend machen.<br/>" +
      "<br/>Die zuständige Aufsichtsbehörde in Nordrhein-Westfalen ist:<br/><br/" +
      "Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br/>" +
      "Postfach 20 04 44<br/>" +
      "40102 Düsseldorf<br/>" +
      "Telefon: +49 211 384 24 0<br/>" +
      "Telefax: +49 211 384 24 10<br/>" +
      "E-Mail: <a href='mailto:poststelle@ldi.nrw.de' title='mailto:poststelle@ldi.nrw.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0% 0%;'>poststelle@ldi.nrw.de</span></a><br/>" +
      "<a href='www.ldi.nrw.de' title='www.ldi.nrw.de'><span style='line-height: 107%; color: rgb(170, 0, 48); background: white none repeat scroll 0% 0%;'>www.ldi.nrw.de</span></a><br/><br/>" +
      "Bei weiteren Fragen zur Datenverarbeitung oder zum Datenschutz steht Ihnen unser Datenschutzbeauftragter unter den oben angegebenen Kontaktdaten zur Verfügung." +
      "</li>" +
      "</ol></p>" +
      "<h4>Cookies</h4><br>" +
      "<p>Auf dieser Webseite nutzen wir weder Cookies noch sonstige einwilligungspflichtige Skripte.</p>" +
      "<h4>Sicherheitshinweis</h4><br/" +
      "<p>Wir sind bemüht, Ihre personenbezogenen Daten durch Ergreifung aller technischen und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, so dass wir Ihnen bei vertraulichen Informationen den Postweg empfehlen.</p>",
    title: "Datenschutz"
  }
};

export default content;
