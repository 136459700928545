import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
    theme: {
        primary: '#b80c1e',
        secondary: '#636363',
        accent: '#3F51B5'
    }
})
