<template>
    <div class="equipment-technical-details">
        <div class="subheading my-3">Technische Daten</div>
        <DescriptionValueDisplay v-if="equipment.propulsion" label="Antriebsart"
                                 :value="equipment.propulsion" />

        <DescriptionValueDisplay v-if="equipment.constructionType" label="Bauart"
                                 :value="equipment.constructionType" />

        <DescriptionValueDisplay v-if="equipment.loadCapacity" label="Tragkraft"
                                 :value="equipment.loadCapacity" unit="kg" />

        <DescriptionValueDisplay v-if="equipment.mastType" label="Hubmast"
                                 :value="equipment.mastType" />

        <DescriptionValueDisplay v-if="equipment.mastType && equipment.initialHub" label="Initialhub"
                                 :value="equipment.initialHub ? 'Ja' : 'Nein'" />

        <DescriptionValueDisplay v-if="equipment.liftingHeight" label="Hubhöhe"
                                 :value="equipment.liftingHeight" unit="mm" />

        <DescriptionValueDisplay v-if="equipment.height" label="Bauhöhe" :value="equipment.height"
                                 unit="mm" />

        <DescriptionValueDisplay v-if="equipment.freeLift" label="Freihub" :value="equipment.freeLift"
                                 unit="mm" />

        <DescriptionValueDisplay v-if="equipment.operatingHours" label="Betriebsstunden"
                                 :value="equipment.operatingHours" unit="h" />
      <DescriptionValueDisplay v-if="!equipment.operatingHours" label="Betriebsstunden"
                               value="Auf Anfrage"  />

        <DescriptionValueDisplay v-if="equipment.additionalHydraulic"
                                 label="Zusatzhydraulik"
                                 :value="equipment.additionalHydraulic" />

        <DescriptionValueDisplay v-if="equipment.driversModule"
                                 label="Fahrerschutzdach"
                                 :value="equipment.driversModule"/>

        <DescriptionValueDisplay v-if="equipment.driversSeat"
                                 label="Fahrersitz"
                                 :value="equipment.driversSeat" />

        <DescriptionValueDisplay v-if="equipment.driversPositionEquipment"
                                 label="Fahrerplatz- & Elektrische Ausrüstung"
                                 :value="equipment.driversPositionEquipment" />

        <DescriptionValueDisplay v-if="equipment.operationAndPedal"
                                 label="Bedienung & Pedalerie"
                                 :value="equipment.operationAndPedal" />

        <DescriptionValueDisplay v-if="equipment.light"
                                 label="Beleuchtung"
                                 :value="equipment.light" />

        <DescriptionValueDisplay v-if="equipment.safety"
                                 label="Sicherheit"
                                 :value="removeDuplicatesInArray(equipment.safety)" />

        <DescriptionValueDisplay v-if="equipment.tires"
                                 label="Bereifung"
                                 :value="equipment.tires" />

        <DescriptionValueDisplay v-if="equipment.surrounding"
                                 label="Umgebung"
                                 :value="equipment.surrounding" />

        <DescriptionValueDisplay v-if="equipment.energyPack"
                                 label="Energiepaket"
                                 :value="equipment.energyPack" />

        <DescriptionValueDisplay v-if="equipment.attachmentUnit"
                                 label="Anbaugerät"
                                 :value="equipment.attachmentUnit" />

      <DescriptionValueDisplay v-if="equipment.forkCarrier"
                               label="Gabelträger / Gabelausführung "
                               :value="equipment.forkCarrier" />

      <DescriptionValueDisplay v-if="equipment.forkOutsideDistance"
                               label="Gabelaußenabstand"
                               :value="equipment.forkOutsideDistance" unit="mm" />

      <DescriptionValueDisplay v-if="equipment.forkCarriageLength"
                               label="Gabelträgerlänge"
                               :value="equipment.forkCarriageLength" unit="mm" />

      <DescriptionValueDisplay v-if="equipment.forkCarriageThickness"
                               label="Gabelträgerdicke"
                               :value="equipment.forkCarriageThickness" unit="mm" />

      <DescriptionValueDisplay v-if="equipment.speedControl"
                               label="Geschwindigkeitsregelung"
                               :value="equipment.speedControl" />

      <DescriptionValueDisplay v-if="equipment.connectAccessControl"
                               label="Connect / Zugangskontrolle"
                               :value="equipment.connectAccessControl" />

      <DescriptionValueDisplay v-if="equipment.colors"
                               label="Farben"
                               :value="equipment.colors" />

      <DescriptionValueDisplay v-if="equipment.chassis"
                               label="Chassis"
                               :value="equipment.chassis" />

      <DescriptionValueDisplay v-if="equipment.battery" label="Batterie"
                                 :value="equipment.battery" />

        <DescriptionValueDisplay v-if="equipment.charger" label="Ladegerät"
                                 :value="equipment.charger" />

        <DescriptionValueDisplay v-if="equipment.attachments" label="Zubehör"
                                 :value="equipment.attachments" />

        <DescriptionValueDisplay v-if="equipment.other" label="Sonstiges"
                                 :value="equipment.other" />

      <DescriptionValueDisplay v-if="equipment.guarantee" label="Gewährleistung"
                               :value="equipment.guarantee" />

    </div>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay.vue";
import DividerWithLabel from "@/components/DividerWithLabel.vue";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "EquipmentTechnicalDetails",
  data() {
    return {
      lightsModified: "",
    };
  },
  props: {
    equipment: false
  },
  methods: {
    ...HelperFunctions,
  },
  components: {
    DescriptionValueDisplay,
    DividerWithLabel
  },
  mounted() {}
};
</script>
