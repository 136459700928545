<template>
  <div id="newest-equipment-slider" class="newest-equipment-slider">
    <template>
        <v-expand-transition v-for="(activeElement, index) in newestEquipments" :key="activeElement.equipmentId">
          <v-card v-show="indexAE == index" class="slide-item" style="max-width: 1400px; margin-left: auto; margin-right: auto;" transition="slide-x-transition" >
            <v-layout row wrap >
              <v-flex md2 xs12 sm4 class="">
                <v-img
                    position="center center"
                    contain
                    v-if="activeElement.mainImage"
                    :src="appendBaseUrl(activeElement.mainImage)"
                    aspect-ratio="1.333"
                ></v-img>
                <v-img
                    class="white--text"
                    v-else
                    :src="require('@/assets/placeholder-image.jpg')"
                    :aspect-ratio="1.333"
                >
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center ma-0>
                      <v-progress-circular
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                    </v-layout>
                  </template>
                </v-img>
              </v-flex>
              <v-flex md7>
                <v-layout class="px-3 pb-0" wrap row justify-space-between>
                  <v-flex class="pt-3 pb-0" md10 xs9 sm9>
                  <span class="body-1 linde-font"
                  >{{ activeElement.constructionType }}
                    {{
                      activeElement.additionalConstructionType
                          ? "/ " + activeElement.additionalConstructionType.replace("/", "")
                          : ""
                    }}</span
                  >
                  </v-flex>
                </v-layout>
                <v-layout class="px-3 mt-0 pb-0 mb-3" wrap row justify-space-between>
                  <v-flex class="subheading" md9 xs9 sm8>
                  <span class="linde-font linde-color linde-bold">
                    {{ activeElement.type }}
                  </span>
                  </v-flex>
                </v-layout>
                <v-layout class="px-3 pb-0 mt-2" wrap row justify-space-between>
                  <v-flex class="subheading">
                    <p class="linde-font linde-color linde-bold py-0 my-0">
                      {{ formatNumbers(activeElement.loadCapacity) ? formatNumbers(activeElement.loadCapacity) + ' kg' : '-' }}
                    </p>
                    <p class="caption">Tragkraft</p>

                  </v-flex>
                  <v-flex class="subheading">
                    <p class="linde-font linde-color linde-bold my-0 py-0">
                      {{ formatNumbers(activeElement.height) ? formatNumbers(activeElement.liftingHeight) + ' mm' : '-'}}
                    </p>
                    <p class="caption">Hubhöhe</p>

                  </v-flex>
                  <v-flex class="subheading">
                    <p class="linde-font linde-color linde-bold py-0 my-0">
                      {{ activeElement.mastType && activeElement.mastType !== "" ? activeElement.mastType : '-' }}
                    </p>
                    <p class="caption">Masttyp</p>

                  </v-flex>
                  <v-flex class="subheading">
                    <p class="linde-font linde-color linde-bold py-0 my-0">
                      {{ formatNumbers(activeElement.operatingHours) ? formatNumbers(activeElement.operatingHours) + ' h' : '-' }}
                    </p>
                    <p class="caption">Betriebsstunden</p>

                  </v-flex>
                </v-layout>
                <v-layout wrap row justify-space-between>
                  <v-flex class="text-md-center">
                    <div class="dot-navigation-container mt-2 mr-1 ml-1" v-for="i in [0,1,2,3,4,5,6,7,8,9]" :key="i">
                      <div :class="indexAE == i ? 'dot-filled': 'dot'" @click="setActiveElement(i)"></div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md3>
                <v-layout class="px-3 pb-1 mb-2 mt-1 pt-0" wrap row justify-space-between>
                  <v-flex class="pt-4 text-md-center" md10 xs9 sm9>
                  <span class="subheading linde-font linde-color linde-bold"
                  >€ {{ formatNumbers(activeElement.price) }}
                          <span
                              class="body-1" style="color: #636363; font-family: linde;">*</span>
                  </span>
                  </v-flex>
                </v-layout>
                <v-layout class="px-3 pb-0" wrap row justify-space-between>
                  <v-flex class="pt-1 text-md-center" md10 xs9 sm9>
                    <v-btn  color="primary"
                            style="text-transform:capitalize; margin-left: 8px; margin-bottom: 8px;" @click="onSelectEquipment(activeElement)">Weitere Details</v-btn>
                  </v-flex>
                </v-layout>
                <v-layout class="px-3 pb-1 pt-0" wrap row justify-space-between>
                  <v-flex class="pt-2 text-md-center" md10 xs9 sm9>
            <span class="caption linde-font">
              Linde Material Handling Rhein-Ruhr GmbH & Co. KG Deutschland
                  </span>
                  </v-flex>
                </v-layout>


              </v-flex>
            </v-layout>
          </v-card>
        </v-expand-transition>
    </template>
  </div>
</template>

<script>
import HelperFunctions from "@/utils/helper-functions";
import EquipmentServices from "@/services/equipment.services";
import {EventBus} from "@/event-bus";

export default {
  name: "NewestEquipmentSlider",
  data() {
    return {
      deleteDialog: false,
      deleteAllDialog: false,
      restoreDialog: false,
      selectedEquipment: null,
      newestEquipments: [],
      activeElement: {},
      indexAE: 0,
      intervalId: "",
    };
  },
  mounted() {
    EquipmentServices.getNewestEquipments()
        .then(response => {
          this.newestEquipments = response.data
          this.activeElement = this.newestEquipments[0]
          this.startSlide()
        })
  },
  methods: {
    ...HelperFunctions,
    startSlide() {
      this.intervalId = setInterval(() => {
        if (this.indexAE === this.newestEquipments.length - 1) this.indexAE = 0
        else this.indexAE += 1

        this.activeElement = this.newestEquipments[this.indexAE]
      }, 6000)
    },
    setActiveElement(i) {
      this.activeElement = this.newestEquipments[i]
      this.indexAE = i
    },
    onSelectEquipment(equipment) {
      equipment.image = "";
      equipment.imagesReal = [];
      EquipmentServices.getEquipmentByIdForUnauthorized(equipment.equipmentId)
          .then(response => {
            this.$store.commit("setSelectedEquipment", response.data);
            this.$router.push({name: "shareDetail", params: { id: equipment.equipmentId}});
            EventBus.$emit("ReloadEquipment")
          })
    },
    stopSlide() {
      clearInterval(this.intervalId);
    }
  }
};
</script>

<style>
.v-responsive__sizer {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.dot-navigation-container {
  height: 15px;
  width: 15px;
  display: inline-block;
}

.dot {
  height: 10px;
  width: 10px;
  border:1px solid #b80c1e;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  display: inline-block;
  cursor: pointer;
}

.dot-filled {
  height: 10px;
  width: 10px;
  background-color: #b80c1e;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  display: inline-block;
}

</style>
