import Api from "./api";

const FOUR_WEEKS = 60 * 60 * 24 * 7 * 4;
// const ONE_MINUTE = 60 * 60 * 24;

const login = user => {
  return Api().post("Sellers/login?include=user", {
    email: user.email,
    password: user.password,
    ttl: FOUR_WEEKS
  });
};

const logout = accessToken => {
  return Api().post("Sellers/logout?access_token=" + accessToken);
};

export default {
  login,
  logout
};
