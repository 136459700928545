<template>
    <div class="content-box">
        <v-card height="100%">
            <v-img
                    v-if="imageUrl"
                    :src="require('@/assets/'+ imageUrl)"
                    aspect-ratio="6.75"
            ></v-img>
            <v-card-title v-if="!toolbar">
                <v-icon v-if="icon !== ''">{{icon}}</v-icon>
                <span class="title" style="font-family: linde; color: #AA0020;">{{title}}</span>
            </v-card-title>
            <v-toolbar v-if="toolbar" dark
                       color="primary px-3">
                <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>{{text}}</p>
                <span class="title" style="font-family: linde; color: #AA0020;">{{listHeader}}</span>
                <ul class="pt-0 mt-0">
                    <li v-for="(item, index) in content" :key="index">{{item}}</li>
                </ul>
                <span class="title" style="font-family: linde; color: #AA0020;">{{listHeader2}}</span>
                <ul class="pt-0 mt-0">
                    <li v-for="(item, index) in content2" :key="index">{{item}}</li>
                </ul>
                <slot></slot>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "ContentBox",
        props: {
            imageUrl: false,
            title: "",
            icon: "",
            text: "",
            listHeader: "",
            content: "",
            listHeader2: "",
            content2: "",
            toolbar: false
        }
    };
</script>

<style>
    ul {
        list-style: none;
        padding-left: 14px;
    }

    li::before {
        content: "•";
        color: #aa0020;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
</style>
