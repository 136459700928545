<template>
  <v-layout class="compare-table" align-start justify-start row>
    <v-flex md12>
      <v-card class="pb-4 min-height-400">
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
           <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
             <b>Vergleichen</b></span>
        </v-card-title>
        <div v-if="compares.length > 0">
          <div class="px-3" v-for="prop of properties" :key="prop.prop">
            <v-layout class="pt-1 px-1">
              <v-flex md12>
                <v-layout row wrap>
                  <!-- Eigenschaft -->
                  <v-flex md2>
                    {{ prop.name }}
                  </v-flex>
                  <v-flex md3 style="text-align: center" v-for="(e, index) in compares"
                          :key="'-compare-table-' + index">
                    <div v-if="prop.prop === 'image'" class="hidden-md-and-down">
                      <v-img
                          v-if="compares[index][prop.prop] && compares[index][prop.prop].image"
                          class="white--text"
                          :src="appendBaseUrl(compares[index][prop.prop].image)"
                          :aspect-ratio="1.333"

                      >
                        <template v-slot:placeholder>
                          <v-layout fill-height align-center justify-center ma-0>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                          </v-layout>
                        </template>
                      </v-img>
                      <v-img
                          v-else
                          class="white--text"
                          :src="require('@/assets/placeholder-image.jpg')"
                          :aspect-ratio="1.333"
                      >
                        <template v-slot:placeholder>
                          <v-layout fill-height align-center justify-center ma-0>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                          </v-layout>
                        </template>
                      </v-img>
                    </div>
                    <span v-if="prop.prop !== 'image'">
                  {{ compares[index][prop.prop] }} {{ prop.unit ? prop.unit : "" }}
                    </span>
                    <v-btn v-if="prop.prop === 'button'" small color="primary"
                           @click="onShowEquipment(compares[index].equipmentId)">Zum Stapler
                    </v-btn>
                    <v-btn v-if="prop.prop === 'button'" small color="primary" flat icon
                           @click="onRemoveCompareElement(compares[index])">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>

            </v-layout>
            <v-divider></v-divider>
          </div>
        </div>
        <div class="text--center" v-else>
          <v-card-text >
            Noch keine Fahrzeuge für den Vergleich ausgewählt.
          </v-card-text>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import {mapState} from "vuex";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "CompareList",
  data() {
    return {
      images: [],
      properties: [
        {
          name: "",
          prop: "image"
        },
        {
          name: "Bezeichnung",
          prop: "type"
        },
        {
          name: "Preis", prop: "price", unit: "€"
        },
        {name: "Baujahr", prop: "constructionYear"},
        {name: "Hubhöhe", prop: "liftingHeight", unit: "mm"},
        {name: "Traglast", prop: "loadCapacity", unit: "kg"},
        {name: "Betriebsstunden", prop: "operatingHours", unit: "h"},
        {name: "Bauhöhe", prop: "height", unit: "mm"},
        {name: "Hydraulik", prop: "additionalHydraulic"},
        {name: "Mast", prop: "mastType"},
        {name: "Anbaugeräte", prop: "attachmentUnit"},
        {name: "Seriennr.", prop: "serialNumber"},
        {name: "Anb. Prod.-Nr.", prop: "AProdID"},
        {name: "", prop: "button"},
      ],
      headers: [],
      compareElements: [],
    };
  },
  components: {
    DescriptionValueDisplay
  },
  mounted() {
    this.images = [];
    this.clearFavoritesAndComparesFromDeletedItems({ favorites: [], compares: this.compares })
  },
  computed: {
    ...mapState(["compares"])
  },
  methods: {
    ...HelperFunctions,
    onShowEquipment(equipmentId) {
      this.$router.push({name: "shareDetail", params: {id: equipmentId}});
    },
    onRemoveCompareElement(equipment) {
      this.$store.commit("updateCompares", equipment);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.min-height-400 {
  min-height: 400px;
}
</style>
