<template>
  <div class="description-value-display">
    <v-layout wrap row class="my-0 py-0">
      <v-flex v-if="icon">
        <v-icon small>{{ icon }}</v-icon> {{ value }}
      </v-flex>
      <v-flex v-if="!icon" md5 sm7 xs5 class="text-md-left text-sm-left py-0">
        <span v-if="label !== ''" :class="fontSize">{{ label }}</span>
      </v-flex>
      <v-flex
        v-if="!icon"
        md7
        sm5
        xs7
        :class="fontSize"
        class="text-md-left text-sm-left text-xs-left py-0"
      >
            <span v-html="pasteSpaceBeforeComma(value)"></span>
            <span v-if="unit !== ''">{{" "}} {{ unit }}</span>
       </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "DescriptionValueDisplay",
  props: {
    label: "",
    value: "",
    unit: "",
    fontSize: "",
    icon: false,
    link: false
  },
  mounted() {},
  methods: {
    pasteSpaceBeforeComma(val) {
      if (val) {
        if (typeof val === "string") return val.replace(/,/g, ", ");
        if (typeof val === "object") {
          return val.join(", ");
        }
      }
      return val;
    }
  }
};
</script>

<style>
.description-value-display {
  color: #636363;
  font-family: linde;
}
</style>
