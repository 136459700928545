import { render, staticRenderFns } from "./TradeIn.vue?vue&type=template&id=989a251e&scoped=true&"
import script from "./TradeIn.vue?vue&type=script&lang=js&"
export * from "./TradeIn.vue?vue&type=script&lang=js&"
import style0 from "./TradeIn.vue?vue&type=style&index=0&id=989a251e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989a251e",
  null
  
)

export default component.exports