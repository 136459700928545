<template>
    <v-layout v-resize="resizeDescription" wrap row>
        <v-flex md12 sm12 xs12>
            <label class="font-weight-bold" :style="{fontSize: descriptionFontSize + 'px'}">
                {{label}}
            </label>
        </v-flex>
        <v-flex md5 sm6 xs6 >
            <v-select class="my-0 pa-0" dense
                      :items="options"
                      placeholder="von"
                      hide-details
                      v-model="from"
                      @input="onChangeFrom"
            ></v-select>
        </v-flex>
        <v-flex offset-md1 md5 sm6 xs6>
            <v-select class="my-0 pa-0" dense
                      :items="options"
                      placeholder="bis"
                      hide-details
                      v-model="to"
                      @input="onChangeTo"
            ></v-select>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
  name: "SearchSelectField",
  data() {
    return {
      commitType:
        "set" +
        this.propertyName.charAt(0).toUpperCase() +
        this.propertyName.substr(1, this.propertyName.length - 1),
      descriptionFontSize: 14
    };
  },
  mounted() {
    this.from = this.$store.state.search[this.propertyName + "From"];
    this.to = this.$store.state.search[this.propertyName + "To"];
  },
  props: {
    options: null,
    propertyType: "",
    propertyName: "",
    action: "",
    label: ""
  },
  computed: {
    from: {
      get() {
        return this.$store.state.search[this.propertyName + "From"];
      },
      set(value) {
        this.$store.dispatch(this.commitType, { name: "from", value: value });
      }
    },
    to: {
      get() {
        return this.$store.state.search[this.propertyName + "To"];
      },
      set(value) {
        this.$store.dispatch(this.commitType, { name: "to", value: value });
      }
    }
  },
  methods: {
    onChangeFrom(e) {
        e = e === 'Beliebig' ? null : e;
      this.$store
        .dispatch(this.commitType, { name: "from", value: e })
        .then(this.invokeQueryConstruction());
    },
    onChangeTo(e) {
        e = e === 'Beliebig' ? null : e;
        this.$store
        .dispatch(this.commitType, { name: "to", value: e })
        .then(this.invokeQueryConstruction());
    },
    invokeQueryConstruction() {
      this.$store.dispatch("constructQuery", {
        type: this.propertyName,
        from: this.from,
        to: this.to
      });
      this.$store.dispatch("getEquipmentData");
    },
    resizeDescription() {
      if (window.innerWidth < 600) this.decsriptionFontSize = 12;
      else this.descriptionFontSize = 14;
    }
  }
};
</script>
