<template>
    <div>
        <v-img :src="require('@/assets/bg.jpg')" v-resize="resizeHeaderImage" :height="imageSize+ 'px'"
               position="center bottom" style="max-width: 1400px; margin-left: auto; margin-right: auto;">
            <div class="pa-2">
                <img id="approved" src="@/assets/approved_quality.png" width="300px" alt="gepruefte_qualitaet"/>
            </div>
        </v-img>
    </div>
</template>


<script>
export default {
  name: "HeaderImage",
  data() {
    return {
      imageSize: 350
    };
  },
  methods: {
    resizeHeaderImage() {
      if (window.innerWidth < 500 && window.innerWidth > 450)
        this.imageSize = 250;
      else if (window.innerWidth <= 450 && window.innerWidth >= 380)
        this.imageSize = 150;
      else if (window.innerWidth < 380) this.imageSize = 100;
      else this.imageSize = 350;
    }
  }
};
</script>

<style scoped>
.v-responsive__sizer {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
    #approved {
        background-color: white;
        padding-left: 10px;
        padding-right: 10px;
        position: absolute;
        right: 10px;
        bottom:10px;
    }
</style>
