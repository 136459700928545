<template>
    <div class="equipment-details-seller">
        <p class="subheading linde-font linde-color linde-bold mb-0 pb-0">Zusätzliche Informationen</p>
        <DescriptionValueDisplay v-if="equipment.deviceStatus" label="Zustand"
                                 :value="equipment.deviceStatus"></DescriptionValueDisplay>
        <DescriptionValueDisplay v-if="equipment.location" label="Standort"
                                 :value="equipment.location"></DescriptionValueDisplay>
        <DescriptionValueDisplay v-if="equipment.availability" label="Verfügbarkeit"
                                 :value="equipment.availability"></DescriptionValueDisplay>
    </div>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay.vue";

export default {
  name: "EquipmentDetailsSeller",
  props: {
    equipment: false
  },
  components: {
    DescriptionValueDisplay
  }
};
</script>
