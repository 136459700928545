<template>
  <v-dialog :value="dialog" max-width="600" persistent transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title class="title">
        Anfrage für {{ equipment.type }}
      </v-card-title>
      <v-card-text>
        <v-flex class="" md5>
          <v-select
              :items="statusItems"
              v-model="inquiryStatus"
              label="Status"
              @input="onChangeStatus"
          ></v-select>
        </v-flex>
        <div class="subheading">
          Wunsch des Kunden
        </div>
        <v-divider></v-divider>
        <div class="mt-2 mb-2">
          <DescriptionValueDisplay class="my-0 py-0" v-if="selectedInquiry.customerIntend"
                                   label="Kauf/Leasing/Mietkauf"
                                   :value="mapCustomerIntend(selectedInquiry.customerIntend)"
                                   font-size="body-1"></DescriptionValueDisplay>
        </div>
        <div class="subheading">
          Information zum Stapler
        </div>
        <v-divider></v-divider>
        <div class="mt-2 mb-2">
          <DescriptionValueDisplay class="my-0 py-0" v-if="equipment.type"
                                   label="Bauart"
                                   :value="equipment.constructionType"
                                   font-size="body-1"></DescriptionValueDisplay>

          <DescriptionValueDisplay class="my-0 py-0" v-if="equipment.serialNumber"
                                   label="Seriennummer"
                                   :value="equipment.serialNumber"
                                   font-size="body-1"></DescriptionValueDisplay>

          <DescriptionValueDisplay class="my-0 py-0" v-if="equipment.providerProductNumber"
                                   label="Anbieter Produkt-Nr."
                                   :value="equipment.providerProductNumber"
                                   font-size="body-1"></DescriptionValueDisplay>
        </div>


        <div class="subheading">
          Bevorzugter Kontaktweg
        </div>
        <v-divider></v-divider>
        <div class="mt-2">
          <v-icon small>{{ contactType }}</v-icon>
          {{ ' ' + selectedInquiry.receiveOfferAs }}
        </div>

        <div class="subheading mt-2">
          Kontakt
        </div>
        <v-divider></v-divider>
        <div class="mt-2">
          <v-icon small>person</v-icon>
          {{ ' ' + selectedInquiry.salutation + ' ' + selectedInquiry.name + ' ' + selectedInquiry.lastname }}
        </div>
        <div class="">
          <v-icon small>business</v-icon>
          {{ ' ' + selectedInquiry.companyOrganization }}
        </div>
        <div class="">
          <v-icon small>map</v-icon>
          {{ selectedInquiry.address }},
          {{ ' ' + selectedInquiry.zipCode + ' ' + selectedInquiry.place }},
          {{ ' ' + selectedInquiry.country }}
        </div>
        <div class="mt-2">
          <v-icon small>phone</v-icon>
          {{ ' ' + selectedInquiry.phone }}
        </div>
        <div class="">
          <v-icon small>mail</v-icon>
          {{ ' ' + selectedInquiry.email }}
        </div>

        <div v-if="selectedInquiry.remarks && selectedInquiry.remarks.length > 0" class="subheading mt-2">
          Anmerkungen
        </div>
        <v-divider v-if="selectedInquiry.remarks && selectedInquiry.remarks.length > 0"></v-divider>
        <div v-if="selectedInquiry.remarks && selectedInquiry.remarks.length > 0" class="mt-2">
          <v-icon small>message</v-icon>
          {{ ' ' + selectedInquiry.remarks }}
        </div>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mb-2 mr-2" color="primary" @click="setInquiryDialogStatus">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "../event-bus";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import EquipmentServices from '../services/equipment.services';
import InquiryServices from "@/services/inquiry.services";
import Constants from "@/utils/constants";
import HelperFunctions from "@/utils/helper-functions";
import {mapState} from "vuex";

export default {
  name: "InquiryInformation",
  data() {
    return {
      dialog: false,
      selectedInquiry: "",
      statusItems: Constants.statusItems,
      contactType: "",
      equipment: "",
      inquiryStatus: "",
    };
  },
  props: [],
  mounted() {
    EventBus.$on("openInquiryDialog", this.setInquiryDialogStatus);
    EventBus.$on("onSelectInquiry", inquiry => {
      this.setSelectedInquiry(inquiry);
      this.inquiryStatus = this.selectedInquiry.status;
    });
  },
  components: {
    DescriptionValueDisplay
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    setSelectedInquiry(inquiry) {
      this.selectedInquiry = inquiry;
      EquipmentServices.getEquipmentById(this.accessToken, this.selectedInquiry.equipmentId).then(
          response => {
            this.equipment = response.data;
          }
      );
      switch (this.selectedInquiry.receiveOfferAs.toLowerCase()) {
        case "email":
          this.contactType = "alternate_email";
          break;
        case "phone":
          this.contactType = "phone";
          break;
        case "fax":
          this.contactType = "print";
          break;
        default:
          break;
      }
    },
    setInquiryDialogStatus() {
      this.dialog = !this.dialog;
      EventBus.$emit("onCloseInquiryDialog", true);
    },
    onChangeStatus() {
      this.loading(true);
      InquiryServices.updateInquiryStatus(
          this.accessToken, this.selectedInquiry.id,
          {status: this.inquiryStatus}
      )
          .then(response => {
            this.loading(false);
            this.initiateSuccessInfo("Status der Anfrage erfolgreich geändert!")
            this.inquiries = response.data;
          })
          .catch(error => {
            this.initiateErrorInfo("Bitte versuchen Sie es erneut!")
            throw error;
          });
    }
  }
};
</script>
