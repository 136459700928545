<template>
  <div class="my-search-view">
    <v-layout class="pt-1" wrap row>
      <v-flex sm12 xs12 md12>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Meine Suche</v-toolbar-title>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout class="pt-1" wrap row>
      <v-flex
          class="text-md-left text-sm-left text-xs-left mt-0 py-0 mb-1"
          md12
          sm12
          xs12
      >
        <v-btn
            class="ma-0 pa-0 normalize-font"
            color="primary"
            to="/dashboard"
            flat
            small
        >
          <v-icon small>arrow_back</v-icon>
          zurück zum Dashboard
        </v-btn>
      </v-flex>
      <v-flex md12 sm12 xs12>
        <SavedSearchList></SavedSearchList>
      </v-flex>
    </v-layout>
  </div>

</template>


<script>



import SavedSearchList from "@/components/SavedSearchList.vue";

export default {
  name: "App",
  data() {
    return {
      user: "",
      searches: [],
    };
  },
  beforeCreate() {
  },
  components: {SavedSearchList},
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  },
  mounted() {
    this.user = this.$store.state.user;
    this.searches = this.$store.state.searches;
  }
};
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
