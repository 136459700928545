<template>
  <div class="search-form">
    <v-layout align-start justify-start row>
      <v-flex md12>
        <v-card>
          <v-card-title class="subheading slighten-2 ma-0 px-3 pt-2 pb-2">
            <slot></slot>
          </v-card-title>
          <v-layout
              v-resize="resizeAvatar"
              class="mx-0 mt-2 text-md-center text-sm-center text-xs-center d-flex"
          >
            <v-flex
                class="px-0 mx-0 hidden-xs-only"
                xs4
                sm2
                md2
                v-for="typeItem in typeItems"
                :key="typeItem.value"
            >
              <v-avatar
                  :class="[
                  'cursor',
                  'ma-0',
                  'pa-0',
                  types.includes(typeItem.value) ? 'isActive' : 'isInactive'
                ]"
                  tile="tile"
                  @click="onSelectType(typeItem.value)"
                  :size="avatarSize"
              >
                <v-img :src="require('@/assets/' + typeItem.path)" alt="avatar" cover/>
              </v-avatar>
              <p :style="{ fontSize: iconDescriptionSize + 'px' }">
                {{ typeItem.text }}
              </p>
            </v-flex>
          </v-layout>
          <v-card-text class="pa-0 hidden-sm-and-up">
            <v-layout>
              <v-flex>
                <v-select
                    :items="typeSelectables"
                    v-model="type"
                    class="px-3 my-1 pt-1"
                    dense
                    hide-details
                    @input="onSelectType(type)"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile-avatar v-if="data.item.path">
                      <img :src="require('@/assets/' + data.item.path)"/>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title
                          v-html="data.item.text"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-title class="subheading slighten-2 ma-0 px-3 pt-2 pb-0">
            <span
                class="font-weight-medium"
                style="color:#AA0020; font-family: linde;"
            ><b>Filter</b></span
            >
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container grid-list-md class="px-3 mt-0 pt-1" fluid>
              <!-- first line -->

              <v-layout wrap row justify-space-between>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <SearchSelectField
                      label="Tragfähigkeit"
                      :options="loadCapacities"
                      propertyName="loadCapacity"
                  />
                </v-flex>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <v-layout v-resize="resizeDescription" wrap row>
                    <v-flex md6 sm6 xs6>
                      <label
                          class="font-weight-bold"
                          :style="{ fontSize: descriptionFontSize + 'px' }"
                      >
                        Antrieb
                      </label>
                    </v-flex>
                    <v-flex offset-md1 md5 sm6 xs6>
                      <label
                          class="font-weight-bold"
                          :style="{ fontSize: descriptionFontSize + 'px' }"
                      >
                        Komponenten/Typ
                      </label>
                    </v-flex>
                    <v-flex md5 sm6 xs6>
                      <v-select
                          v-if="
                          search.types.includes('Gegengewichtstapler') ||
                            search.types.length === 0
                        "
                          class="my-0 pa-0"
                          dense
                          :items="propulsion"
                          hide-details
                          v-model="propulsionType"
                          @input="onSelectPropulsion"
                      ></v-select>
                      <v-select
                          v-else
                          class="my-0 pa-0"
                          dense
                          :items="['Elektro']"
                          hide-details
                          v-model="propulsionType"
                          @input="onSelectPropulsion"
                      ></v-select>
                    </v-flex>
                    <v-flex offset-md1 md5 sm6 xs6>
                      <v-select
                          class="my-0 pa-0"
                          dense
                          :items="componentTypes"
                          :disabled="
                          !search.types.includes('Komponente') ||
                            search.types.length === 0
                        "
                          hide-details
                          v-model="componentType"
                          @input="onSelectComponentType"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <!-- end first line -->

              <!-- second line -->

              <v-layout wrap row justify-space-between>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <v-layout v-resize="resizeDescription" wrap row>
                    <v-flex md12 sm12 xs12>
                      <label
                          class="font-weight-bold"
                          :style="{ fontSize: descriptionFontSize + 'px' }"
                      >
                        Masttyp
                      </label>
                    </v-flex>
                    <v-flex md5 sm6 xs6>
                      <v-select
                          class="my-0 pa-0"
                          dense
                          :items="mastTypes"
                          hide-details
                          v-model="mastType"
                          @input="onSelectMastType"
                      ></v-select>
                    </v-flex>
                    <v-flex class="mt-2" md6 xs12 sm6></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <SearchSelectField
                      label="Betriebsstunden"
                      :options="operatingHourSpan"
                      propertyName="operatingHours"
                  />
                </v-flex>
              </v-layout>

              <!-- end second line -->

              <!-- third line -->

              <v-layout wrap justify-space-between>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <SearchSelectField
                      label="Hubhöhe"
                      :options="liftingHeights"
                      propertyName="liftingHeight"
                  />
                </v-flex>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <SearchSelectField
                      label="Preis"
                      :options="prices"
                      propertyName="price"
                  />
                </v-flex>
              </v-layout>

              <!-- end third line -->

              <!-- fourth line -->
              <v-layout wrap justify-space-between>
                <v-flex class="mt-2" md6 xs12 sm6>
                  <SearchSelectField
                      label="Bauhöhe"
                      :options="mastHeights"
                      propertyName="height"
                  />
                </v-flex>
                <v-flex v-if="isHome" class="mt-2 pt-2" md6 xs12 sm6>
                  <v-layout wrap row>
                    <v-flex md5 sm6 xs12>
                      <v-layout>
                      <label class="font-weight-bold" :style="{fontSize: getDescriptionFontSize + 'px'}">
                        Anbieter-Produkt-Nr
                      </label>
                      </v-layout>
                      <v-layout class="mt-2" >
                        <v-text-field
                            v-model="searchedAnProdNr"
                            hide-details
                            dense
                            class="my-0 pa-0"
                            label="Anbieter-Produkt-Nr"
                            :disabled="searchedSerialNumber !== ''"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>
                    <v-flex v-if="isLoggedIn" offset-md1 md5 sm6 xs6>
                      <v-layout>
                      <label class="font-weight-bold" :style="{fontSize: getDescriptionFontSize + 'px'}">
                        Seriennummer
                      </label>
                      </v-layout>
                      <v-layout class="mt-2" >
                        <v-text-field
                            v-model="searchedSerialNumber"
                            hide-details
                            dense
                            class="my-0 pa-0"
                            label="Seriennummer"
                            :disabled="searchedAnProdNr !== ''"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- end fourth line -->
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-0">
            <div style="display: block">
              <div style="margin-left: 8px; margin-bottom: 8px;"
                   class="note" v-if="equipments.length === 0">
                <h4 style="text-transform: uppercase;" class="">Leider entspricht kein Angebot deinen
                  Suchkriterien.</h4>
                <p>Leg hier ein kostenloses Gabelstapler Gesuch an. </p>
              </div>

              <v-btn
                  color="primary"
                  style="text-transform:capitalize; margin-left: 8px; margin-bottom: 8px;"
                  @click="showResult"
                  v-if="isHome && equipments.length > 0 && searchedSerialNumber === '' && searchedAnProdNr === ''"
              >
                <v-icon>search</v-icon>
                {{ getActiveElements(equipments).length }} Fahrzeug<span
                  style="text-transform:lowercase;"
                  v-if="equipments.length !== 1"
              >e
              </span>
              </v-btn>
              <v-btn
                  color="primary"
                  style="text-transform:capitalize; margin-left: 8px; margin-bottom: 8px;"
                  @click="showResult"
                  v-if="isHome && getActiveElements(equipments).length > 0 && searchedSerialNumber !== ''"
              >
                <v-icon>search</v-icon>
                {{ getActiveElements(equipments.filter(eq => eq.serialNumber.replace(/\s/g,'') === searchedSerialNumber.replace(/\s/g,''))).length }} Fahrzeug<span
                  style="text-transform:lowercase;"
                  v-if="equipments.length !== 1"
              >e
              </span>
              </v-btn>
              <v-btn
                  color="primary"
                  style="text-transform:capitalize; margin-left: 8px; margin-bottom: 8px;"
                  @click="showResult"
                  v-if="isHome && getActiveElements(equipments).length > 0 && searchedAnProdNr !== ''"
                  :disabled="getActiveElements(equipments.filter(eq => eq.providerProductNumber.replace(/\s/g,'') === searchedAnProdNr.replace(/\s/g,''))).length === 0"
              >
                <v-icon>search</v-icon>
                {{  getActiveElements(equipments.filter(eq => eq.providerProductNumber.replace(/\s/g,'') === searchedAnProdNr.replace(/\s/g,''))).length }} Fahrzeug<span
                  style="text-transform:lowercase;"
                  v-if="equipments.length !== 1"
              >e
              </span>
              </v-btn>
              <v-btn
                  color="primary"
                  style="text-transform:capitalize; margin-left: 8px; margin-bottom: 8px;"
                  @click="onOpenSearchMissionForm"
                  v-if="isHome && equipments.length === 0"
              >Gesuch anlegen
              </v-btn>
              <v-btn
                  class=""
                  style="text-transform:capitalize; margin-bottom: 8px;"
                  color="primary"
                  flat
                  @click="resetFilter"
                  v-if="this.$route.path === '/'"
              >
                Filter zurücksetzen
              </v-btn>
              <v-btn
                  class="hidden-md-and-up"
                  style="text-transform:capitalize;"
                  small
                  flat
                  block
                  color="primary"
                  @click="resetFilter"
                  v-if="this.$route.path == '/result'"
              >
                zurücksetzen
              </v-btn>
            </div>
            <slot name="closeBottomSheetButton"></slot>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import SearchSelectField from "@/components/SearchSelectField";
import NoMatchNote from "@/components/NoMatchNote";
import CONSTANTS from "../utils/constants";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SearchForm",
  data: () => ({
    typeItems: [
      {
        text: "Gegengewichtstapler",
        value: "Gegengewichtstapler",
        path: "icons/UTS-Icon4_os.png"
      },
      {
        text: "Hochhubwagen",
        value: "Hochhubwagen",
        path: "icons/UTS-Icon1_os.png"
      },
      {
        text: "Niederhubwagen",
        value: "Niederhubwagen",
        path: "icons/UTS-Icon2_os.png"
      },
      {
        text: "Schubmaststapler",
        value: "Schubmaststapler",
        path: "icons/UTS-Icon3_os.png"
      },
      {
        text: "Kommissionierer",
        value: "Kommissionierer",
        path: "icons/UTS-Icon5_os.png"
      },
      {
        text: "Schlepper",
        value: "Schlepper",
        path: "icons/UTS-Icon6_os.png"
      },
      {
        text: "Komponente",
        value: "Komponente",
        path: "icons/Komponenten.jpg"
      }
    ],
    typeSelectables: [
      {
        text: "Beliebig",
        value: "Beliebig",
        path: ""
      },
      {
        text: "Gegengewichtstapler",
        value: "Gegengewichtstapler",
        path: "icons/UTS-Icon4_os.png"
      },
      {
        text: "Hochhubwagen",
        value: "Hochhubwagen",
        path: "icons/UTS-Icon1_os.png"
      },
      {
        text: "Niederhubwagen",
        value: "Niederhubwagen",
        path: "icons/UTS-Icon2_os.png"
      },
      {
        text: "Schubmaststapler",
        value: "Schubmaststapler",
        path: "icons/UTS-Icon3_os.png"
      },
      {
        text: "Kommissionierer",
        value: "Kommissionierer",
        path: "icons/UTS-Icon5_os.png"
      },
      {
        text: "Schlepper",
        value: "Schlepper",
        path: "icons/UTS-Icon6_os.png"
      },
      {
        text: "Komponente",
        value: "Komponente",
        path: "icons/Komponenten.jpg"
      }
    ],
    type: "Beliebig",
    propulsion: CONSTANTS.propulsion,
    propulsionAvailable: false,
    propulsionType: "Beliebig",
    liftingHeights: CONSTANTS.liftingHeights,
    operatingHourSpan: CONSTANTS.operatingHour,
    mastHeights: CONSTANTS.mastHeights,
    loadCapacities: CONSTANTS.loadCapacities,
    mastType: "Beliebig",
    mastTypes: CONSTANTS.mastTypes,
    prices: CONSTANTS.prices,
    componentType: "Beliebig",
    componentTypes: CONSTANTS.componentTypes,
    searchedSerialNumber: "",
    searchedAnProdNr: "",
    avatarSize: 126,
    iconDescriptionSize: 12,
    descriptionFontSize: 14
  }),
  mounted() {
    this.propulsionType =
        this.search.propulsionType && this.search.propulsionType.length > 0
            ? this.search.propulsionType[0]
            : "Beliebig";
    this.componentType =
        this.search.componentType && this.search.componentType.length > 0
            ? this.search.componentType[0]
            : "Beliebig";
    this.mastType =
        this.search.mastType.length > 0 ? this.search.mastType[0] : "Beliebig";
    this.type = this.search.types[0] ? this.search.types[0] : "Nothing";
    this.getConstructionYearsSpan();
    this.$store.commit("updateActualPage", 1)
  },
  components: {
    SearchSelectField,
    NoMatchNote
  },
  computed: {
    ...mapState({
      equipments: state =>
          state.equipments.filter(eq => eq.active == 0 || eq.active == null),
      constructionYearSpan: state => state.constructionYearSpan,
      types: state => state.search.types,
      search: state => state.search
    }),
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isHome: function () {
      return this.$route.path === '/'
          || this.$route.path === '/vorteile'
          || this.$route.path === '/geprueft'
          || this.$route.path === '/fstStandard'
          || this.$route.path === '/kontakt';
    }
  },
  methods: {
    ...HelperFunctions,
    ...mapMutations(["resetQuery"]),
    ...mapActions([
      "getEquipmentData",
      "getConstructionYearsSpan",
      "unsetSearchParameters",
      "setTypes",
      "setPropulsionType",
      "setComponentType",
      "setMastType"
    ]),
    showResult() {
      if (this.searchedSerialNumber === "" && this.searchedAnProdNr === "") {
        this.$router.push("result");
      } else if (this.searchedAnProdNr !== "") {
        let eq = this.equipments.filter(e => e.providerProductNumber.replace(/\s/g,'') === this.searchedAnProdNr.replace(/\s/g,''));
        if (eq.length == 1) {
          this.$router.push({name: "shareDetail", params: {id: eq[0].equipmentId}});
        }
      } else {
        let eq = this.equipments.filter(e => e.serialNumber.replace(/\s/g,'') === this.searchedSerialNumber.replace(/\s/g,''));
        if (eq.length == 1) {
          this.$router.push({name: "shareDetail", params: {id: eq[0].equipmentId}});
        }
      }
    },
    onOpenSearchMissionForm() {
      this.$router.push("search-inquiry")
    },
    resetFilter() {
      this.type = "Beliebig";
      this.propulsionType = "Beliebig";
      this.mastType = "Beliebig";
      this.unsetSearchParameters();
      this.resetQuery();
      this.getEquipmentData();
    },
    onSelectType(type) {
      let assignedType = type;
      if (!assignedType) assignedType = this.type;
      this.setTypes(type);
      this.getEquipmentData();
      this.propulsionAvailable = this.search.types.includes(
          "Gegengewichtstapler"
      );

      if (this.propulsionAvailable) {
        this.propulsionType = "Beliebig";
        this.setPropulsionType("Beliebig");
      } else if (!this.propulsionAvailable && this.search.types.length === 0) {
        this.propulsionType = "Beliebig";
        this.setPropulsionType("Beliebig");
        // this.setPropulsionType(this.propulsionType);
        this.onSelectPropulsion();
      } else if (!this.propulsionAvailable && this.types.length === 1) {
        this.propulsionType = "Elektro";
        this.setPropulsionType("Elektro");
        // this.setPropulsionType(this.propulsionType);
        this.onSelectPropulsion();
      }
    },
    onSelectPropulsion() {
      this.setPropulsionType(this.propulsionType);
      this.getEquipmentData();
    },
    onSelectComponentType() {
      this.setComponentType(this.componentType);
      this.getEquipmentData();
    },
    onSelectMastType() {
      this.setMastType(this.mastType);
      this.getEquipmentData();
    },
    resizeAvatar() {
      if (window.innerWidth < 960 && window.innerWidth > 800) {
        this.avatarSize = 112;
        this.iconDescriptionSize = 14;
      } else if (window.innerWidth < 800 && window.innerWidth > 600) {
        this.avatarSize = 80;
        this.iconDescriptionSize = 10;
      } else if (window.innerWidth < 600) {
        this.avatarSize = 40;
        this.iconDescriptionSize = 10;
      }
    },
    resizeDescription() {
      if (window.innerWidth < 600) this.descriptionFontSize = 12;
      else this.descriptionFontSize = 14;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-form {
  font-family: linde;
}

.isActive {
  opacity: 1;
}

.isInactive {
  opacity: 0.4;
}
</style>
