import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Result from "./views/Result.vue";
import Management from "./views/Management.vue";
import SearchInquiry from "./views/SearchInquiry.vue";
import Details from "./views/Details.vue";
import Favorites from "./views/Favorites.vue";
import DSGVO from "./views/DSGVO.vue";
import Verified from "./views/Verified.vue";
import Compare from "@/views/Compare";
import TradeIn from "@/views/TradeIn";

import store from "./store";
import Dashboard from "@/views/Dashboard.vue";
import MySearch from "@/views/MySearch.vue";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      name: "default",
      component: Home
    },
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: {
        requiresAuth: true
      },
      component: Dashboard
    },
    {
      path: "/dashboard/my-search",
      name: "my-search",
      meta: {
        requiresAuth: true
      },
      component: MySearch
    },
    {
      path: "/result",
      name: "result",
      component: Result
    },
    {
      path: "/detail",
      name: "detail",
      component: Details
    },
    {
      path: "/detail/:id",
      name: "shareDetail",
      component: Details
    },
    {
      path: "/search-inquiry",
      name: "searchInquiry",
      component: SearchInquiry
    },
    {
      path: "/trade-in",
      name: "tradeIn",
      component: TradeIn
    },
    {
      path: "/compare",
      name: "compare",
      component: Compare
    },
    {
      path: "/favorites",
      name: "favorites",
      component: Favorites
    },
    {
      path: "/dsgvo",
      name: "dsgvo",
      component: DSGVO
    },
    {
      path: "/management",
      name: "management",
      meta: {
        requiresAuth: true
      },
      component: Management
    },
    {
      path: "/verified",
      name: "verified",
      component: Verified
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.getters.loginState) next("/");
  else next();
});

export default router;
