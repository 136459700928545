<template>
  <v-layout class="pt-1" wrap row>
    <v-flex
        class="text-md-left text-sm-left text-xs-left mt-0 py-0 mb-1"
        md12
        sm12
        xs12
    >
      <v-btn
          class="ma-0 pa-0 normalize-font"
          color="primary"
          to="/result"
          flat
          small
      >
        <v-icon small>arrow_back</v-icon>
        zurück zu den Ergebnissen
      </v-btn>
    </v-flex>
    <v-flex md12 sm12 xs12>
      <FavoriteList></FavoriteList>
    </v-flex>
  </v-layout>
</template>


<script>

import FavoriteList from "@/components/FavoriteList";
export default {
  name: "App",
  data() {
    return {
      equipment: null,
    };
  },
  beforeCreate() {
  },
  components: {
    FavoriteList
  },
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
