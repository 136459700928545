import Api from "./api";

const RESOURCE_NAME = "TradeIns/";

const getTradeIns = (auth) => {
    return Api().get(RESOURCE_NAME + `own?access_token=${auth}`);
};

const updateTradeInStatus = (auth, id, payload) => {
    payload.updatedAt = new Date(Date.now());
    return Api().patch(
        RESOURCE_NAME + 'status/' + id + `?access_token=${auth}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const uploadTradeInImage = (tradeInId, payload) => {
    return Api().post(
        RESOURCE_NAME + tradeInId + "/upload",
        payload,
        {
            headers: {
                Authorization: returnAccessToken(),
                "Content-Type": "multipart/form-data"
            }
        }
    );
};

const sendEmail = (tradeInId) => {
    return Api().post(
        RESOURCE_NAME + tradeInId + "/sendMail",
        {
            headers: {
                Authorization: returnAccessToken(),
            }
        }
    );
}

const postTradeIn = (payload) => {
    return Api().post(RESOURCE_NAME + "new", payload)
}

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getTradeIns,
    updateTradeInStatus,
    uploadTradeInImage,
    postTradeIn,
    sendEmail
};
