<template>
    <div>
        <v-divider class="my-2"></v-divider>
        <span class="subheading">{{label}}</span>
        <v-divider class="my-2"></v-divider>
    </div>
</template>

<script>
    export default {
        name: 'DividerWithLabel',
        props: {
            label: ''
        }
    }
</script>