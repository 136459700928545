<template>
  <v-card-text>
    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.createdAt"
        label="Erstellungsdatum"
        :value="formatDate(selectedEquipment.createdAt)"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.type"
        label="Typ"
        :value="selectedEquipment.type"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.constructionType"
        label="Bauart"
        :value="selectedEquipment.constructionType"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.serialNumber"
        label="Seriennummer"
        :value="selectedEquipment.serialNumber"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.mastType"
        label="Masttyp"
        :value="selectedEquipment.mastType"
        font-size="body-1"
    />

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.mastType"
        label="Initialhub"
        :value="selectedEquipment.initialHub ? 'Ja' : 'Nein'"
        font-size="body-1"
    />

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.liftingHeight"
        label="Hubhöhe (mm)"
        :value="selectedEquipment.liftingHeight"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.loadCapacity"
        label="Tragfähigkeit (kg)"
        :value="selectedEquipment.loadCapacity"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.height"
        label="Masthöhe (mm)"
        :value="selectedEquipment.height"
        font-size="body-1"
    />

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.component"
        label="Komponenten/Typ"
        :value="selectedEquipment.component"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        v-if="selectedEquipment.driversModule"
        label="Fahrerschutzdach"
        :value="selectedEquipment.driversModule"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.driversModule"
        label="Höhe Fahrerschutzdach (mm)"
        :value="selectedEquipment.heightOfCabin"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.additionalHydraulic"
        label="Zusatzhydraulik"
        :value="selectedEquipment.additionalHydraulic"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.driversSeat"
        label="Fahrersitz"
        :value="selectedEquipment.driversSeat"
    />

    <DescriptionValueDisplay
        v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)
              && selectedEquipment.forkOutsideDistance"
        label="Gabelträgeraußenabstand (mm)"
        :value="selectedEquipment.forkOutsideDistance"
    />

    <DescriptionValueDisplay
        v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)
              && selectedEquipment.forkCarriageLength"
        label="Gabelträgerlänge (mm)"
        :value="selectedEquipment.forkCarriageLength"
    />

    <DescriptionValueDisplay
        v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)
              && selectedEquipment.forkCarriageThickness"
        label="Gabelträgerdicke (mm)"
        :value="selectedEquipment.forkCarriageThickness"
    />

    <DescriptionValueDisplay
        v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)
              && selectedEquipment.forkCarrier"
        label="Gabelträger/ Gabelträgeausführung"
        :value="selectedEquipment.forkCarrier"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.driversPositionEquipment"
        label="Fahrerplatz- & Elektrische Ausrüstung"
        :value="selectedEquipment.driversPositionEquipment"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.operationAndPedal"
        label="Bedienung & Pedalerie"
        :value="selectedEquipment.operationAndPedal"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.light"
        label="Beleuchtung"
        :value="selectedEquipment.light"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.safety"
        label="Sicherheit"
        :value="selectedEquipment.safety"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.tires"
        label="Bereifung"
        :value="selectedEquipment.tires"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.energyPack"
        label="Energiepaket"
        :value="selectedEquipment.energyPack"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.attachmentUnit"
        label="Anbaugerät"
        :value="selectedEquipment.attachmentUnit"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.speedControl"
        label="Geschwindigkeitsregelung"
        :value="selectedEquipment.speedControl"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.color"
        label="Farben"
        :value="selectedEquipment.color"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.connectAccessControl"
        label="Connect / Zugangskontrolle"
        :value="selectedEquipment.connectAccessControl"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.chassis"
        label="Chassis"
        :value="selectedEquipment.chassis"
    />

    <DescriptionValueDisplay
        v-if="selectedEquipment.surrounding"
        label="Umgebung"
        :value="selectedEquipment.surrounding"
    />

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.additionalConstructionType"
        label="Beschreibung Zustand"
        :value="selectedEquipment.additionalConstructionType"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.providerProductNumber"
        label="Anbieter Produkt-Nr."
        :value="selectedEquipment.providerProductNumber"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.operation"
        label="Bedienung"
        :value="selectedEquipment.operation"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.propulsion"
        label="Antrieb"
        :value="selectedEquipment.propulsion"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.constructionYear"
        label="Baujahr"
        :value="selectedEquipment.constructionYear"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.loadCapacity"
        label="Tragfähigkeit (kg)"
        :value="selectedEquipment.loadCapacity"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        label="Betriebsstunden (h)"
        :value="selectedEquipment.operatingHours ? selectedEquipment.operatingHours : 'Auf Anfrage'"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.price"
        label="Preis"
        :value="selectedEquipment.price"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.deviceStatus"
        label="Zustand"
        :value="selectedEquipment.deviceStatus"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.location"
        label="Standort"
        :value="selectedEquipment.location"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.availability"
        label="Verfügbarkeit"
        :value="selectedEquipment.availability"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.guarantee"
        label="Garantie"
        :value="selectedEquipment.guarantee"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <v-layout class="my-3">
      <v-flex>
        <div v-if="selectedEquipment.imagesReal">
          <v-layout row wrap>
            <v-flex
                v-for="image of selectedEquipment.imagesReal"
                :key="image.imageId"
                md3
            >
              <!--<img v-if="image.image" :src="appendBaseUrl(image.image)" />-->
              <v-img
                  class="mb-2"
                  :src="appendBaseUrl(image.image)"
                  width="200"
                  height="200"
              >
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.battery"
        label="Batterie"
        :value="selectedEquipment.battery"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.charger"
        label="Ladegerät"
        :value="selectedEquipment.charger"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.other"
        label="Sonstiges"
        :value="selectedEquipment.other"
        font-size="body-1"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
        class="my-0 py-0"
        v-if="selectedEquipment.guarantee"
        label="Gewährleistung"
        :value="selectedEquipment.guarantee"
        font-size="body-1"
    ></DescriptionValueDisplay>
  </v-card-text>
</template>
<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay.vue";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SellerReadOnlyEquipment",
  props: {
    selectedEquipment: null
  },
  methods: {
    ...HelperFunctions,
  },
  components: {DescriptionValueDisplay}
}
</script>
