<template>
  <v-btn @click="navigate(destination)" text ripple flat plain>
    <span style="font-family: linde;
        text-transform: capitalize;">{{ label }}</span>
  </v-btn>
</template>
<script>
export default {
  name: "AnchorNavigation",
  props: {
    destination: "",
    label: ""
  },
  methods: {
    navigate(destination) {
      let anchor = this.$router.currentRoute.hash;
      anchor = anchor.replace("#", "");
      if (destination !== anchor) {
        this.$router.push({name: 'home', hash: destination})
      }
      this.$nextTick(() => {
        const selector = this.$router.currentRoute.hash;
        if (document.getElementById(selector)) {
          const el = document.getElementById(selector);
          el.scrollIntoView({behavior: "smooth"});
        }
      });
    }
  }
};
</script>
<style type="text/css"></style>
