import Api from "./api";

const RESOURCE_NAME = "Equipments/";

const getEquipments = (auth, query) => {
    return Api().get(
        query,
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        });
}

const getTrashedEquipments = (auth) => {
    return Api().get(
        RESOURCE_NAME + "?filter[where][trashed]=1",
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        });
}

const getEquipmentById = (auth, id) => {
    return Api().get(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`
    );
};

const getPdfByZipCode = (auth, id, zipCode) => {
    return Api().get(
        RESOURCE_NAME + `${id}/zip/${zipCode}/pdf`,
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        })
}

const getEquipmentByIdForUnauthorized = (id) => {
    return Api().get(
        RESOURCE_NAME + id + `/public`
    );
};

const addEquipment = (auth, payload) => {
    return Api().post(RESOURCE_NAME, payload, {
        headers: {
            Authorization: returnAccessToken(auth)
        }
    });
};

const patchEquipmentById = (auth, id, payload) => {
    payload.updatedAt = new Date(Date.now());
    return Api().patch(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const deleteEquipmentById = (auth, id) => {
    return Api().delete(
        RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const deleteMultipleEquipments = (auth, payload) => {
    return Api().post(
        RESOURCE_NAME + `deleteTrashed?access_token=${returnAccessToken(auth)}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

const updateReservedStatus = (auth, id, payload) => {
    return Api().patch(
        RESOURCE_NAME + id + `/reserved?access_token=${returnAccessToken(auth)}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
}

const getReservedInformation = (auth, id) => {
    return Api().get(
        RESOURCE_NAME + id + `/reservedFor?access_token=${returnAccessToken(auth)}`,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
}

const getNewestEquipments = () => {
    return Api().get(RESOURCE_NAME + "newest");
}

const createCopyOfEquipment = (auth, id) => {
    return Api().post(
        RESOURCE_NAME + id + `/duplicate?access_token=${returnAccessToken(auth)}`,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
}

const importFromCsv = (auth, payload) => {
    return Api().post(RESOURCE_NAME + "import", payload,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
}

const importFromXML = (auth, payload) => {
    return Api().post(RESOURCE_NAME + "upload", payload,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
}

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getEquipments,
    getTrashedEquipments,
    getEquipmentById,
    getEquipmentByIdForUnauthorized,
    addEquipment,
    updateReservedStatus,
    patchEquipmentById,
    deleteEquipmentById,
    getReservedInformation,
    createCopyOfEquipment,
    deleteMultipleEquipments,
    getNewestEquipments,
    importFromCsv,
    getPdfByZipCode,
    importFromXML
};
