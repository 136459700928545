import Api from "./api";

const RESOURCE_NAME = "Images";

const getImagesByEquipmentId = (auth, id) => {
  return Api().get(RESOURCE_NAME + `?filter[where][equipmentId]=` + id, {
    headers: {
      Authorization: returnAccessToken()
    }
  });
};

const getImages = () => {
  return Api().get(RESOURCE_NAME);
};

const addImages = (auth, equipmentId, payload) => {
  return Api().post(
    RESOURCE_NAME + "/equipments/" + equipmentId + "/uploads",
    payload,
    {
      headers: {
        Authorization: returnAccessToken(),
        "Content-Type": "multipart/form-data"
      }
    }
  );
}

const deleteImageById = (auth, id) => {
  return Api().delete(RESOURCE_NAME + "/" + id, {
    headers: {
      Authorization: returnAccessToken()
    }
  });
};

const deleteEquipmentImage = (auth, equipmentId, imageId) => {
  return Api().delete(RESOURCE_NAME + "/equipments/" + equipmentId + "/images/" + imageId, {
    headers: {
      Authorization: returnAccessToken(auth)
    }
  });
};

const putImageById = (auth, id, payload) => {
  return Api().put(
    RESOURCE_NAME + "/" + id + `?access_token=${returnAccessToken(auth)}`,
    payload,
    {
      headers: {
        Authorization: returnAccessToken(auth)
      }
    }
  );
};

const uploadContactPersonImage = (auth, contactPersonId, payload) => {
  return Api().post(
      RESOURCE_NAME + "/contact-persons/" + contactPersonId + "/uploads",
      payload,
      {
        headers: {
          Authorization: returnAccessToken(),
          "Content-Type": "multipart/form-data"
        }
      }
  );
}

const getImagesByContactPersonId = (auth, id) => {
  return Api().get(RESOURCE_NAME + `?filter[where][contactPersonId]=` + id, {
    headers: {
      Authorization: returnAccessToken()
    }
  });
};

function returnAccessToken(auth) {
  return auth ? auth.id : null;
}

export default {
  getImagesByEquipmentId,
  getImages,
  deleteImageById,
  deleteEquipmentImage,
  addImages,
  putImageById,
  uploadContactPersonImage,
  getImagesByContactPersonId,
};
