import Api from "./api";

const RESOURCE_NAME = "searchInquiries/";

const getSearchInquiries = auth => {
    return Api().get(RESOURCE_NAME + `own?access_token=${auth}`);
};

const updateSearchInquiryStatus = (auth, id, payload) => {
    payload.updatedAt = new Date(Date.now());
    return Api().patch(
        RESOURCE_NAME + 'status/' + id + `?access_token=${auth}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getSearchInquiries,
    updateSearchInquiryStatus
};
