import axios from "axios";
import constants from "@/utils/constants";
export default () => {
  return axios.create({
    baseURL: constants.baseURL[constants.environment],
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};
