<template>
  <div class="dashboard-view">
    <v-layout class="pt-1" wrap row>
      <v-flex sm12 xs12 md12>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Dashboard</v-toolbar-title>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout class="pt-1" wrap row>
      <DashboardBox class="cursor" icon="search" label="Meine Suche" destination="dashboard/my-search"></DashboardBox>
      <DashboardBox class="cursor" icon="star" label="Meine Merkliste" destination="favorites"></DashboardBox>
      <DashboardBox class="cursor" icon="handshake" label="Meine Inzahlungnahmen" destination="management" is-management="true" :is-admin="user.isAdmin" ></DashboardBox>
    </v-layout>
  </div>

</template>


<script>

import DashboardBox from "@/components/DashboardBox.vue";

export default {
  name: "App",
  data() {
    return {
      user: ""
    };
  },
  beforeCreate() {
  },
  components: {DashboardBox},
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  },
  mounted() {
    this.user = this.$store.state.user;
  }
};
</script>

<style scoped>
</style>
