<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout>
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right>
          <v-flex xs12 class="pa-0 ma-0 mr-0">
            <v-btn small dark fab top right color="primary" @click="editEmail">
              <v-icon style="display: inline-flex" v-if="emails.length > 0" small>edit</v-icon>
              <v-icon style="display: inline-flex" v-if="emails.length === 0" small>add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="px-4 pt-3 mt-3">
      <v-flex>
        Bitte bearbeiten Sie die Email-Empfänger. Es können vier
        unterschiedliche Email-Adressen eingetragen werden. Verwenden Sie das
        Stift Symbol, um das Formular für die Bearbeitung zu öffnen.
      </v-flex>
    </v-layout>
    <v-layout class="px-4 pt-3">
      <v-flex>
        <v-list>
          <v-list-tile v-for="email of emails" :key="email.id">
            <div v-if="email.length > 0">
              <v-icon small class="mr-2">mail</v-icon>
              <span style="font-size: 14px;" class="linde-font">{{
                email
              }}</span>
            </div>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>

    <v-dialog
      :value="settings"
      max-width="600"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3"
            >Email-Empfänger
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="firstEmail"
            name="emailOne"
            browser-autocomplete="new-password"
            placeholder="example@mail.com"
          ></v-text-field>
          <v-text-field
            v-model="secondEmail"
            name="emailTwo"
            browser-autocomplete="new-password"
            placeholder="example@mail.com"
          ></v-text-field>
          <v-text-field
            v-model="thirdEmail"
            name="emailThree"
            browser-autocomplete="new-password"
            placeholder="example@mail.com"
          ></v-text-field>
          <v-text-field
            v-model="fourthEmail"
            name="emailFour"
            browser-autocomplete="new-password"
            placeholder="example@mail.com"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-2 mr-2" color="primary" @click="settings = false"
            >Schließen</v-btn
          >
          <v-btn class="mb-2 mr-2" color="primary" @click="saveSettings"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SettingsServices from "../../services/settings.services";
import { mapState } from "vuex";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "EmailManagement",
  data() {
    return {
      emails: "",
      id: "",
      settings: false,
      firstEmail: "",
      secondEmail: "",
      thirdEmail: "",
      fourthEmail: ""
    };
  },
  mounted() {
    SettingsServices.getSettings(this.accessToken)
      .then(response => {
        this.emails = response.data[0].receivers.split(",");
        this.id = response.data[0].id;
        this.firstEmail = this.emails[0];
        this.secondEmail = this.emails[1];
        this.thirdEmail = this.emails[2];
        this.fourthEmail = this.emails[3];
      })
      .catch(error => {
        throw error;
      });
  },
  computed: {
    ...mapState(["equipments", "accessToken"])
  },
  methods: {
    ...HelperFunctions,
    editEmail() {
      this.settings = true;
    },
    saveSettings() {
      this.loading(true)
      SettingsServices.putSettingsById(this.accessToken, this.id, {
        receivers: [
          this.firstEmail,
          this.secondEmail,
          this.thirdEmail,
          this.fourthEmail
        ]
      })
          .then(() => this.settings = false)
        .then(() => {
          this.initiateSuccessInfo("Email Empfänger erfolgreich gespeichert.")
          SettingsServices.getSettings(this.accessToken)
            .then(response => {
              this.emails = response.data[0].receivers.split(",");
              this.id = response.data[0].id;
              this.firstEmail = this.emails[0];
              this.secondEmail = this.emails[1];
              this.thirdEmail = this.emails[2];
              this.fourthEmail = this.emails[3];
              this.loading(false)
            })
            .catch(error => {
              throw error;
            });

        })
        .catch(err => {
          throw err;
        });
    }
  }
};
</script>
