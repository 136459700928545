<template>
  <v-layout class="save-search-box" align-start justify-start row>
    <v-flex md12>
      <v-card>
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
           <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
                <b>Suche speichern </b>
                    </span>
        </v-card-title>
        <v-card-text class="py-0 my-0">
          <v-text-field
              class="mt-0 pt-0"
              density="compact"
              variant="solo"
              label="Name"
              v-model="searchName"
              single-line
              hide-details
              @click:append-inner="onSaveSearch"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn color="primary" @click="onSaveSearch" small :disabled="searchName == ''">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import HelperFunctions from "@/utils/helper-functions";
import SearchService from "@/services/search.service";
import {mapGetters} from "vuex";

export default {
  name: "SaveSearchBox",
  data() {
    return {
      searchName: "",
      rules: {
        required: value => !!value || "Bitte geben Sie einen Wert ein."
      }
    };
  },
  components: {},
  mounted() {
    this.searchName = ""
  },
  computed: {
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  },
  methods: {
    ...HelperFunctions,
    onSaveSearch() {
      SearchService.postSearch(this.$store.state.accessToken, {...this.$store.state.search, name: this.searchName})
          .then(() => {
            this.searchName = ""
          })
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              color: "success",
              message: "Suche wurde gespeichert"
            });
          })
          .catch(err => {
            this.$store.dispatch("showSnackbar", {
              color: "error",
              message: "Das hat leider nicht geklappt!" + err
            });
          });
      },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
