<template>
  <v-app
    class="linde-font"
    :id="this.$route.path === '/' ? 'bg' : 'sub'"
    v-resize="justifyContentToCenter"
  >
    <div class="main-wrapper">
      <cookie-law theme="blood-orange--rounded" buttonText="Fortfahren">
        <div slot="message">
          Auf dieser Webseite nutzen wir weder Cookies noch sonstige einwilligungspflichtige Skripte.
          <a @click="onClickShowInfo('datenschutz')">Mehr erfahren.</a>
        </div>
      </cookie-law>
    </div>

    <v-toolbar
      dense
      extense
      absolut
      id="toolbar"
      class="centerSized"
      height="58"
    >
      <div class="logo-box">
        <CompanyLogo
          style="cursor: pointer"
          @click.native="onClickToHome"
        ></CompanyLogo>
      </div>
      <div
        v-for="anchorItem in anchorItems"
        :key="anchorItem.anchor"
        class="anchor-navigation-box hidden-md-and-down "
      >
        <AnchorNavigation
          :destination="anchorItem.anchor"
          :label="anchorItem.label"
        ></AnchorNavigation>
      </div>
      <div class="anchor-navigation-box hidden-lg-and-up">
        <v-menu
          :close-on-content-click="true"
          :nudge-width="300"
          left
          absolute
          offset-y
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon flat>
              <v-icon small>menu</v-icon>
            </v-btn>
          </template>

          <v-list v-for="(item, i) in anchorItems" :key="i">
            <v-list-tile @click="navigate(item.anchor)">
              <v-list-tile-content>
                <v-list-tile-title v-text="item.label"> </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <NavigationButton
          v-if="isLoggedIn"
          label="Dashboard"
          icon="dashboard"
          destination="/dashboard"
      ></NavigationButton>
      <NavigationButton
        label="Suche"
        icon="search"
        destination="/"
      ></NavigationButton>
      <NavigationButton
          label="Ankauf"
          icon="handshake"
          destination="/trade-in"
      ></NavigationButton>
      <NavigationButton
          label="Merkliste"
          icon="star"
          destination="/favorites"
      ></NavigationButton>
      <NavigationButton
          label="Vergleichen"
          icon="compare"
          destination="/compare"
      ></NavigationButton>
      <NavigationButton
        v-if="isLoggedIn"
        label="Einstellungen"
        icon="settings"
        destination="/management"
      ></NavigationButton>
      <LoginForm></LoginForm>
    </v-toolbar>
    <NewestEquipmentSlider v-if="this.$route.path === '/'" class="mt-3 mb-2"></NewestEquipmentSlider>
    <HeaderImage class="mt-2 mb-2"></HeaderImage>
    <div class="text--center">
      <v-content id="content" :class="{ 'content-size': justifyCenter }">
        <v-container class="pa-0" grid-list-md grid-list-sm grid-list-xs fluid>
          <router-view />
        </v-container>
      </v-content>
    </div>

    <v-layout class="text--center">
      <v-flex></v-flex>
      <v-flex md1>
        <v-btn
          class="normalize-font"
          color="primary"
          @click="onClickShowInfo('impressum')"
          flat
          small
        >
          <span class="caption">Impressum</span>
        </v-btn>
      </v-flex>
      <v-flex md1>
        <v-btn
          class="normalize-font"
          color="primary"
          @click="onClickShowInfo('agb')"
          flat
          small
        >
          <span class="caption">AGB</span>
        </v-btn>
      </v-flex>
      <v-flex md1>
        <v-btn
          class="normalize-font"
          color="primary"
          @click="onClickShowInfo('datenschutz')"
          flat
          small
        >
          <span class="caption">Datenschutz</span>
        </v-btn>
      </v-flex>
      <v-flex></v-flex>
    </v-layout>

    <v-dialog v-model="privacy" scrollable width="800">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>{{ infoTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p v-html="infoText"></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-md-right text-xs-right text-sm-right">
          <v-layout>
            <v-flex></v-flex>
            <v-flex md2 xs2 sm4>
              <v-btn
                small
                flat
                class="mr-2 mb-2 normalize-font"
                color="primary"
                @click="privacy = false"
              >
                Schließen
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showInfo" :color="info.color" :timeout="3000" top>
      {{ info.message }}
      <v-btn dark flat @click="onClickToClose">
        <v-icon dark right>cancel</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" persistent width="80">
      <div class="text-xs-center">
        <v-card>
          <v-card-text>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import NavigationButton from "@/components/NavigationButton.vue";
import AnchorNavigation from "@/components/AnchorNavigation";
import HeaderImage from "@/components/HeaderImage.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import content from "./content/info";
import CookieLaw from "vue-cookie-law";
import axios from "axios";
import NewestEquipmentSlider from "@/components/NewestEquipmentSlider.vue";

export default {
  name: "App",
  data() {
    return {
      justifyCenter: false,
      infoTitle: "",
      infoText: "",
      privacy: false,
      mobileNavigationOverlay: false,
      overlayZIndex: 0,
      anchorItems: [
        {
          label: "STANDARDS",
          anchor: "vorteile"
        }
        // {
        //   label: "Ihre Vorteile",
        //   anchor: "vorteile"
        // },
        // {
        //   label: "Approved STANDARD",
        //   anchor: "geprueft"
        // },
        // {
        //   label: "FST STANDARD",
        //   anchor: "fstStandard"
        // },
        // {
        //   label: "Ansprechpartner",
        //   anchor: "kontakt"
        // }
      ]
    };
  },
  mounted() {
    this.getEquipmentData()
  },
  methods: {
    ...mapActions(["hideSnackbar", "getEquipmentData"]),
    navigate(destination) {
      let anchor = this.$router.currentRoute.hash;
      anchor = anchor.replace("#", "");
      if (destination !== anchor) {
        this.$router.push({name: 'home', hash: destination})
      }
      this.$nextTick(() => {
        const selector = this.$router.currentRoute.hash;
        if (document.getElementById(selector)) {
          const el = document.getElementById(selector);
          el.scrollIntoView({behavior: "smooth"});
        }
      });
    },
    justifyContentToCenter() {
      if (innerWidth > 1180) {
        this.justifyCenter = true;
      } else {
        this.justifyCenter = false;
      }
    },
    onClickToHome() {
      this.$router.push("/");
    },
    onClickToClose() {
      this.hideSnackbar();
    },
    onClickShowInfo(type) {
      this.infoTitle = content[type].title;
      this.infoText = content[type].info;

      if (type === "agb") {
        axios.get("/agbs.html").then(response => this.infoText = response.data);
      }

      this.privacy = !this.privacy;
    }
  },
  computed: {
    ...mapState(["info", "showSnackbar", "loading", "favorites"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
    showInfo: {
      get() {
        return this.showSnackbar;
      },
      set(value) {
        this.$store.commit("setShowSnackbar", value);
      }
    },
    isLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$store.commit("loading", value);
      }
    }
  },
  beforeCreate() {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister()
      } })
  },
  components: {
    NewestEquipmentSlider,
    LoginForm,
    NavigationButton,
    HeaderImage,
    CompanyLogo,
    CookieLaw,
    AnchorNavigation
  },
};
</script>

<style type="text/css">
@font-face {
  font-family: linde;
  src: url("./assets/fonts/DaxlineWebPro.woff") format("woff"),
    url("./assets/fonts/lindeglobaliconfont_5.woff2") format("woff2"),
    url("./assets/fonts/DaxWebPro-Medi.woff") format("woff"),
    url("./assets/fonts/DaxWebPro.woff") format("woff");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-100.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-100.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-100italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-100italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-300.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-300italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-300italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-500.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-500italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-500italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-700.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-700italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-700italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-900.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-900.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local(''),
       url('./assets/fonts/roboto-v30-latin-900italic.woff2') format('woff2'),
       url('./assets/fonts/roboto-v30-latin-900italic.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url(./assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(./assets/fonts/MaterialIcons-Regular.woff2) format('woff');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

#bg {
  background-color: #eeeff3;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#toolbar {
  background-color: transparent;
  box-shadow: 0px 0px;
}

.v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}

.logo-box {
  background-color: white;
  padding-bottom: 8px;
  padding-right: 8px;
  align-items: center;
  vertical-align: center;
  height: 58px;
}

#sub {
  background-color: #eeeff3;
}

.cursor {
  cursor: pointer;
}

body {
  padding: 8px;
  background-color: white;
  font-family: linde;
}

.linde-font {
  font-family: linde;
}

.linde-color {
  color: #aa0020;
}

.linde-bold {
  font-weight: bold;
}

.normalize-font {
  text-transform: none;
}

.theme--light.v-select .v-select__selections {
  font-size: 16px;
  color: darkgray;
}

.v-select__selections {
  font-size: 14px;
  color: black !important;
}

.content-size {
  max-width: 1400px;
  min-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.centerSized {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.no-list-dot li::before {
  content: none !important;
  color: transparent !important;
}

.original-list-dot li::before {
  content: "\2022" !important;
  color: #aa0020 !important;
  display: inline-block !important;
  width: 1em !important;
  margin-left: -1em !important;
}
</style>
