export default {
    environment: "production",
    baseURL: {
        develop: "https://staplerscout24.it-objects.de/api/",
        qa: "https://qaapi.staplerscout24.de/api/",
        local: "http://0.0.0.0:3000/api/",
        production: "https://api.staplerscout24.de/api/"
    },
    managementTabs: {
        ADMIN: {
            MEIN_KONTO: {
                label: "Mein Konto",
                tab: 0,
            },
            NUTZERVERWALTUNG: {
                label: "Nutzerverwaltung",
                tab: 1,
            },
            EQUIPMENTVERWALTUNG: {
                label: "Equipmentverwaltung",
                tab: 2,
            },
            ANFRAGENVERWALTUNG: {
                label: "Anfragenverwaltung",
                tab: 3,
            },
            GESUCHE: {
                label: "Gesuche",
                tab: 4,
            },
            INZAHLUNGNAHME: {
                label: "Inzahlungnahmen",
                tab: 5,
            },
            EMAIL_EINSTELLUNGEN: {
                label: "Email Einstellungen",
                tab: 6,
            },
            ANSPRECHPARTNER_VERWALTUNG: {
                label: "Ansprechpartner",
                tab: 7,
            },
            PAPIERKORB: {
                label: "Papierkorb",
                tab: 8,
            },
        },
        SELLER: {
            MEIN_KONTO: {
                label: "Mein Konto",
                tab: 0,
            },
            EQUIPMENTVERWALTUNG: {
                label: "Equipmentverwaltung",
                tab: 1,
            },
            GESUCHE: {
                label: "Gesuche",
                tab: 2,
            },
            INZAHLUNGNAHME: {
                label: "Inzahlungnahmen",
                tab: 3,
            },
            ANFRAGENVERWALTUNG: {
                label: "Anfragenverwaltung",
                tab: 4,
            },
        }
    },
    operatingHour: [
        {text: "Beliebig", value: undefined},
        {text: "100", value: 100},
        {text: "200", value: 200},
        {text: "300", value: 300},
        {text: "400", value: 400},
        {text: "500", value: 500},
        {text: "600", value: 600},
        {text: "700", value: 700},
        {text: "800", value: 800},
        {text: "900", value: 900},
        {text: "1.000", value: 999}
    ],
    typeItems: [
        {
            type: "Gegengewichtstapler",
            path: "icons/UTS-Icon4_os.png"
        },
        {
            type: "Hochhubwagen",
            path: "icons/UTS-Icon1_os.png"
        },
        {
            type: "Niederhubwagen",
            path: "icons/UTS-Icon2_os.png"
        },
        {
            type: "Schubmaststapler",
            path: "icons/UTS-Icon3_os.png"
        },
        {
            type: "Kommissionierer",
            path: "icons/UTS-Icon5_os.png"
        },
        {
            type: "Schlepper",
            path: "icons/UTS-Icon6_os.png"
        },
        {
            type: "Komponente",
            path: "icons/Komponenten.jpg"
        }
    ],
    typeSelectables: [
        {
            text: "Beliebig",
            value: "Beliebig",
            path: ""
        },
        {
            text: "Gegengewichtstapler",
            value: "Gegengewichtstapler",
            path: "icons/UTS-Icon4_os.png"
        },
        {
            text: "Hochhubwagen",
            value: "Hochhubwagen",
            path: "icons/UTS-Icon1_os.png"
        },
        {
            text: "Niederhubwagen",
            value: "Niederhubwagen",
            path: "icons/UTS-Icon2_os.png"
        },
        {
            text: "Schubmaststapler",
            value: "Schubmaststapler",
            path: "icons/UTS-Icon3_os.png"
        },
        {
            text: "Kommissionierer",
            value: "Kommissionierer",
            path: "icons/UTS-Icon5_os.png"
        },
        {
            text: "Schlepper",
            value: "Schlepper",
            path: "icons/UTS-Icon6_os.png"
        },
        {
            text: "Komponente",
            value: "Komponente",
            path: "icons/Komponenten.jpg"
        }
    ],
    liftingHeights: [
        {
            text: "Beliebig",
            value: undefined
        },
        {
            text: "2.000 mm",
            value: 2000
        },
        {
            text: "2.500 mm",
            value: 2500
        },
        {
            text: "3.000 mm",
            value: 3000
        },
        {
            text: "3.500 mm",
            value: 3500
        },
        {
            text: "4.000 mm",
            value: 4000
        },
        {
            text: "4.500 mm",
            value: 4500
        },
        {
            text: "5.000 mm",
            value: 5000
        },
        {
            text: "5.500 mm",
            value: 5500
        },
        {
            text: "6.000 mm",
            value: 6000
        },
        {
            text: "6.500 mm",
            value: 6500
        },
        {
            text: "7.000 mm",
            value: 7000
        },
        {
            text: "7.500 mm",
            value: 7500
        },
        {
            text: "8.000 mm",
            value: 8000
        },
        {
            text: "8.500 mm",
            value: 8500
        },
        {
            text: "9.000 mm",
            value: 9000
        },
        {
            text: "9.500 mm",
            value: 9500
        },
        {
            text: "10.000 mm",
            value: 10000
        }
    ],
    loadCapacities: [
        {
            text: "Beliebig",
            value: undefined
        },
        {
            text: "1.000 kg",
            value: 1000
        },
        {
            text: "1.250 kg",
            value: 1250
        },
        {
            text: "1.400 kg",
            value: 1400
        },
        {
            text: "1.600 kg",
            value: 1600
        },
        {
            text: "1.800 kg",
            value: 1800
        },
        {
            text: "2.000 kg",
            value: 2000
        },
        {
            text: "2.500 kg",
            value: 2500
        },
        {
            text: "3.000 kg",
            value: 3000
        },
        {
            text: "3.500 kg",
            value: 3500
        },
        {
            text: "4.500 kg",
            value: 4500
        },
        {
            text: "5.000 kg",
            value: 5000
        },
        {
            text: "5.500 kg",
            value: 5500
        },
        {
            text: "6.000 kg",
            value: 6000
        },
        {
            text: "6.500 kg",
            value: 6500
        },
        {
            text: "7.000 kg",
            value: 7000
        },
        {
            text: "7.500 kg",
            value: 7500
        },
        {
            text: "8.000 kg",
            value: 8000
        },
        {
            text: "8.500 kg",
            value: 8500
        },
        {
            text: "9.000 kg",
            value: 9000
        },
        {
            text: "10.000 kg",
            value: 10000
        },
        {
            text: "16.000 kg",
            value: 16000
        },
        {
            text: "30.000 kg",
            value: 30000
        }
    ],
    mastTypes: ["Beliebig", "Mono", "Simplex", "Standard", "Duplex", "Triplex"],
    mastHeights: [
        {
            text: "Beliebig",
            value: undefined
        },
        {
            text: "2.000 mm",
            value: 2000
        },
        {
            text: "2.500 mm",
            value: 2500
        },
        {
            text: "3.000 mm",
            value: 3000
        },
        {
            text: "3.500 mm",
            value: 3500
        },
        {
            text: "4.000 mm",
            value: 4000
        },
        {
            text: "4.500 mm",
            value: 4500
        },
        {
            text: "5.000 mm",
            value: 5000
        },
        {
            text: "5.500 mm",
            value: 5500
        },
        {
            text: "6.000 mm",
            value: 6000
        },
        {
            text: "6.500 mm",
            value: 6500
        },
        {
            text: "7.000 mm",
            value: 7000
        },
        {
            text: "7.500 mm",
            value: 7500
        },
        {
            text: "8.000 mm",
            value: 8000
        },
        {
            text: "8.500 mm",
            value: 8500
        },
        {
            text: "9.000 mm",
            value: 9000
        },
        {
            text: "9.500 mm",
            value: 9500
        },
        {
            text: "10.000 mm",
            value: 10000
        }
    ],
    propulsion: ["Beliebig", "Gas", "Diesel", "Elektro"],
    prices: [
        {
            text: "Beliebig",
            value: undefined
        },
        {
            text: "2.500 EUR",
            value: 2500
        },
        {
            text: "5.000 EUR",
            value: 5000
        },
        {
            text: "7.500 EUR",
            value: 7500
        },
        {
            text: "10.000 EUR",
            value: 10000
        },
        {
            text: "12.500 EUR",
            value: 12500
        },
        {
            text: "15.000 EUR",
            value: 15000
        },
        {
            text: "17.500 EUR",
            value: 17500
        },
        {
            text: "20.000 EUR",
            value: 20000
        },
        {
            text: "22.500 EUR",
            value: 22500
        },
        {
            text: "25.500 EUR",
            value: 25500
        },
        {
            text: "27.500 EUR",
            value: 27500
        },
        {
            text: "30.000 EUR",
            value: 30000
        },
        {
            text: "32.500 EUR",
            value: 32500
        },
        {
            text: "35.000 EUR",
            value: 35000
        },
        {
            text: "37.500 EUR",
            value: 37500
        },
        {
            text: "40.000 EUR",
            value: 40000
        },
        {
            text: "42.500 EUR",
            value: 42500
        },
        {
            text: "45.000 EUR",
            value: 45000
        }
    ],
    availibility: [
        "1-2 Tage nach Auftragseingang",
        "nach Absprache",
        "2 Wochen nach Auftragseingang",
        "4 Wochen nach Auftragseingang"
    ],
    additionalHydraulics: ["EZH", "DZH", "3-fach", "Magnetventil"],
    driversModules: [
        "Bügeltüren",
        "Dach- Mast- und Seitenscheiben",
        "Dach- und Mastscheibe",
        "Dachscheibe \"VIEW\"",
        "Dachscheibe \"VIEW\" grün getönt",
        "Dachscheibe aus Kunststoff",
        "Dachscheibe grün getönt",
        "Dachschutzgitter",
        "Dachschutzgitter \"Light\"",
        "Fahrerschutzdach Container",
        "Fahrerschutzdach Komfort",
        "Fahrerschutzdach Komfort \"VIEW\"",
        "Fahrerschutzdach Komfort erhöht",
        "Fahrerschutzdach Standard",
        "Fahrerschutzdach Standard \"VIEW\"",
        "Fensteröffnung in Kabinentüre",
        "Front- und Dachscheibe",
        "Front-/ Dach- und Heckscheibe",
        "Frontscheibe",
        "Frontscheibe grün getönt",
        "Frontscheibe in Kunststoff",
        "Gitterschutz für FSD 1",
        "Heckscheibe grün getönt",
        "Neigezylinderschutz",
        "Panorama – Kunststoffdach",
        "Panorama – Panzerglasdach",
        "Polycarbonat für FSD 1",
        "PVC-Türen",
        "Schulterschutzbügel",
        "Schutzgitter auf FSD 1",
        "Schutzgitter Front und Dach",
        "Schutzgitter über Dachscheibe",
        "Schutzgitter über Frontscheibe",
        "Sicherheitstüre rechts & links",
        "Vollkabine",
        "Wetterschutzkabine",
        "Wetterschutzkabine mit elektrischer Heizung",
        "Windschutztür",
        "Wisch-/ Waschanlage für Dachscheibe"
    ],
    componentTypes: [
        "Beliebig",
        "Gabelzinken",
        "Ladegeräte",
        "Anbaugeräte",
        "Batterie",
        "Reinigungsmaschinen"
    ],
    driversSeats: [
        "Anpassbare Rückenlehne inkl. Klappsitz",
        "Ausführung schwenkbar",
        "Ausführung schwenkbar 10°",
        "Ausführung schwenkbar 17°",
        "Fahrersitz (Stoff / neigbar)",
        "Fahrersitz Deluxe (Stoff / luftgefedert)",
        "Fahrersitz Komfort (Echtleder / luftgefedert)",
        "Fahrersitz Komfort (Kunstleder / luftgefedert)",
        "Fahrersitz Komfort (Kunstleder)",
        "Fahrersitz Komfort (Stoff / gefedert)",
        "Fahrersitz Komfort (Stoff / luftgefedert)",
        "Fahrersitz Komfort (Stoff)",
        "Fahrersitz Komfort gefedert (Container)",
        "Fahrersitz Standard (Container / gefedert)",
        "Fahrersitz Standard (Kunstleder / luftgefedert)",
        "Fahrersitz Standard (Kunstleder)",
        "Fahrersitz Standard (Kunststoff / gefedert)",
        "Fahrersitz Standard (Kunststoff)",
        "Fahrersitz Standard (Kunststoff)",
        "Fahrersitz Standard (PVC)",
        "Fahrersitz Standard (Stoff / Lordosenstütze)",
        "Fahrersitz Super Deluxe (PVC / Luftfederung / Kühlfunktion)",
        "Fahrersitz Super Komfort",
        "Fahrersitz Superkomfort (Active Stoff / luftgefedert)",
        "Fahrersitz Superkomfort (Active Stoff)",
        "Fahrersitz Superkomfort (Kunstlder / luftgefedert)",
        "Fahrersitz Superkomfort (Kunstleder)",
        "Fahrersitz Superkomfort (Kunststoff / luftgefedert)",
        "Fahrersitz Superkomfort (Stoff / luftgefedert)",
        "Fahrersitz Superkomfort (Stoff)",
        "Kunstledersitz",
        "Mitfahrersitz (PVC)",
        "Mitfahrersitz (Standard)",
        "Sicherheitsgurt",
        "Sicherheitsgurt (Mitfahrersitz)",
        "Sitz klappbare Armlehnen",
        "Sitz Kühlhaus",
        "Sitz Längsverstellung",
        "Sitz Luftgefedert",
        "Sitz umklappbar",
        "Sitzheizung",
        "Verlängerte Rückenlehne",
        "Verstellbare Rückenlehne & Klappsitz",
        "Vertikal verstellbare Armlehnen",
        "Zusätzliche Kopfstütze (Leder)",
        "Zusätzliche Kopfstütze (Stoff)"
    ],
    driversPositionEquipments: [
        "Ablage vorne bei Plattformhub",
        "Anbaubaubügel hinten (hoch & niedrig)",
        "Anbaubügel hinten (hoch)",
        "Anbaubügel hinten (niedrig)",
        "Anbaubügel vorne (hoch)",
        "Anbaubügel vorne (niedrig)",
        "Armaturenbrett mit Ablagefach",
        "Aufbewahrungsbox",
        "Aufbewahrungsnetz",
        "Außenspiegel links + rechts",
        "Bügel für Anbauelemente",
        "Bügel vorne (hoch & niedrig)",
        "Dach- und Frontrollo",
        "Dachrollo",
        "Farbmonitor am FSD",
        "Farbmonitor in Kabine",
        "Haltegriff an FSD 1 bis 2",
        "Halter für Scanner",
        "Halter für Schrumpffolie Front",
        "Halter für Schrumpffolie Heck",
        "Heizung",
        "Heizung & Klimaanlage",
        "Höhenverstellbare Bedienkonsole & neigbares Klemmbrett",
        "Hubhöhenvorwahl System",
        "Hubhöhenvorwahl System (Mastseitig)",
        "Innenspiegel",
        "Innenspiegel Panorama",
        "Kamerasystem am Chassis",
        "Kamerasystem am Chassis + Mast",
        "Kamerasystem am Mast",
        "Kamerasystem an der Gabelzinke",
        "Klemmbrett",
        "Klemmbrett mit LED",
        "Klemmbrett vorne",
        "Kniepolster",
        "Knieschutz",
        "Lenkrad höhenverstellbar",
        "Lenkrad links",
        "Lenkrad rechts",
        "Lenksäule höhenverstellbar",
        "Luftgefederte Fahrerplattform",
        "Ablage auf Batteriedeckel",
        "Dynamic Mast Control DMC",
        "Mast Komfortpaket",
        "Neigeposition Vorwahl",
        "Pack Nr.2",
        "Panoramaspiegel vorne",
        "Pfosten links",
        "Pfosten links & rechts",
        "Pfosten rechts",
        "Plattform mit Dämpfungsmatte",
        "Radio / SD",
        "Richtungsschalter Lenksäule L",
        "Richtungsschalter Lenksäule R",
        "Rückfahrgriff mit Hupenbetätigung",
        "Seitenspiegel",
        "Spannungsversorgung für externes Geräte (12V)",
        "Spannungsversorgung für externes Geräte (24V)",
        "Steckdose (12V)",
        "Steckdose (12V) in Kabine",
        "Steckdose (USB)",
        "Stromversorgung Front 24V",
        "Stromversorgung Heck 24V",
        "Symmetrischer Lenker",
        "Terminalhalter & 24V Spannung",
        "Terminalhalter FSD",
        "Terminalhalter  in Kabine",
        "Terminalhalter vorne",
        "Universal Smartphone Halter"
    ],
    operationAndPedals: [
        "Doppelpedal-Steuerung",
        "Einpedal-Steuerung",
        "Zentralhebel-Betätigung",
        "Kreuzhebel-Betätigung",
        "Einzelhebel-Betätigung"
    ],
    securities: [
        "Aktive Fußschutzleiste STOP",
        "Aktive Fußschutzleiste STOP & GO",
        "Blaue Warnlinien",
        "Bluespot / Redspot",
        "Bluespot / Redspot auf FSD",
        "Bluespot / Redspot bei Rückwärtsfahrt",
        "Bluespot / Redspot schaltbar (3 Stufen)",
        "Bluespot / Redspot über Zündung",
        "Durchgreifschutz Drahtgewebe",
        "Durchgreifschutz Polycarbonat",
        "Fußschutz Heck",
        "Lastgewichtsanzeige",
        "Lastgewichtsanzeige \"PLUS\"",
        "Linde Load Management Advanced",
        "Linde Safety Pilot",
        "Linde Safety Pilot LSP \"active\"",
        "Linde Safety Pilot LSP \"select\"",
        "Linde TruckSpot",
        "Linde TruckSpot unter FSD",
        "Linde TruckSpot auf FSD",
        "Motion Detection",
        "Rote Warnlinien",
        "Rundumleuchte",
        "Rundumleuchte auf FSD",
        "Rundumleuchte bei Rückwärtsfahrt",
        "Rundumleuchte schaltbar (3 Stufen)",
        "Rundumleuchte über Zündung",
        "Rundumleuchte unter FSD",
        "Sequent. Gurtüberw. 0km/h",
        "Sequent. Gurtüberw. 2km/h",
        "Warnblinker bei Rückwärtsfahrt",
        "Warnblitzleuchte",
        "Warnblitzleuchte auf FSD",
        "Warnblitzleuchte bei Rückwärtsfahrt",
        "Warnblitzleuchte schaltbar (3 Stufen)",
        "Warnblitzleuchte über Zündung",
        "Warnblitzleuchte unter FSD",
        "Warnton b.Rück.-fahrt BBS-87",
        "Warnton BBS-87",
        "Warnton bei Rückwärtsfahrt",
        "Warnton mit autom. Anpassung",
        "Warnton Rückwärtsfahrt auto.",
        "Xenon Warnblitzleuchte"
    ],
    lights: [
        "ASW 1",
        "ASW 2",
        "ASW 3",
        "ASW 4",
        "ASW 5",
        "ASW 6",
        "ASW 7",
        "ASW 8",
        "Allgemeine Beleuchtung",
        "ASW FSD links",
        "ASW FSD rechts",
        "Fahrzeugbeleuchtung oben am FSD",
        "LED-Ausführung",
        "LED-Lichtleisten",
        "LED-Lichtleisten Heck",
        "LED-Lichtleisten vorne",
        "LED Lichtleisten Automatikfunktion",
        "Rück-/ Bremsleuchte am FSD oben",
        "Strassenbeleuchtung STVZO",
        "Umfang StVZO",
        "VertiLight"
    ],
    tires: [
        "Antriebsrad (Extra Grip)",
        "Antriebsrad (Gummi)",
        "Antriebsrad (Gummielastisch / profiliert)",
        "Antriebsrad (Heavy Duty)",
        "Antriebsrad (High Grip)",
        "Antriebsrad (nicht kreidend)",
        "Antriebsrad (Polyurethan / profiliert)",
        "Antriebsrad (Polyurethan / wet grip)",
        "Antriebsrad (Polyurethan)",
        "Antriebsrad (Standard)",
        "Antriebsrad (Vollgummi / nicht kreidend)",
        "Antriebsrad (Vollgummi)",
        "Antriebsrad (wet grip)",
        "Bandage",
        "Einfach – Lastrolle",
        "Einfach - Lastrolle (Polyurethan)",
        "Einfach – Stützrad",
        "Lasträder (SE / nicht kreidend)",
        "Lasträder (SE)",
        "Lasträder (Standard)",
        "Lastrolle (Superelastik / nicht kreidend)",
        "Lastrolle (Superelastik)",
        "Lastrolle (Vollgummi / nicht kreidend)",
        "Lastrolle (Vollgummi)",
        "SE – Bereifung",
        "SE - Bereifung (nicht kreidend)",
        "SE – Zwilling",
        "Stützrollen (Heavy Duty)",
        "Stützrollen (Hydraulisch)",
        "Tandem – Lastrolle",
        "Tandem - Lastrolle (Polyurethan / abschmierbar)",
        "Tandem - Lastrolle (Polyurethan)",
        "Tandem - Stützrad",
    ],
    energyPacks: [
        "Aktive Entlüftung beim Laden",
        "Anschlußkabel 230V",
        "Anschlußkabel 400V",
        "Aquamatiksystem",
        "Batterie 24 V",
        "Batterie 48 V",
        "Batterie 80 V",
        "Batteriekabel mit Anschluß Flex",
        "Batteriekabel mit Anschluß Perfect",
        "Batteriestecker 80A",
        "Batteriestecker 160A",
        "Batteriestecker 320A",
        "Batteriestecker für EUW",
        "CEE-Stecker 16A 4-polig",
        "CEE-Stecker 16A 5-polig",
        "CEE-Stecker 32A 4-polig",
        "CEE-Stecker 32A 5-polig",
        "Elektrolytanzeige am Trog",
        "Elektrolytanzeige an Steckdose",
        "Elektrolytumwälzung",
        "Gel – Batterie",
        "Ladegerät extern",
        "Ladegerät integriert",
        "Li - IONEN Batterie",
        "Ladegerät Li-Ionen",
        "mit Ladegerät",
        "Seitlicher Wechsel",
        "Vertikaler Wechsel",
    ],
    surroundings: [
        "180° Lenkung",
        "360° Lenkung",
        "Auspuff nach oben",
        "Automat. Motorabst. 30sek-5min",
        "Batterietür 180°",
        "Doppel – Flaschenhalterung",
        "Drucklosschaltung",
        "Druckspeicher zur Lastdämpfung",
        "Feinstfilter für Hydr.-Anlage",
        "Funkenlöscher",
        "Getränkeausführung",
        "Halterung Treibgasflasche",
        "Kühlersieb (Papierindustrie)",
        "Kühlhausausführung",
        "Leistungsstufe Economy",
        "Leistungsstufe Efficiency",
        "Leistungsstufe Performance",
        "Partikelfilteranlage",
        "Reinigungsöffnung Boden",
        "Staubs. Multizyklon höh.mont.",
        "Staubschutz",
        "Staubschutz höher montiert",
        "Staubschutz Ölbadluftfilter",
        "StVZO-Ausstattung",
        "Treibgastank",
        "TÜV-Abnahme",
        "Umkehrfunktion der Lenkung"
    ],
    forkCarrier: [
        "Automatische Hubfunktion Heben & Senken",
        "Automatische Hubfunktion (nur Heben)",
        "Manuelle Hubfunktion Heben & Senken ",
        "Soft - Landing des Gabelträgers",
        "Linde – Safety Lift",
        "Linde - Lift Speed Booster ",
        "Verstellbare Radarme 900 / 1200 / 1350 mm"
    ],
    speedControl: [
        "Autom.Geschw.red. ab 500mm HH",
        "Autom.Geschw.red. ab 500mm LSP",
        "Fahrgeschwindigkeit fixiert",
        "Fahrgeschwindigkeit schaltbar über Taster",
        "Fahrgeschwindigkeit 6 km/h",
        "Fahrgeschwindigkeit 8 km/h",
        "Fahrgeschwindigkeit 10 km/h",
        "Fahrgeschwindigkeit 12 km/h",
        "Fahrgeschwindigkeit 14 km/h",
        "Geschwindigkeitsregelung – Vorwärts 8 Km/h",
        "Geschwindigkeitsregelung – Vorwärts 10 Km/h",
        "Geschwindigkeitsregelung – Vorwärts 12 Km/h",
        "Geschwindigkeitsregelung – Vorwärts 14 Km/h",
        "Geschwindigkeitsregelung – 5.5 Km/h GZ > 250 mm über Freihub",
        "Geschwindigkeitsregelung – Begrenzung 2 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 4 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 6 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 8 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 10 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 12 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 14 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 16 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 18 Km/h",
        "Geschwindigkeitsregelung – Begrenzung 20 Km/h",
        "Geschwindigkeitsregelung – Indoor Begrenzung",
        "Geschwindigkeitsregelung – Linde Speedassist",
        "Geschwindigkeitsregelung – Max. Fahrgeschwindigkeit begrenzt",
        "Geschwindigkeitsregelung – Reduziert Geschwindigkeit bei niedrigen GZ",
        "Geschwindigkeitsregelung – SafetySpeed",
        "Geschwindigkeitsregelung – Schleichfahrtfunktion"
    ],
    connectAccessControl: [
        "ac: access control PIN",
        "ac: access control RFID",
        "Schlüsselschalter",
        "Data Transfer Wifi",
        "Data Transfer Online",
        "dt: crash detection",
        "an: usage analysis",
        "dt: trouble codes",
    ],
    colors: [
        "RAL 1005 (Honiggelb)",
        "RAL 1023 (Verkehrsgelb)",
        "RAL 1028 (Melonengelb)",
        "RAL 2000 (Gelborange)",
        "RAL 2002 (Blutorange)",
        "RAL 3001 (Signalrot)",
        "RAL 5005 (Signalblau)",
        "RAL 5010 (Enzianblau)",
        "RAL 6010 (Grasgrün)",
        "RAL 9010 (Reinweiß)",
    ],
    chassis: [
        "Anfahrschutz oben",
        "Anfahrschutz Metall vorne",
        "Anfahrschutz oben u. unten",
        "Anfahrschutz Semi-Automated",
        "Anfahrschutz unten",
        "Rammschutz oben",
        "Rammschutz oben u. unten",
        "Rammschutz unten",
        "Rammschutz vorne",
        "Schutzl. vo., hi. & seitl.",
        "Schutzleisten seitlich ",
        "Seitliche Schranken",
        "Stark geschützte Ausführung",
    ],
    attachmentUnits: [
        "Automatische Horizontalstellung GZ",
        "Automatische Zentrierung ISS",
        "Ballenklammer",
        "Doppel – Palettenklammer",
        "Drehgerät",
        "Gabelträger",
        "Gabelzinken 1200 mm",
        "Gabelzinken 10 mm über dem Boden",
        "Gabelzinken 20 mm über dem Boden",
        "Gabelzinken 30 mm über dem Boden",
        "Gabelzinken 40 mm über dem Boden",
        "Gabelzinken 50 mm über dem Boden",
        "Gabelzinken 60 mm über dem Boden",
        "Integrierter Seitenschieber",
        "Integriertes - Zinkenverstellgerät \"VIEW\"",
        "ISO 2B / 800mm",
        "ISO 2B / 1000mm",
        "ISO 2B / 1100mm",
        "Klammergabel",
        "Rollenklammer",
        "Seitenschieber (vorgehangen)",
        "Seitenhub",
        "Teleskopzinken",
        "Zinkverstellung",
        "Zinkenverstellgerät",
        "Zinkenverstellgerät inkl. Seitenschub"
    ],
    deviceStatus: [
        "Bitte wählen",
        "Vorlauf Aufarbeitung / Approved Standard",
        "Gerät in Aufarbeitung",
        "FST Standard",
        "Approved Truck / verfügbar"
    ],
    guaranteeOptions: [
        "Unter Ausschluss jeglicher Gewährleistung",
        "3 Monate / 250 Betriebsstunden",
        "6 Monate / 500 Betriebsstunden",
        "12 Monate / 1000 Betriebsstunden"
    ],
    // Beschreibung Zustand
    additionalConstructionType: [
        "Approved Truck - verfügbar",
        "LMH RR - FST - Standard"
    ],
    manufacturer: [
        "Linde MH",
        "STILL",
        "Jungheinrich",
        "Crown",
        "Toyota",
        "BT",
        "AUSA",
        "BAKA",
        "Baumann",
        "Bulmor",
        "BYD",
        "Carer",
        "Caterpillar",
        "CESAB",
        "CLARK",
        "Combilift",
        "Dantruck",
        "DIMOS",
        "Doosan",
        "Genkinger",
        "Goodsense",
        "HanseLifter",
        "Heli",
        "Hubtex",
        "Hyster",
        "Hyundai",
        "JCB",
        "JLG",
        "Kalmar",
        "Konecranes",
        "Manitou",
        "Merlo",
        "MIAG",
        "Mitsubishi",
        "Sichelschmidt",
        "Stöcklin",
        "Svetruck",
        "UniCarriers",
        "Votex Bison",
        "Yale",
        "andere"
    ],
    yesOrNo: ["ja", "nein"],
    conditionTypes: ["gut", "mittel", "schlecht"],
    voltages: ["24V", "48V", "80V"],
    formRules: {
        requiredRules: [v => !!v || "Bitte geben Sie einen Wert ein."],
        nameRules: [v => !!v || "Vor- & Nachname ist erforderlich"],
        companyOrganizationRules: [
            v => !!v || "Unternehmen/Organisation ist erforderlich"
        ],
        zipCodeRules: [
            v => !!v || "Postleitzahl ist erforderlich",
            v =>
                /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) ||
                "Ungültige Postleitzahl"
        ],
        addressRules: [
            v => !!v || "Straße & Hausnummer ist erforderlich",
            v => /^[ \-0-9a-zA-ZäöüÄÖÜß.]+?\s+(\d+(\s?[a-zA-Z])?)\s*(?:$|\(|[A-Z]{2})/.test(v) || "Ungültige Straße & Hausnummer"
        ],
        placeRules: [v => !!v || "Ort ist erforderlich"],
        emailRules: [
            v => !!v || "E-mail ist erforderlich",
            v =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "Ungültige E-mail"
        ],
        passwordRules: [
            v => !!v || "Passwort ist erforderlich",
            v => /^.{8,}$/.test(v) || "Passwort zu kurz (Mind. 8 Zeichen)",
            v => /^(?=.*\d).{8,}$/.test(v) || "Mindestens 1 Nummer"
        ],
        phoneRules: [v => !!v || "Telefon ist erforderlich"],
        budgetRules: [v => !!v || "Budget ist erforderlich"],
        propulsionRules: [v => !!v || "Antrieb ist erforderlich"],
        loadCapacitiesRules: [v => !!v || "Tragfähigkeit ist erforderlich"],
        constructionYearRules: [v => !!v || "Baujahr ist erforderlich"],
        minimumValue: [v => v > 100 || "Bitte in kg angeben! - Gewicht > 100kg)"]
    },
    statusItems: [
        {text: "Offen", value: "open"},
        {text: "In Bearbeitung", value: "inProgress"},
        {text: "Abgeschlossen", value: "completed"},
    ],
    runTimes: [24, 36, 39, 42, 48, 54, 60],
    residuals: [0, 10, 20, 30, 40]
};


