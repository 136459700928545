<template>
  <div class="dsgvo">
    <v-card>
      <v-card-text>
        <p v-html="content"></p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import content from "@/content/info";

export default {
  name: "App",
  data() {
    return {
      title: "",
      content: ""
    };
  },
  components: {},
  mounted() {
    this.title = content.datenschutz.title;
    this.content = content.datenschutz.info;
  },
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.mt10 {
  margin-top: 10px;
}

a {
  color: #42b983;
}

video {
  width: 100%;
  height: auto;
}

.contact-name {
  color: rgb(170, 0, 32);
}

.contact-role {
  color: #636363;
  font-size: 12px;
}

#geprueft li {
  display: table !important;
}
</style>
