<template>
    <v-flex
        class="text-md-center text-sm-center text-xs-center mt-0 py-0 mb-1 mx-0"
        md4
        sm6
        xs12
        @click="navigateTo(destination)"
    >
      <div class="search-inquiry-form linde-font">
        <v-card>
          <v-card-title>
            <span class="title linde-color"> {{ label }} </span>
          </v-card-title>
          <v-card-text>
            <v-icon class="big-icon mt-5 mb-5">{{ icon }}</v-icon>
          </v-card-text>
        </v-card>
      </div>
    </v-flex>
</template>


<script>
import constants from "@/utils/constants";

export default {
  name: "DashboardBox",
  props: {
    destination: "",
    icon: "",
    label: "",
    isManagement: false,
    isAdmin: false
  },
  methods: {
    navigateTo(destination) {
      if (this.isManagement) {
        let active = this.isAdmin ?
            constants.managementTabs.ADMIN.INZAHLUNGNAHME.tab :
            constants.managementTabs.SELLER.INZAHLUNGNAHME.tab
        this.$store.commit("updateActiveTab", active);
      }
      this.$router.push("/" + destination);
    }
  },
};
</script>


<style>
.navigation-button {
}

.big-icon {
  font-size: 65px
}
</style>
