<template>
  <div>
    <v-card
        style="max-width: 200px; margin-left: auto; margin-right: auto; text-align: center"
        v-if="searches.length === 0"
    >
      <v-card-text>
        Noch keine gespeicherten Suchen
      </v-card-text>
    </v-card>
    <div v-for="search in searches" :key="search.id">
      <v-card class="mb-3 cursor">
        <v-card-title @click="onSelectSearch(search)" class="pa-0">
          <v-layout wrap row>
            <v-flex md9 xs12 sm8 class="mt-2">
              <v-layout class="px-3 pb-0" wrap row justify-space-between>
                <v-flex class="subheading mb-0 pb-0" md9 xs9 sm8>
                  <span class="linde-font linde-color linde-bold">{{
                      search.name
                    }}</span>
                </v-flex>
              </v-layout>
              <v-layout class="px-3 pb-2" wrap row justify-space-between>
                <v-flex class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Traglast:"
                      :value="formatRangeOutput(search.loadCapacityFrom, search.loadCapacityTo, 'kg')"
                      unit="kg"
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Masttyp:"
                      :value="search.mastType == '' ? '-' : search.mastType"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Produktkategorie:"
                      :value="search.types.toString()"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Hubhöhe:"
                      :value="formatRangeOutput(search.liftingHeightFrom, search.liftingHeightTo, 'mm')"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Bauhöhe:"
                      :value="formatRangeOutput(search.heightFrom , search.heightTo, 'mm')"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Antrieb:"
                      :value="search.propulsionType"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Komponenten/Typ:"
                      :value="search.componentType ? search.componentType : '-'"
                      unit=""
                  />
                </v-flex>

                <v-flex class="my-0 py-0" md7 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Betriebsstunden:"
                      :value="formatRangeOutput(formatNumbers(search.operatingHoursFrom), formatNumbers(search.operatingHoursTo), 'h')"
                      unit=""
                  />
                </v-flex>
                <v-flex class="my-0 py-0" md5 sm12 xs12>
                  <DescriptionValueDisplay
                      label="Preis:"
                      :value="formatRangeOutput(formatNumbers(search.priceFrom), formatNumbers(search.priceTo), '€')"
                      unit=""
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-card-actions>
          <v-btn flat color="primary" @click="deleteSearch(search)">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- DELETE CONFIRMATION DIALOG -->
    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Suche löschen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p class="subheading">
            Möchten Sie diese Suche wirklich löschen?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              flat
              @click="deleteDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              class="normalize-font mb-2 mr-2"
              color="primary"
              @click="onRemoveSearch(selectedSearch)"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import HelperFunctions from "@/utils/helper-functions";
import SearchService from "@/services/search.service";

export default {
  name: "SavedSearchList",
  data() {
    return {
      selectedSearch: {},
      deleteDialog: false
    };
  },
  components: {
    DescriptionValueDisplay
  },
  mounted() {
    SearchService.getSearches(this.$store.state.accessToken)
        .then(response => {
          this.$store.commit("updateSearches", response.data)
        });
  },
  computed: {
    ...mapState(["searches"]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["setMastType", "setTypes", "setComponentType", "setPrice", "setPropulsionType"]),
    formatRangeOutput(from, to, unit) {
      from = from ? from + ' ' + unit : 'beliebig'
      to = to ? to + ' ' + unit : 'beliebig'
      return `von: ${from} bis: ${to}`
    },
    onSelectSearch(search) {
      this.setTypes(search.types);
      this.$store.commit("updateOperatingHoursFrom", search.operatingHoursFrom);
      this.$store.commit("updateOperatingHoursTo", search.operatingHoursTo);
      this.invokeQueryConstruction("operatingHours", search.operatingHoursFrom, search.operatingHoursTo)
      this.$store.commit("updateHeightFrom", search.heightFrom);
      this.$store.commit("updateHeightTo", search.heightTo);
      this.invokeQueryConstruction("height", search.heightFrom, search.heightTo)
      this.$store.commit("updateLiftingHeightFrom", search.liftingHeightFrom);
      this.$store.commit("updateLiftingHeightTo", search.liftingHeightTo);
      this.invokeQueryConstruction("liftingHeight", search.liftingHeightFrom, search.liftingHeightTo)
      this.$store.commit("updateLoadCapacityFrom", search.loadCapacityFrom);
      this.$store.commit("updateLoadCapacityTo", search.loadCapacityTo);
      this.invokeQueryConstruction("loadCapacity", search.loadCapacityFrom, search.loadCapacityTo)
      this.$store.commit("updatePriceFrom", search.priceFrom);
      this.$store.commit("updatePriceTo", search.priceTo);
      this.invokeQueryConstruction("price", search.priceFrom, search.priceTo)
      this.setPropulsionType(search.propulsionType);
      this.setComponentType(search.componentType);
      this.setMastType(search.mastType);
      this.$router.push("/result");
    },
    deleteSearch(search) {
      this.deleteDialog = true
      this.selectedSearch = search
    },
    onRemoveSearch(search) {
      this.deleteDialog = false;
      this.loading( true);
        SearchService.deleteSearch(this.$store.state.accessToken, search.searchId)
            .then(() => {
              this.initiateSuccessInfo("Suche wurde gelöscht")
              setTimeout(() => {
                SearchService.getSearches(this.$store.state.accessToken)
                    .then(response => {
                      this.$store.commit("updateSearches", response.data)
                    })
                    .then(() => {
                      this.loading(false);
                    })
                    .catch(err => {
                      throw err;
                    });
              }, 1500);
              // hide delete dialog
              this.selectedSearch = null;
            })
            .catch(() => {
              this.initiateErrorInfo("Das hat leider nicht geklappt!")
            });
    },
    invokeQueryConstruction(propertyName, from, to) {
      if (from || to) {
        this.$store.dispatch("constructQuery", {
          type: propertyName,
          from: from,
          to: to
        });
        this.$store.dispatch("getEquipmentData");
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
