import Api from "./api";

const RESOURCE_NAME = "settings/";

const getSettings = auth => {
  return Api().get(RESOURCE_NAME, {
    headers: {
      Authorization: auth ? auth.id : null
    }
  });
};

const putSettingsById = (auth, id, payload) => {
  return Api().put(
    RESOURCE_NAME + id + `?access_token=${returnAccessToken(auth)}`,
    payload,
    {
      headers: {
        Authorization: returnAccessToken(auth)
      }
    }
  );
};

function returnAccessToken(auth) {
  return auth ? auth.id : null;
}

export default {
  getSettings,
  putSettingsById
};
