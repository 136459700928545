<template>
  <v-layout class="compare-list" align-start justify-start row>
    <v-flex md12>
      <v-card>
        <v-card-title class="subheading lighten-2 ma-0 pa-3" primary-title>
           <span class="font-weight-medium" style="color:#AA0020; font-family: linde;">
                <b>Vergleichen (
                  {{ compares.length > 3 ? 3 : compares.length }} / 3
                  )</b>
                    </span>
        </v-card-title>
        <div v-for="(equipment, index) in compares" :key="'compare-list-' + index" class="element px-3">
          <v-layout wrap row>
            <v-flex md3 xs12 sm4 class="">
              <v-img
                  v-if="equipment.image && equipment.image.image"
                  class="white--text"
                  :src="appendBaseUrl(equipment.image.image)"
                  :aspect-ratio="1.333"

              >
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
              <v-img
                  v-else
                  class="white--text"
                  :src="require('@/assets/placeholder-image.jpg')"
                  :aspect-ratio="1.333"
              >
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                  </v-layout>
                </template>
              </v-img>
            </v-flex>
            <v-flex md9 xs12 sm8 class="mt-1">
              <v-flex>
                <v-layout class="px-3 pb-0" wrap row justify-space-between>
                  <v-flex class="body-1 mb-0 pb-0" md12>
                    <span class="linde-font linde-color linde-bold">{{ equipment.type }}</span>
                  </v-flex>
                  <v-flex class="subheading mb-0 pb-0" md12>
                   <span class="body-1 linde-font mb-1 "
                   >{{ equipment.constructionType }}
                    {{
                       equipment.additionalConstructionType
                           ? equipment.additionalConstructionType
                           : ""
                     }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex shrink>
                <v-btn icon small flat @click="onRemoveCompareElement(equipment)">
                  <v-icon small color="primary">close</v-icon>
                </v-btn>
              </v-flex>
              <v-layout class="px-3 pb-2" wrap row justify-space-between>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <v-card-actions class="mt-2">
          <v-btn color="primary" @click="onShowCompare" small :disabled="compares.length < 2">Vergleichen</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import {mapState} from "vuex";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "CompareList",
  data() {
    return {
      images: [],
      compareElements: [],
    };
  },
  components: {
    DescriptionValueDisplay
  },
  mounted() {
    this.images = [];
    this.clearFavoritesAndComparesFromDeletedItems({ favorites: [], compares: this.compares })
    this.compareElements = this.compares;
  },
  computed: {
    ...mapState(["compares"])
  },
  methods: {
    ...HelperFunctions,
    onShowCompare() {
      this.$router.push("compare/");
    },
    onRemoveCompareElement(equipment) {
      this.$store.commit("updateCompares", equipment);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
