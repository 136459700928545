<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout>
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right>
          <v-flex xs12 class="pa-0 ma-0 mr-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small dark fab top right color="primary"
                     @click="deleteAllDialog = true" v-on="on">
                <v-icon style="display: inline-flex" small>delete_forever</v-icon>
              </v-btn>
            </template>
            <span>Papierkorb leeren</span>
          </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="px-4 pt-3">
      <v-flex>
        <v-data-table
            style="padding: 0 15px!important;"
            :headers="headers"
            :items="trashedEquipments"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
            rows-per-page-text="Zeilen pro Seite"
            class="elevation-0 lower-padding"
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.equipmentId }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.updatedAt | formatDate }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.providerProductNumber }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.serialNumber }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.type }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.propulsion }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.constructionYear }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.liftingHeight | meter }}</td>
            <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.price }} €</td>
            <td class="text-xs-left text-md-left" style="padding: 0 15px!important;">
              <v-layout>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          right
                          small
                          icon
                          flat
                          @click="getSingleElement(props.item.equipmentId, 'restore')"
                          color="primary"
                          v-on="on"
                      >
                        <v-icon small>restore</v-icon>
                      </v-btn>
                    </template>
                    <span>Wiederherstellen</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          right
                          small
                          icon
                          flat
                          @click="getSingleElement(props.item.equipmentId, 'delete')"
                          color="primary"
                          v-on="on"
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Endgültig löschen</span>
                  </v-tooltip>

                </v-flex>
              </v-layout>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <!-- DELETE CONFIRMATION DIALOG -->
    <v-dialog v-model="deleteDialog" max-width="350">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Equipment löschen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p class="subheading">
            Möchten Sie dieses Equipment entgültig löschen?
          </p>
          <span class="subheading linde-color linde-font">
                {{ selectedEquipment ? selectedEquipment.type : "" }}
              </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              flat
              @click="deleteDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              class="normalize-font mb-2 mr-2"
              color="primary"
              @click="onDeleteEquipment(selectedEquipment.equipmentId)"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- RESTORE CONFIRMATION DIALOG -->
    <v-dialog v-model="restoreDialog" max-width="350">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Equipment wiederherstellen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p class="subheading">
            Möchten Sie dieses Equipment wiederherstellen?
          </p>
          <span class="subheading linde-color linde-font">
                {{ selectedEquipment ? selectedEquipment.type : "" }}
              </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              flat
              @click="restoreDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              class="normalize-font mb-2 mr-2"
              color="primary"
              @click="onRestoreEquipment(selectedEquipment.equipmentId)"
          >
            Wiederherstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETE ALL CONFIRMATION DIALOG -->
    <v-dialog v-model="deleteAllDialog" max-width="350">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Papierkorb leeren</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p class="subheading">
            Möchten Sie alle Equipments entgültig löschen?
          </p>
          <span class="subheading linde-color linde-font">
                {{ selectedEquipment ? selectedEquipment.type : "" }}
              </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              flat
              @click="deleteAllDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              class="normalize-font mb-2 mr-2"
              color="primary"
              @click="onEmptyTrash()"
          >
            Papierkorb leeren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import EquipmentServices from "@/services/equipment.services";
import ImageServices from "@/services/image.service";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "TrashedEquipmentManagement",
  data() {
    return {
      pagination: {
        sortBy: "equipmentId",
        rowsPerPage: 10
      },
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "equipmentId",
          width: "1%"
        },
        {text: "Gelöscht am", value: "updatedAt", width: "1%"},
        {text: "AnbProdId", value: "AProdID", width: "1%"},
        {text: "Seriennr.", value: "serialNumber", width: "1%"},
        {text: "Typen Beschreibung", value: "type", width: "1%"},
        {text: "Antrieb", value: "propulsion", width: "1%"},
        {text: "Baujahr", value: "constructionYear", sortable: true, width: "1%"},
        {text: "Hubhöhe", value: "liftingHeight", width: "1%"},
        {text: "Preis (€)", value: "price", width: "1%"},
        {text: "", value: "actions", sortable: false, width: "1%"}
      ],
      deleteDialog: false,
      deleteAllDialog: false,
      restoreDialog: false,
      selectedEquipment: null
    };
  },
  mounted() {},
  computed: {
    ...mapState(["trashedEquipments", "accessToken"])
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["getEquipmentData", "unsetSearchParameters"]),
    getSingleElement(equipmentId, type) {
      EquipmentServices.getEquipmentById(this.accessToken, equipmentId).then(
          response => {
            this.selectedEquipment = response.data;
            if(type === "delete") this.deleteDialog = true;
            if(type === "restore") this.restoreDialog = true;
          }
      );
    },
    onRestoreEquipment(equipmentId) {
      this.loading(true);
      EquipmentServices.patchEquipmentById(this.accessToken, equipmentId, {trashed: false})
          .then(() => {
            this.initiateSuccessInfo("Equipment wurde wiederhergestellt.", true)
            this.reloadEquipmentsWithDelay(2000)
            this.restoreDialog = false;
            this.selectedEquipment = null;
          })
          .catch(() => {
            this.initiateErrorInfo("Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
          });
    },
    onDeleteEquipment(equipmentId) {
      this.loading(true);
      EquipmentServices.deleteEquipmentById(this.accessToken, equipmentId)
          .then(response => {
            if (response.status == 200) {
              setTimeout(this.setDialogStatus, 200);
              ImageServices.deleteEquipmentImage(this.accessToken, equipmentId, "all");
            }
          })
          .then(() => {
            this.initiateSuccessInfo("Equipment wurde gelöscht", true)
            this.reloadEquipmentsWithDelay(1500)
            // hide delete dialog
            this.deleteDialog = false;
            this.selectedEquipment = null;
          })
          .catch(() => {
            this.initiateErrorInfo("Ein Fehler ist aufgetreten!")
          });
    },
    onEmptyTrash() {
      this.loading(true);
        EquipmentServices.deleteMultipleEquipments(this.accessToken, {equipments: this.trashedEquipments})
            .then(response => {
              if (response.status == 200) {
                setTimeout(this.setDialogStatus, 200);
              }
            })
            .then(() => {
              this.initiateSuccessInfo("Papierkorb wurde erfolgreich geleert.", true)
              this.reloadEquipmentsWithDelay(1500)
              // hide delete all dialog
              this.deleteAllDialog = false;
              this.selectedEquipment = null;
            })
            .catch(() => {
              this.initiateErrorInfo("Ein Fehler ist aufgetreten!")
            });
    }
  }
};
</script>
