<template>
    <div class="navigation-button">
      <div>
        <v-btn class="hidden-md-and-up" ripple :to="destination" icon flat>
          <v-icon>{{icon}}</v-icon>
        </v-btn>
        <v-btn class="hidden-sm-and-down" ripple :to="destination" flat>
          <v-icon class="pr-1" small>{{icon}}</v-icon><span class="hidden-sm-and-down" style="font-family: linde;
        text-transform: capitalize;">{{label}}</span>
        </v-btn>
      </div>
    </div>
</template>


<script>
export default {
  name: "NavigationButton",
  props: {
    destination: "",
    icon: "",
    label: "",
  }
};
</script>


<style>
.navigation-button {
}
</style>
