import Api from "./api";

const RESOURCE_NAME = "Inquiries/";

const getInquiries = (auth) => {
    return Api().get(
        RESOURCE_NAME,
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        });
}

const getInquiriesById = (auth, id) => {
    return Api().get(
        RESOURCE_NAME + id,
        {
            headers: {
                Authorization: returnAccessToken(auth),
            }
        });
}

const updateInquiryStatus = (auth, id, payload) => {
    payload.updatedAt = new Date(Date.now());
    return Api().patch(
        RESOURCE_NAME + 'status/' + id + `?access_token=${auth}`,
        payload,
        {
            headers: {
                Authorization: returnAccessToken(auth)
            }
        }
    );
};

function returnAccessToken(auth) {
    return auth ? auth.id : null;
}

export default {
    getInquiries,
    getInquiriesById,
    updateInquiryStatus
};
