<template>
  <div class="search-inquiry-form linde-font">
    <v-card>
      <v-flex class="text-md-left">
        <v-btn
            small
            flat
            class="normalize-font"
            color="primary"
            to="/"
        >
          <v-icon small>arrow_back</v-icon>
          zurück zur Hauptseite
        </v-btn>
      </v-flex>
      <v-card-title>
        <span class="title linde-color"> Ankauf-Anfrage stellen </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <p class="subheading">
            Ihre Angaben
          </p>
          <v-divider v-if="isLoggedIn" class="my-3"></v-divider>
          <div v-if="isLoggedIn" class="mb-1 body-2 linde-bold">
            Angaben zum Verkäufer
          </div>
          <v-layout v-if="isLoggedIn" row wrap>
            <v-flex md4>
              <v-text-field class="pr-3" label="Vor- & Nachname" v-model="sellerName"
                            required></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field class="pr-3" label="Anfrage zu Angebot-Nr."
                            v-model="requestOnOfferNo"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field class="pr-3" label="E-Mail-Adresse"
                            v-model="sellerEmail"></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">
            Ansprechpartner
          </div>
          <v-layout row wrap>
            <v-flex md4 sm6 xs12>
              <v-text-field class="pr-3" :rules="rules.companyOrganizationRules"
                            label="Firma*"
                            v-model="companyOrganization"
                            required></v-text-field>
            </v-flex>

            <v-flex md3 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Straße Hausnummer" v-model="address"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="rules.addressRules" label="Straße Hausnummer*"
                            v-model="address"
                            required></v-text-field>
            </v-flex>
            <v-flex md2 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Postleitzahl" v-model="zipCode"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="rules.zipCodeRules" label="Postleitzahl*"
                            v-model="zipCode"
                            required></v-text-field>
            </v-flex>
            <v-flex md3 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Ort" v-model="place"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="rules.placeRules" label="Ort*" v-model="place"
                            required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex md4>
              <v-text-field class="pr-3" label="Vor- & Nachname*" :rules="rules.nameRules" v-model="name"
                            required></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Telefon" v-model="phone"></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="rules.phoneRules" label="Telefon*"
                            v-model="phone"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field class="pr-3" :rules="rules.emailRules" label="E-Mail-Adresse*"
                            v-model="email"></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Angaben zum Gerät
          </div>

          <!-- Manufacturer / type / serial number -->
          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select
                  :items="manufacturers"
                  v-model="manufacturer"
                  label="Hersteller*"
                  :rules="rules.requiredRules"
                  required
              ></v-select>
            </v-flex>
            <v-flex md4 sm6 xs12>
              <v-text-field class="mr-3" label="Typ*" :rules="rules.requiredRules" v-model="type"
                            required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field class="mr-3" label="Seriennr." v-model="serialNumber" required></v-text-field>
            </v-flex>
          </v-layout>
          <!-- Construction-year/ operationg-hours/ load-capacity -->
          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select v-if="isLoggedIn"
                        :items="constructionYearSpan"
                        v-model="constructionYear"
                        label="Baujahr"
              ></v-select>
              <v-select v-if="!isLoggedIn"
                        :items="constructionYearSpan"
                        v-model="constructionYear"
                        label="Baujahr*"
                        :rules="rules.constructionYearRules"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-text-field
                  v-model="operatingHours"
                  label="Betriebsstunden (h)*"
                  :rules="rules.requiredRules"
                  required
              ></v-text-field>
            </v-flex>
            <v-flex class="mr-3">
              <v-text-field
                  v-model="loadCapacity"
                  label="Tragfähigkeit (kg)*"
                  :rules="rules.requiredRules"
                  required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- Propulsion -->
          <v-layout>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select v-if="isLoggedIn"
                        :items="constants.propulsion"
                        v-model="propulsion"
                        label="Antrieb (Elektro, Gas, Diesel)"
              ></v-select>
              <v-select v-if="!isLoggedIn"
                        :items="constants.propulsion"
                        v-model="propulsion"
                        label="Antrieb (Elektro, Gas, Diesel)*"
                        :rules="rules.propulsionRules"
              ></v-select>
            </v-flex>
          </v-layout>

          <!-- Mast-type / lifting-height / height -->
          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select
                  :items="constants.mastTypes"
                  v-model="mastType"
                  label="Hubmast"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md4>
              <v-text-field
                  v-model="liftingHeight"
                  label="Hubhöhe (mm)"
              ></v-text-field>
            </v-flex>
            <v-flex class="mr-3">
              <v-text-field
                  v-model="height"
                  label="Bauhöhe (mm)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- Hydraulic / attachment-unit -->
          <v-layout>
            <v-flex class="mr-3" md4 sm4 xs12>
              <v-select
                  :items="['Beliebig', 'Einzel-Zusatzhydraulik', 'Doppel-Zusatzhydraulik', '3. Zusatzhydraulik']"
                  v-model="additionalHydraulic"
                  label="Hydraulik"
              ></v-select>
            </v-flex>
            <v-flex md4 sm6 xs12>
              <v-text-field
                  v-model="attachmentUnit"
                  label="Anbaugerät"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Angaben zur Batterie
          </div>
          <!-- voltage / ampere hour / serial number / construction year -->
          <v-layout row wrap>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="voltages"
                  v-model="batteryVoltage"
                  label="Volt"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-text-field
                  v-model="ampereHour"
                  label="AH"
              ></v-text-field>
            </v-flex>
            <v-flex md3>
              <v-text-field class="pr-3" label="Seriennr." v-model="batterySerialNumber"></v-text-field>
            </v-flex>
            <v-flex class="mr-3">
              <v-select
                  :items="constructionYearSpan"
                  v-model="batteryConstructionYear"
                  label="Baujahr"
              ></v-select>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Angaben zum Ladegerät
          </div>
          <!-- voltage / ampere hour / serial number / construction year -->
          <v-layout row wrap>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="voltages"
                  v-model="chargerVoltage"
                  label="Volt"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-text-field
                  v-model="chargerAmpereHour"
                  label="AH"
              ></v-text-field>
            </v-flex>
            <v-flex md3>
              <v-text-field class="pr-3" label="Seriennr." v-model="chargerSerialNumber"></v-text-field>
            </v-flex>
            <v-flex class="mr-3">
              <v-select
                  :items="constructionYearSpan"
                  v-model="chargerConstructionYear"
                  label="Baujahr"
              ></v-select>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Zustand Gerät
          </div>
          <!-- voltage / ampere hour / serial number / construction year -->
          <v-layout row wrap>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="conditionTypes"
                  v-model="opticalCondition"
                  label="optisch*"
                  :rules="rules.requiredRules"
                  required
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="conditionTypes"
                  v-model="technicalCondition"
                  label="technisch*"
                  :rules="rules.requiredRules"
                  required
              ></v-select>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Wichtig für den Transport
          </div>
          <!-- isAbleToDrive / loadingOnSite / serial number / construction year -->
          <v-layout row wrap>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="yesOrNo"
                  v-model="isAbleToDrive"
                  label="Gerät ist fahrbereit?*"
                  :rules="rules.requiredRules"
                  required
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md3 sm6 xs12>
              <v-select
                  :items="['ebenerdig', 'per Rampe']"
                  v-model="loadingOnSite"
                  label="Verladung vor Ort?*"
              ></v-select>
            </v-flex>
            <v-flex md3>
              <v-text-field :rules="rules.minimumValue" class="pr-3" label="Gesamtgewicht Gerät (in Kg).*"
                            v-model="weightOfEquipment"></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field class="pr-3" label="Abholadresse" v-model="pickupAddress"></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex class="mr-3">
              <v-textarea label="Sonstiges" v-model="other"></v-textarea>
            </v-flex>
          </v-layout>


          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Bildupload <span class="error" v-if="!isComplete">Bitte Bilder auswählen.</span>
          </div>

          <v-layout row wrap align-content-center>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Vorne Links" :reference="'vls'" :image="flImage" descriptionLabel="Vorne links schräg*"
                                    exampleImageUrl="stapler_vl.png"></ImagePickerFormField>
            </v-flex>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Vorne Rechts" :reference="'vrs'" :image="frImage" descriptionLabel="Vorne rechts schräg*"
                                    exampleImageUrl="stapler_vr.png"></ImagePickerFormField>
            </v-flex>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Hinten Links" :reference="'hls'" :image="rlImage" descriptionLabel="Hinten links schräg*"
                                    exampleImageUrl="stapler_hl.png"></ImagePickerFormField>
            </v-flex>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Hinten Rechts" :reference="'hrs'" :image="rrImage"
                                    descriptionLabel="Hinten rechts schräg*"
                                    exampleImageUrl="stapler_hr.png"></ImagePickerFormField>
            </v-flex>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Betriebsstundenzähler" :reference="'bsz'" :image="ohtImage"
                                    descriptionLabel="Betriebsstundenzähler*"
                                    exampleImageUrl="stapler_display.png"></ImagePickerFormField>
            </v-flex>
            <v-flex md2 sm4>
              <ImagePickerFormField name="Typenschild" :reference="'ts'" :image="tdImage" descriptionLabel="Typenschild*"
                                    exampleImageUrl="stapler_typeplate.jpg"></ImagePickerFormField>
            </v-flex>
          </v-layout>
          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">DSGVO & CO.</div>
          <div>
            <v-checkbox
                class="mb-0"
                style="font-size:14px"
                v-model="dataConfirmation"
                :rules="[(v) => !!v || 'Bitte aktzeptieren zum fortfahren!']"
            >
              <template v-slot:label>
                <div>
                  Die
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                          target="_blank"
                          href="/#/dsgvo"
                          @click.stop
                          v-on="on"
                      >
                        Datenschutzerklärung
                      </a>
                    </template>
                    Im neuen Fenster öffnen
                  </v-tooltip>
                  habe ich zur Kenntnis genommen.
                </div>
              </template>
            </v-checkbox>
          </div>

          <div class="text-md-right text-sm-right text-xs-right">
            <v-btn class="normalize-font" color="primary" v-on:click="submit">
              Absenden
            </v-btn>
          </div>
          <v-divider class="my-3"></v-divider>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-flex class="text-md-left">
          <slot name="action"></slot>
        </v-flex>
      </v-card-actions>
    </v-card>

    <!-- ask for another equipment to add dialog -->
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title
            class="headline linde"
            primary-title
        >
          Info
        </v-card-title>

        <v-card-text>
          Wollen sie ein Fahrzeug mit gleichen Werten erfassen?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              flat
              @click="onCancelAddAnotherTradeIn"
          >
            Nein
          </v-btn>
          <v-btn
              color="primary"
              flat
              @click="onAddAnotherTradeIn"
          > Ja - Vergleichsgerät erfassen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CONSTSANTS from "../utils/constants";
import HELPER_FUNCTIONS from "../utils/helper-functions";
import ImagePickerFormField from "@/components/ImagePickerFormField";
import {EventBus} from "@/event-bus";
import TradeInService from "@/services/trade-in.service";

export default {
  name: "TradeInForm",
  data() {
    return {
      rules: CONSTSANTS.formRules,
      constants: CONSTSANTS,
      manufacturers: CONSTSANTS.manufacturer,
      voltages: CONSTSANTS.voltages,
      yesOrNo: CONSTSANTS.yesOrNo,
      conditionTypes: CONSTSANTS.conditionTypes,
      agbString: `Die Datenschutzerklärung habe ich zur Kenntnis genommen.`,
      fds: new FormData(),
      fDs: [],
      constructionYearSpan: [],
      valid: false,
      sellerName: "",
      sellerEmail: "",
      name: "",
      companyOrganization: "",
      address: "",
      zipCode: "",
      place: "",
      email: "",
      phone: "",
      requestOnOfferNo: "",
      propulsion: "",
      type: "",
      serialNumber: "",
      loadCapacity: "",
      constructionYear: "",
      operatingHours: "",
      mastType: "",
      liftingHeight: "",
      height: "",
      additionalHydraulic: "",
      attachmentUnit: "",
      other: "",
      dataConfirmation: false,
      manufacturer: "",
      batteryVoltage: "",
      ampereHour: "",
      batterySerialNumber: "",
      batteryConstructionYear: "",
      chargerVoltage: "",
      chargerAmpereHour: "",
      chargerSerialNumber: "",
      chargerConstructionYear: "",
      opticalCondition: "",
      technicalCondition: "",
      isAbleToDrive: "",
      loadingOnSite: "",
      weightOfEquipment: "",
      pickupAddress: "",
      flImage: null,
      frImage: null,
      rlImage: null,
      rrImage: null,
      ohtImage: null,
      tdImage: null,
      // isComplete: true,
      dialog: false,
      copyOfEnteredData: {},
    };
  },
  components: {
    ImagePickerFormField
  },
  mounted() {
    this.constructionYearSpan = this.generateConstructionYearSpan();
    this.fillInBasicInformationForLoggedIn();
  },
  methods: {
    ...HELPER_FUNCTIONS,
    submit(e) {
      this.valid = this.$refs.form.validate();
      // this.isComplete = this.isImageUploadComplete();
      e.preventDefault();
      if (this.valid && this.isComplete) {
        this.setImageUrls();
        this.loading(true);
        this.copyOfEnteredData = {
          name: this.name,
          companyOrganization: this.companyOrganization,
          address: this.address,
          zipCode: this.zipCode,
          place: this.place,
          email: this.email,
          phone: this.phone,
          requestOnOfferNo: this.requestOnOfferNo,
          sellerName: this.sellerName,
          sellerEmail: this.sellerEmail,
          propulsion: this.propulsion,
          type: this.type,
          serialNumber: this.serialNumber,
          loadCapacity: this.loadCapacity,
          constructionYear: this.constructionYear,
          operatingHours: this.operatingHours,
          mastType: this.mastType,
          liftingHeight: this.liftingHeight,
          height: this.height,
          additionalHydraulic: this.additionalHydraulic,
          attachmentUnit: this.attachmentUnit,
          manufacturer: this.manufacturer,
          batteryVoltage: this.batteryVoltage,
          ampereHour: this.ampereHour,
          batterySerialNumber: this.batterySerialNumber,
          batteryConstructionYear: this.batteryConstructionYear,
          chargerVoltage: this.chargerVoltage,
          chargerAmpereHour: this.chargerAmpereHour,
          chargerSerialNumber: this.chargerSerialNumber,
          chargerConstructionYear: this.chargerConstructionYear,
          opticalCondition: this.opticalCondition,
          technicalCondition: this.technicalCondition,
          isAbleToDrive: this.isAbleToDrive,
          loadingOnSite: this.loadingOnSite,
          weightOfEquipment: this.weightOfEquipment,
          pickupAddress: this.pickupAddress,
          frontLeftImage: this.flImage,
          frontRightImage: this.frImage,
          rearLeftImage: this.rlImage,
          rearRightImage: this.rrImage,
          operatingHourTerminalImage: this.ohtImage,
          typeDefinitionImage: this.tdImage,
          other: this.other,
          dataConfirmation: this.dataConfirmation
        };
        TradeInService.postTradeIn(this.copyOfEnteredData)
            .then((response) => {
              this.fDs = this.tradeInImages.map(ti => ti.file);
              if (this.fDs.length > 0) {
                for (let i = 0; i < this.fDs.length; i++) {
                  this.fds.append("images", this.fDs[i].file, response.data.id + '_' + this.fDs[i].name);
                  this.resizeImageAndUpload({}, response.data.id, this.fDs[i].file, response.data.id + '_' + this.fDs[i].name, "tradeIn");
                }
                TradeInService.sendEmail(response.data.id)
              }
            })
            .then(() => {
              //Scrolls to top when view is displayed
              window.scrollTo(0, 0);
              this.initiateSuccessInfo("Inzahlungs-Anfrage erfolgreich versendet!")
              // ask for another equipment to add
              this.dialog = true;
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben prüfen!")
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben prüfen!")
      }
    },
    onAddAnotherTradeIn() {
      this.fds = new FormData();
      this.fDs = [];
      EventBus.$emit('OnClearImages', true);
      this.$store.commit("updateTradInImages", []);
      this.flImage = null;
      this.frImage = null;
      this.rlImage = null;
      this.rrImage = null;
      this.ohtImage = null;
      this.tdImage = null;
      this.serialNumber = "";
      this.operatingHours = "";
      this.batterySerialNumber = "";
      this.chargerSerialNumber = "";
      this.dataConfirmation = false;
      this.dialog = false;
    },
    onCancelAddAnotherTradeIn() {
      this.resetForm();
      this.dialog = false;
      this.$router.push({path: '/'});
    },
    setImageUrls() {
      this.flImage = this.tradeInImages.find(ti => ti.name === "Vorne Links").image;
      this.frImage = this.tradeInImages.find(ti => ti.name === "Vorne Rechts").image;
      this.rlImage = this.tradeInImages.find(ti => ti.name === "Hinten Links").image;
      this.rrImage = this.tradeInImages.find(ti => ti.name === "Hinten Rechts").image;
      this.ohtImage = this.tradeInImages.find(ti => ti.name === "Betriebsstundenzähler").image;
      this.tdImage = this.tradeInImages.find(ti => ti.name === "Typenschild").image;
    },
    unsetTadeInImages() {
      this.flImage = this.frImage = this.rlImage = this.rrImage = this.ohtImage = this.tdImage = {};
      this.$store.commit("updateTradInImages", []);

    },
    resetForm() {
      this.$refs.form.reset();
      this.$store.commit("updateTradInImages", []);
      this.unsetTadeInImages();
    },
    fillInBasicInformationForLoggedIn() {
      if (this.isLoggedIn) {
        this.sellerName = this.user.firstName + ' ' + this.user.lastName;
        this.sellerEmail = this.user.email;
      }
    },
    isImageUploadComplete() {
      const fl = this.tradeInImages.findIndex(ti => ti.name === "Vorne Links");
      const fr = this.tradeInImages.findIndex(ti => ti.name === "Vorne Rechts");
      const rl = this.tradeInImages.findIndex(ti => ti.name === "Hinten Links");
      const rr = this.tradeInImages.findIndex(ti => ti.name === "Hinten Rechts");
      const oht = this.tradeInImages.findIndex(ti => ti.name === "Betriebsstundenzähler");
      const td = this.tradeInImages.findIndex(ti => ti.name === "Typenschild");
      return (fl > -1 && fr > -1 && rl > -1 && rr > -1 && oht > -1 && td > -1);
    },
    onClickTestButton() {
      this.name = "Test Name";
      this.companyOrganization = "TEST Company";
      this.address = "TEST STR 12";
      this.zipCode = "32323";
      this.place = "TESTER HEIM";
      this.email = "alexej.nillmaier@it-objects.de";
      this.phone = "02334234";
      this.requestOnOfferNo = "231231231";
      this.propulsion = "Diesel";
      this.type = "TEST TYPE";
      this.serialNumber = "32324243";
      this.loadCapacity = "123312";
      this.constructionYear = 1989;
      this.operatingHours = "1233"
      this.mastType = "Doppel";
      this.liftingHeight = "1231";
      this.height = "2312";
      this.additionalHydraulic = "rferf";
      this.attachmentUnit = "ferfe"
      this.manufacturer = "STILL";
      this.batteryVoltage = "12V";
      this.ampereHour = "23"
      this.batterySerialNumber = "233232"
      this.batteryConstructionYear = 1977;
      this.chargerVoltage = "12V";
      this.chargerAmpereHour = "12";
      this.chargerSerialNumber = "123123";
      this.chargerConstructionYear = 1980;
      this.opticalCondition = "mittel";
      this.technicalCondition = "mittel";
      this.isAbleToDrive = "ja";
      this.loadingOnSite = "ebenerdig";
      this.weightOfEquipment = "2000";
      this.pickupAddress = "ADREESE 12, 34354 ORT";
    },
  },
  computed: {
    ...mapState([
      "user", "tradeInImages"
    ]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
    isComplete() {
      return this.isImageUploadComplete()
    }
  }
};
</script>

<style>
label.v-label {
  font-size: 14px;
}
</style>
