<template>
  <div class="my-account">
    <v-container class="pa-3 ma-0 mr-0">
      <v-layout row>
        <v-flex md12>
          <div class="headline mt-3">
            {{ account.firstName }} {{ account.lastName }}
          </div>
          <v-divider class="mb-3"></v-divider>

          <DescriptionValueDisplay
            class="my-0 py-0"
            label="Unternehmen"
            :value="account.company"
            font-size="body-1"
          ></DescriptionValueDisplay>

          <DescriptionValueDisplay
            class="my-0 py-0"
            label="Benutzername"
            :value="account.username"
            font-size="body-1"
          ></DescriptionValueDisplay>

          <DescriptionValueDisplay
            class="my-0 py-0"
            label="Rolle"
            :value="account.isAdmin ? 'Admin' : 'Verkäufer'"
            font-size="body-1"
          ></DescriptionValueDisplay>

          <v-layout wrap row class="mt-3 my-0 py-0 pt-1">
            <v-flex md5 sm7 xs5 class="text-md-left text-sm-left py-0">
              <span class="body-1">Scout Alarm</span>
            </v-flex>
            <v-flex
                md7
                sm5
                xs7
                class="text-md-left text-sm-left text-xs-left py-0 body-1"
            >
              <span>
                <v-checkbox
                    class="mb-0 mt-0 pt-0 pb-0 pl-0 ml-0"
                    style="font-size:14px"
                    :label="user.enableNotifications ? 'Aktiv' : 'Inaktiv'"
                    v-model="user.enableNotifications"
                    @change="setNotificationPreference()"
                ></v-checkbox>
              </span>
            </v-flex>
          </v-layout>

          <v-btn class="mt-3 ml-0" color="primary" @click="showResetDialog()"
            >Passwort ändern</v-btn
          >
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      :value="showReset"
      width="600"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3"
            >Passwort zurücksetzen
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="password"
              label="Passwort"
              type="password"
              required
              autocomplete="false"
            ></v-text-field>
            <v-text-field
              v-model="passwordConfirm"
              label="Passwort wiederholen"
              type="password"
              required
              autocomplete="false"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showReset = false">Abbrechen</v-btn>
          <v-btn @click="resetPassword()" color="primary"
            >Passwort ändern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import { mapState, mapActions } from "vuex";
import SellerService from "../../services/seller.services";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "MyAccount",
  data: function() {
    return {
      account: "",
      showReset: false,
      password: "",
      passwordConfirm: "",
    };
  },
  mounted() {
    this.account = this.user;
  },
  components: {
    DescriptionValueDisplay
  },
  computed: {
    ...mapState(["accessToken", "user"])
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["login", "logout"]),
    setNotificationPreference() {
      // this.account.enableNotifications =
      this.$store.commit("updateUserNotificationPreference", this.account.enableNotifications)
      SellerService.updateNotificationPreference(this.accessToken, {id: this.user.id, preference: this.account.enableNotifications})
          .then(() => {
            this.initiateSuccessInfo("Scout Alert Einstellung aktualisiert", true)
            localStorage.setItem("user", JSON.stringify(this.user))
          })
          .catch(()=> {
            this.initiateErrorInfo("Etwas ist schiefgelaufen!")
          });
    },
    showResetDialog() {
      this.showReset = true;
    },
    resetPassword() {
      if (this.password !== this.passwordConfirm) {
        this.$store.dispatch("showSnackbar", {
          color: "error",
          message: "Die eingegebenen Passwörter stimmen nicht überein!"
        });
      } else {
        SellerService.patchSeller(this.accessToken, this.user.id, {
          password: this.password
        })
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              color: "success",
              message: "Passwort wurde aktualisiert!"
            });

            this.$store.commit("logout");
            this.$router.push("/");
            this.showReset = false;
          })
          .catch(() => {
          });
      }
    }
  }
};
</script>
