<template>
    <div>
        <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="300"
                left
                absolute
                offset-y
                v-if="!isLoggedIn"
        >
            <v-btn
                    slot="activator"
                    flat
                    ripple
                    depressed
                    class="hidden-sm-and-down"
                    style="font-family: linde; text-transform: capitalize;"
            >
                Login
            </v-btn>
            <v-btn
                    slot="activator"
                    flat
                    class="hidden-md-and-up"
                    icon
            >
                <v-icon>person</v-icon>
            </v-btn>

            <v-card>
                <v-card-title class="subheading primary white--text" dark> Anmelden</v-card-title>
                <v-card-text class="mb-0">
                    <v-form>
                        <v-text-field style="font-size: 12px" class="linde-font" v-model="email" solo dense
                                      label="Benutzername" hide-details></v-text-field>
                        <v-text-field style="font-size: 12px" class="mt-1 linde-font" aria-label="Passwort" dense
                                      type="password" solo v-model="password" label="Passwort" @keyup.enter.native="signIn"
                                      hide-details autocomplete="false"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions class="mt-0">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2 mb-2 normalize-font" color="primary" @click="signIn">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-btn v-if="isLoggedIn" class="hidden-md-and-up" icon flat @click="signOut">
            <v-icon>exit_to_app</v-icon>
        </v-btn>
        <v-btn v-if="isLoggedIn" class="hidden-sm-and-down normalize-font" flat @click="signOut">
            <v-icon class="pr-1" small>exit_to_app</v-icon>
            Logout
        </v-btn>

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      fav: true,
      menu: false,
      email: "",
      password: "",
      snackbarError: false,
      snackbarSuccess: false,
      loginErrorText: "Anmeldung fehlgeschlagen.",
      loginSuccessText: "Anmeldung erfolgreich."
    };
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters(["loginState"]),
    isLoggedIn: function () {
      return this.loginState;
    },
  },
  methods: {
    ...mapActions(["login", "logout"]),
    signIn() {
      this.login({
        email: this.email,
        password: this.password
      }).then(() => {
        this.close();
        this.resetInput();
      });
    },
    navigateToManagement() {
      if (!this.$router.currentRoute.path === "/management")
        this.$router.push({path: "/management"});
    },
    signOut() {
      this.logout();
      if (this.$router.currentRoute.name !== "home") {
        this.$router.push({path: "/"});
      }
    },
    close() {
      this.menu = false;
      this.resetInput();
    },
    resetInput() {
      this.email = "";
      this.password = "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
