<template>
    <v-layout class="pt-1" wrap row>
        <v-flex md12 sm12 xs12>
            <EquipmentDetails :equipment="equipment">Kaufen</EquipmentDetails>
        </v-flex>
    </v-layout>
</template>


<script>
    import EquipmentDetails from "@/components/EquipmentDetails.vue";
    import EquipmentServices from "@/services/equipment.services";

    export default {
        name: "App",
        data() {
            return {
              equipment: null,
            };
        },
        beforeCreate() {
          const id = this.$route.params.id;
          EquipmentServices.getEquipmentById(this.$store.state.accessToken, id)
              .then(response => {
                if (response.data && !response.data.active) {
                  this.equipment = response.data;
                  this.$store.commit("setSelectedEquipment", response.data);
                }
              });
        },
        components: {
            EquipmentDetails
        },
        created() {
          //Scrolls to top when view is displayed
            window.scrollTo(0, 0);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1,
    h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
