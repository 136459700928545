import Api from "./api";

const RESOURCE_NAME = "Sellers";

const getSellers = auth => {
  /* let filter = "";
  if (isAdmin) filter = '{"where": { "superUser": null}}';
  else filter = '{"where": { "isAdmin" : false }}'; */

  return Api().get(RESOURCE_NAME, {
    headers: {
      Authorization: returnAccessToken(auth)
    }
  });
};

const postSeller = (auth, payload) => {
  return Api().post(
    `${RESOURCE_NAME}?access_token=${returnAccessToken(auth)}`,
    payload,
    {
      headers: {
        Authorization: auth ? auth.id : null
      }
    }
  );
};

const patchSeller = (auth, id, payload) => {
  return Api().patch(
    `${RESOURCE_NAME}/${id}?access_token=${returnAccessToken(auth)}`,
    payload,
    {
      headers: {
        Authorization: auth ? auth.id : null
      }
    }
  );
};

const updateNotificationPreference = (auth, payload) => {
  return Api().patch(
      `${RESOURCE_NAME}/notify?access_token=${returnAccessToken(auth)}`,
      payload,
      {
        headers: {
          Authorization: auth ? auth.id : null
        }
      }
  );
};

function returnAccessToken(auth) {
  return auth ? auth.id : null;
}

export default {
  getSellers,
  patchSeller,
  postSeller,
  updateNotificationPreference
};
