<template>
  <div class="image-picker-form-filed">
    <v-layout class="text--center">
      <v-flex md12>
        <!-- multiple select of real images -->
        <v-layout v-if="pickedImage" row wrap>
          <v-flex md2>
            <v-layout justify-space-between class="mb-2" row wrap>
              <v-flex class="pl-5" md2 v-if="pickedImage.image">
                <v-img
                    v-if="pickedImage.image"
                    class="mb-2"
                    :src="pickedImage.url"
                    width="80"
                    height="80"
                >
                </v-img>
                <v-img
                    v-else
                    class="mb-2"
                    :src="appendBaseUrl(pickedImage.image)"
                    width="55"
                    height="55"
                ></v-img>
              </v-flex>
            </v-layout>
            <v-layout class="text--left" row wrap v-if="pickedImage.image">
              <v-flex class="my-0 py-0 pt-1 text--center">
                <span class="">
                  {{ formatImageName(pickedImage.image) }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-img
            v-if="!pickedImage.image"
            class="mb-2"
            :src="require('@/assets/trad-in-example-images/' + exampleImageUrl)"
            width="80"
            height="80"
        ></v-img>
        {{ descriptionLabel }}
        <v-btn
            color="primary"
            class="normalize-font"
            flat
            @click="pickFile(false)"
        >
          <v-icon small>attach_file</v-icon>
          Bild auswählen
        </v-btn>
        <input
            type="file"
            style="display: none"
            :ref="reference"
            accept="image/*"
            @change="onFilePicked"
            multiple
        />
      </v-flex>
    </v-layout>
  </div>
</template>


<script>

import HelperFunctions from "@/utils/helper-functions";
import {EventBus} from "@/event-bus";

export default {
  name: "ImagePickerFormField",
  props: {
    image: null,
    name: "",
    exampleImageUrl: "",
    descriptionLabel: "",
    reference: null
  },
  data() {
    return {
      pickedImage: "",
    };
  },
  mounted() {
    if (this.image) {
      this.pickedImage = this.image;
    }
    EventBus.$on('OnClearImages', this.removeImageFromList);
  },
  methods: {
    ...HelperFunctions,
    pickFile(single) {
      if (single) this.$refs[this.reference].click();
      else this.$refs[this.reference].click();
    },
    onFilePicked(e) {
      const file = e.target.files[0];

      if (file !== undefined) {
        this.pickedImage = {
          file: {file: file, name: file["name"]},
          name: this.name,
          image: file.name,
          url: URL.createObjectURL(file)
        };
        this.$store.commit("updateTradInImages", this.pickedImage);
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
      } else {
        this.pickedImage = {};
      }
    },
    removeImageFromList() {
      this.$store.commit("deleteTradeInImageElement", this.pickedImage);
      this.pickedImage = {};
      this.$refs[this.reference].value = null
    },
  },
};
</script>

<style>
.truncate {
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
