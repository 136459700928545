<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card>
      <v-toolbar dark color="primary px-3">
        <v-toolbar-title>Equipment löschen</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="subheading">
          Möchten Sie dieses Equipment in den Papierkorb legen?
        </p>
        <span class="subheading linde-color linde-font">
                {{ type ? type : "" }}
                {{ operation ? operation : "" }}
              </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="normalize-font mb-2"
            flat
            @click="onCancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
            class="normalize-font mb-2 mr-2"
            color="primary"
            @click="moveToTrash()"
        >
          In Papierkorb legen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EquipmentServices from "@/services/equipment.services";
import {EventBus} from "@/event-bus";
import {mapActions, mapState} from "vuex";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "EquipmentDeleteDialog",
  data: function () {
    return {
      dialog: false,
      equipmentId: "",
      type: "",
      operation: ""
    }
  },
  props: {},
  mounted() {
    EventBus.$on("openMoveEqTrashDialog", (data) => {
      this.dialog = true
      this.equipmentId = data.equipment.equipmentId
      this.type = data.equipment.type
      this.operation = data.equipment.operation
    })
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    ...mapActions(["getEquipmentData"]),
    moveToTrash() {
      this.loading(true);
      EquipmentServices.patchEquipmentById(this.accessToken, this.equipmentId, {trashed: true})
          .then(() => {
            this.initiateSuccessInfo("Equipment wurde in den Papierkorb verschoben.", true)
            this.reloadEquipmentsWithDelay(2000)
            this.onCancel()
          })
          .catch(() => {
            this.initiateErrorInfo( "Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.")
          });
    },
    onCancel() {
      this.dialog = false
      this.equipment = null
      this.equipmentId = ""
      this.type = ""
      this.operation = ""
    },
  }
};
</script>
