<template>
  <div class="share-an-favorite">
    <v-btn v-if="!favorite" class="" @click="commentDialog = true" ripple small icon flat>
      <v-icon v-if="favorite" color="primary" small>star</v-icon>
      <v-icon v-if="!favorite" color="" small>star</v-icon>
    </v-btn>
    <v-btn v-if="favorite"  class="" @click="setFavorite(equipment)" ripple small icon flat>
      <v-icon v-if="favorite" color="primary" small>star</v-icon>
      <v-icon v-if="!favorite" color="" small>star</v-icon>
    </v-btn>
    <v-btn class="" @click="onSelectCompare" ripple small icon flat>
      <v-icon v-if="cCompare" color="primary" small>compare</v-icon>
      <v-icon v-if="!cCompare" color="" small>compare</v-icon>
    </v-btn>
    <!--<v-btn class="" @click="onShareEquipment" ripple small icon flat>
      <v-icon small>share</v-icon>
    </v-btn>-->
    <v-menu :close-on-content-click="false"
            :nudge-width="160"
            v-model="shareMenu"
            offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            ripple small icon flat
            v-bind="attrs"
            v-on="on"
        >
          <v-icon small>share</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-layout row wrap>
          <v-flex>
            <div class="subheading font-weight-light pl-3 py-2">Angebot teilen</div>
          </v-flex>
          <v-flex d-flex>
            <v-btn flat icon small @click="shareMenu = false">
              <v-icon small>
                close
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-list>
          <v-list-tile v-for="(item, index) in share"
                       :key="index"
                       @click="onShareEquipment(equipment.equipmentId, item.icon)"
          >
            <v-list-tile-avatar>
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>

              {{ ' ' + item.name }}
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>

    <v-dialog v-model="commentDialog" max-width="290">
      <v-card>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title
          >Kommentar zum Favoriten
          </v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-form
              ref="commentForm"
              @submit="setFavorite(equipment, comment)"
          >
            <v-text-field
                v-model="comment"
                label="Kommentar"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font mb-2"
              color="primary"
              flat
              @click="onCancel"
          >
            Abbrechen
          </v-btn>
          <v-btn
              class="normalize-font mb-2 mr-2"
              color="primary"
              @click="setFavorite(equipment, comment)"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>


<script>

import {mapState} from "vuex";
import HelperFunctions from "@/utils/helper-functions";
import {EventBus} from "@/event-bus";

export default {
  name: "ShareAndFavorite",
  props: {
    equipment: null,
  },
  data() {
    return {
      favorite: false,
      commentDialog: false,
      comment: "",
      compare: false,
      share: [
        {
          name: "Email",
          icon: "mail"
        }, {
          name: "Link kopieren",
          icon: "link"
        }
      ],
      shareMenu: false,
    };
  },
  mounted() {
    this.favorite = this.isFavorite(this.equipment.equipmentId);
    this.compare = this.isCompare(this.equipment.equipmentId);
    EventBus.$on('onCloseCommentDialog', () => {
      this.commentDialog = false
    })
  },
  methods: {
    ...HelperFunctions,
    onSelectCompare() {
      const isInList = this.isCompare(this.equipment.equipmentId);
      const isLimitReached = this.compares.length === 3;
      let message = "";
      if (isLimitReached && !isInList) {
        message = "Vergleich nicht möglich. Max. 3 Stapler können verglichen werden.";
        this.$store.dispatch("showSnackbar", {
          color: "error",
          message: message
        });
      } else {
        message = !isInList ? "Stapler wurde zum Vergleich hinzugefügt!" : "Stapler wurde vom Vergleich entfernt!"
        this.$store.commit("updateCompares", this.equipment);
        this.compare = this.isCompare(this.equipment.equipmentId);
      }
      this.$store.dispatch("showSnackbar", {
        color: isLimitReached && !isInList ? "error" : "success",
        message: message
      });
    },
    onShareEquipment(equipmentId, type) {
      const link = window.location.href.replace('result', "") + 'detail/' + equipmentId;
      if (type === "link") {
        try {
          navigator.clipboard.writeText(link);
          this.$store.dispatch("showSnackbar", {
            color: "success",
            message: "Link kopiert!"
          });
        } catch (e) {
          if (e) {
            this.$store.dispatch("showSnackbar", {
              color: "error",
              message: "Das hat nicht geklappt!"
            });
          }
        }
      } else {
        const subject = "Staplerscout24.de - Empfehlung";
        const body = `
        Hallo,%0D%0A%0D%0A ich habe bei Staplerscout24.de ein Angebot gefunden, das dir gefallen könnte:%0D%0A%0D%0A
        Angebot%0D%0A ________________________________________%0D%0A%0D%0A Typ: ${this.equipment.type}%0D%0A Preis: € ${this.equipment.price},-%0D%0A %0D%0A Alle Details zum Angebot gibt's hier:%0D%0A
        ${link}%0D%0A%0D%0A Viele Grüße
       `;
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
      }
    },
    onCancel() {
      this.comment = ""
      this.commentDialog = false
    },
  },
  computed: {
    ...mapState(["compares"]),
    cCompare() {
      return this.isCompare(this.equipment.equipmentId);
    }
  },
};
</script>


<style>
.navigation-button {
}
</style>
