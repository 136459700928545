<template>
  <div class="provider-details">
    <div class="body-1 mb-1">
      Linde Material Handling Rhein-Ruhr GmbH & Co. KG
    </div>
    <div class="mb-2 mt-2" style="color: #636363;">
      <p class="my-0 py-0">Bollenheide 4</p>
      <p class="my-0 py-0">42781 Haan</p>

      <!-- profile image --->
      <v-img
          class="ml-auto"
          position=""
          contain
          v-if="provider.image"
          :src="appendBaseUrl(provider.image)"
          aspect-ratio="1.333"
          height="150"
      ></v-img>

      <p v-if="!provider.firstName && !provider.lastName" class="my-0 py-0 mt-2">Tim Rodloff</p>
      <p v-else class="my-0 py-0 mt-2">{{ provider.firstName + ' ' + provider.lastName }}</p>
      <p v-if="!provider.firstName && !provider.lastName" class="my-0 py-0">Leiter Gebrauchtgeräte Center</p>
      <p v-else class="my-0 py-0">{{ provider.careerLevel }}</p>
    </div>

    <DescriptionValueDisplay
      icon="phone"
      :value="provider.phone ? provider.phone : '+49 2301 91251 0'"
    ></DescriptionValueDisplay>
    <DescriptionValueDisplay
      icon="smartphone"
      :value="provider.mobilePhone ? provider.mobilePhone : '+49 173 193 72 27'"
    ></DescriptionValueDisplay>
    <DescriptionValueDisplay
        v-if="!provider.firstName && !provider.lastName"
      icon="print"
      value="+49 201 8917 109"
    ></DescriptionValueDisplay>

    <DescriptionValueDisplay
      icon="alternate_email"
      :value="provider.email ? provider.email :'tim.rodloff@lmh-rr.de'"
    ></DescriptionValueDisplay>
    <DescriptionValueDisplay
      icon="web"
      link="link"
      value="www.lmh-rr.de"
    ></DescriptionValueDisplay>
  </div>
</template>

<script>
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "ProviderDetails",
  props: {
    company: "",
    provider: ""
  },
  components: {
    DescriptionValueDisplay
  },
  methods: {
    ...HelperFunctions
  }
};
</script>

<style>
.provider-details {
  font-family: linde;
}
</style>
